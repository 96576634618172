import React, { useState, useEffect, useRef } from 'react';
import { callApi } from '../../apiProvider';
import { useDebouncedCallback } from 'use-debounce';
import {
  Form,
  ListGroup,
  Button,
  Spinner,
  InputGroup,
  Overlay,
} from 'react-bootstrap';
import { faSearch, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './hotel-lookup.scss';

export const HotelLookup = ({ onSelectHotel, placeholder, excludeId }) => {
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const overlayTarget = useRef(null);

  const executeSearch = useDebouncedCallback((searchFilter, excludeId) => {
    callApi(
      '/search/indexes/venue-index/docs/search?api-version=2020-06-30',
      {
        method: 'POST',
        data: {
          count: true,
          skip: 0,
          top: 10,
          searchMode: 'any', //'all',
          searchFields: 'name,address,city,state',
          queryType: 'full',
          search: `${searchFilter.split(' ').join('~ ')}~`,
          filter: `isDeleted ne true${
            excludeId ? ` and id ne '${excludeId}'` : ''
          }`,
        },
      },
      false
    ).then((r) => {
      setSearchResults(r.data.value);
      setLoading(false);
    });
  }, 600);

  useEffect(() => {
    if (searchFilter) {
      setLoading(true);
      executeSearch(searchFilter, excludeId);
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, excludeId]);

  const resetSearch = () => {
    setSearchFilter('');
  };

  return (
    <div id='hotel-lookup-container'>
      <InputGroup ref={overlayTarget}>
        <InputGroup.Prepend>
          <InputGroup.Text style={{ width: '45px', justifyContent: 'center' }}>
            {loading && (
              <Spinner size='sm' variant='secondary' animation='border' />
            )}
            {!loading && <FontAwesomeIcon icon={faSearch} />}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          placeholder={
            placeholder || 'Lookup your hotel by name, city, and/or state...'
          }
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <InputGroup.Append>
          <Button variant='outline-danger' onClick={resetSearch}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      <Overlay
        target={overlayTarget.current}
        show={searchFilter.length > 0}
        placement='bottom-start'
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <ListGroup
            {...props}
            style={{ width: '45rem', ...props.style }}
            className='hotel-lookup-list'
          >
            {searchFilter && searchResults.length === 0 && (
              <ListGroup.Item>No results</ListGroup.Item>
            )}
            {searchResults.map((sr, i) => {
              return (
                <ListGroup.Item
                  key={`hlr-${i}`}
                  onClick={() => {
                    onSelectHotel(sr);
                    resetSearch();
                  }}
                  className='hotel-lookup-result'
                >
                  <div id='hotel-name' style={{ fontWeight: 'bold' }}>
                    {sr.name}
                  </div>
                  <div>{sr.address}</div>
                  <div>{`${sr.city}, ${sr.state} ${sr.zip}`}</div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        )}
      </Overlay>
    </div>
  );
};
