import { Button, ButtonGroup, Dropdown, Form, InputGroup, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { ReportBoardCol, ReportBoardRow } from "../../reporting/report-board";
import { ReportCard, reportCardHeights } from "../../reporting/report-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons";
import { useContext, useEffect, useState } from "react";
import { ROLE_ADMIN, UserProfileContext } from "../../../userProfileContext";
import { callApi, defaultErrorNotification } from "../../../apiProvider";
import { useDebouncedCallback } from "use-debounce";
import Loader from "../../loader";
import { Metric } from "../../reporting/metric";
import { EventPlanStatusMap, ProposalRequestStatusMap } from "../../../common/statusMaps";
import { differenceInCalendarWeeks, format, parseISO } from "date-fns";
import './index.scss';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StackedBar } from "../../reporting/stacked-bar";
import { formatCurrency, formatNumber } from "../../../common";
import { DonutChart } from "../../reporting/donut-chart";
import { Pie } from "recharts";
import { EventCancelModal } from "../../event/cancel-modal";
import { Share } from "../../event/planner/share";
import { RfpViewPipeline } from "./rfp-view-pipeline";
import { RfpViewGrid } from "./rfp-view-grid";
import { ActionToggle } from "../../buttons/action-toggle";
import { faFilterList } from "@fortawesome/pro-solid-svg-icons";
import { faCircleBookOpen, faCircleCalendar } from "@fortawesome/pro-light-svg-icons";
import { calculateOpportunityValue } from "../../../common/proposalValues";
import { MessagingDashboardTile } from "../../chat/dashboard-tile";
import { getMembers, sortMembers } from "../../../services/organizations";

const getEvents = filterText => {
    return callApi(
        `/sourcing/api/events/plans${!!filterText ? `?$filter=${filterText}` : ''}`,
        {
            method: 'GET'
        },
        true
    );
}

const mapEvent = e => {
    const proposalRequests = Object.keys(e.proposalRequestStatuses).reduce((a, c) => {
        a.requestsTotal += c === ProposalRequestStatusMap.Pending.key ? 0 : e.proposalRequestStatuses[c].length;
        a.requestsResponded += [ProposalRequestStatusMap.Pending.key,ProposalRequestStatusMap.New.key,ProposalRequestStatusMap.Received.key].includes(c) ? 0 : e.proposalRequestStatuses[c].length;
        a.requestsAwaiting = a.requestsTotal - a.requestsResponded;
        return a;
    }, { requestsResponded: 0, requestsAwaiting: 0, requestsTotal: 0 });
    const owner = e.planners.find(p => p.owner);
    return {
        ...e,
        name: e.name || `New RFP Created ${format(parseISO(e.created), 'MMM d, yyyy')}`,
        rfpValue: calculateOpportunityValue([EventPlanStatusMap.Contracting.key,EventPlanStatusMap.Contracted.key].includes(e.status) ? e.proposalValues : e.proposalValuesAverage),
        ownerId: !!owner ? owner.id : null,
        ownerName: !!owner ? `${owner.firstName} ${owner.lastName}` : null,
        ...proposalRequests,
        responseRate: proposalRequests.requestsTotal > 0 ? (proposalRequests.requestsResponded / proposalRequests.requestsTotal * 100) : 0,
    };
}

export const dashboardStatuses = [
    { status: EventPlanStatusMap.New.key, fill: '#f0f2f3', color: '#638FA9', active: false, },
    { status: EventPlanStatusMap.Submitted.key, fill: '#FAC536', color: '#FA6A36', active: true, },
    { status: EventPlanStatusMap.Bidding.key, fill: '#BCEBFF', color: '#0A587E', active: true, },
    { status: EventPlanStatusMap.Contracting.key, fill: '#95D9D0', color: '#027587', active: true, },
];

const dashboardStatusKeys = dashboardStatuses.map(s => s.status);

const viewNames = {
    pipeline: { key: 'pipeline', label: 'Columns' },
    list: { key: 'list', label: 'List' },
    cancelled: { key: 'cancelled', label: 'Cancelled' },
};

const detailsToggleKeys = {
    unreadProposals: 'unreadProposals',
    unreadMessages: 'unreadMessages',
    responsesDueThisWeek: 'responsesDueThisWeek',
    selectionsDueThisWeek: 'selectionsDueThisWeek',
}

export const PlannerDashboard = ({ onCopyEvent }) => {
    const history = useHistory();
    const { userProfile } = useContext(UserProfileContext);
    const [viewName, setViewName] = useState(viewNames.pipeline.key);
    const [filterText, setFilterText] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [events, setEvents] = useState([]);
    const [ownerFilter, setOwnerFilter] = useState({ selected: null, filters: [] });
    const [organizationMembers, setOrganizationMembers] = useState([]);
    const [assignOwnerContext, setAssignOwnerContext] = useState({ eventPlan: {}, newOwner: {} });
    const [assignOwnerModal, setShowAssignOwnerModal] = useState(false);
    const [shareContext, setShareContext] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [deleteContext, setDeleteContext] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelContext, setCancelContext] = useState({});
    const [detailsToggle, setDetailsToggle] = useState(null);
    const [metrics, setMetrics] = useState({
        activeRfps: [],
        activeStatuses: [],
        overdueRfps: [],
        awardedRfps: [],
        unreadProposals: [],
        responsesDueThisWeek: [],
        selectionsDueThisWeek: [],
    });

    useEffect(() => {
        if (userProfile.role !== ROLE_ADMIN) {
            setShowLoader(true);
            getEvents().then(r => setEvents(r.data.map(mapEvent))).finally(() => setShowLoader(false));
        }
    }, [userProfile.role]);

    useEffect(() => {
        const today = new Date();
        setMetrics(events.reduce((a, c) => {
            const selectionDate = !!c.selectionDate ? parseISO(c.selectionDate) : null;
            const responsesDueDate = !!c.responsesDueDate ? parseISO(c.responsesDueDate) : null;
            if (dashboardStatuses.find(s => s.status === c.status && s.active)) {
                const overdue = c.status !== EventPlanStatusMap.Contracting.key && selectionDate < new Date();
                a.overdueRfps[overdue ? 0 : 1].count++;
                a.activeRfps.push(c);
                a.activeStatuses.find(s => s.status === c.status).count++;
                a.activeStatuses.find(s => s.status === c.status).value += c.rfpValue;
            }
            if ([EventPlanStatusMap.Contracting.key,EventPlanStatusMap.Contracted.key].includes(c.status)) {
                const index = c.status === EventPlanStatusMap.Contracting.key ? 0 : 1;
                a.awardedRfps[index].count++;
                a.awardedRfps[index].value += c.rfpValue;
            }
            if (EventPlanStatusMap.Bidding.key === c.status && c.proposalRequestStatuses[ProposalRequestStatusMap.Active.key]?.length > 0)
            {
                a.unreadProposals.push(...c.proposalRequestStatuses[ProposalRequestStatusMap.Active.key]);
            }
            if (selectionDate > today && differenceInCalendarWeeks(selectionDate, today) === 0) {
                a.selectionsDueThisWeek.push(c);
            }
            if (responsesDueDate > today && differenceInCalendarWeeks(responsesDueDate, today) === 0) {
                a.responsesDueThisWeek.push(c);
            }
            return a;
        }, {
            activeRfps: [],
            activeStatuses: dashboardStatuses.filter(s => s.active).map(s => ({ ...s, count: 0, value: 0 })),
            overdueRfps: [
                { name: 'Overdue', count: 0, fill: '#B82B45' },
                { name: 'Other', count: 0, fill: '#c0cadd' },
            ],
            awardedRfps: [
                { name: EventPlanStatusMap.Contracting.key, count: 0, value: 0, fill: '#95D9D0' },
                { name: EventPlanStatusMap.Contracted.key, count: 0, value: 0, fill: '#027587' },
            ],
            unreadProposals: [],
            responsesDueThisWeek: [],
            selectionsDueThisWeek: [],
        }));
        setOwnerFilter({ selected: null, filters: events.filter(e => dashboardStatusKeys.includes(e.status)).reduce((a, c) => {
            if (!!c.ownerId && a.some(e => e.id === c.ownerId) === false) {
                a.push({ id: c.ownerId, name: c.ownerName });
            }
            return a;
        }, []) });
    }, [events]);

    const onSearchAdmin = useDebouncedCallback((value) => {
        setShowLoader(true);
        getEvents(filterText).then(r => setEvents(r.data.map(mapEvent))).finally(() => setShowLoader(false));
    }, 1000);

    const onMarkContractSigned = (event) => {
        history.push(`/event/${event.id}/agreement?marksigned=true`);
    }

    const onAssignOwner = (event) => {
        getMembers(event.organizationId).then(r => {
            setOrganizationMembers(r.data);
            setAssignOwnerContext(s => {
                return {
                    ...s,
                    eventPlan: { id: event.id, name: event.name, ownerId: event.planners.find(p => p.owner)?.id || null },
                };
            });
            setShowAssignOwnerModal(true);
        });
    }

    const onConfirmAssignOwner = () => {
        setShowLoader(true);
        callApi(`/sourcing/api/events/plans/${assignOwnerContext.eventPlan.id}/assignowner`, {
            method: 'POST',
            data: {
                ...assignOwnerContext
            }
        }, true)
        .then(() => {
            getEvents(filterText).then(r => setEvents(r.data.map(mapEvent))).finally(() => setShowLoader(false));
        })
        .finally(() => {
            setShowAssignOwnerModal(false);
            setAssignOwnerContext({});
        });
    }

    const onShareEvent = (event) => {
        setShareContext(event);
        setShowShareModal(true);
    }

    const onChangeView = viewName => {
        setViewName(viewName);
        window.analytics.track(`Planner changed My RFPs view to ${viewName} view`);
    }

    const onDeleteEvent = (event) => {
        setDeleteContext(event);
        setShowConfirmModal(true);
    }

    const onConfirmDelete = () => {
        setShowLoader(true);
        callApi(`/sourcing/api/events/plans/${deleteContext.id}`, { method: 'DELETE' }, true)
            .catch(error => defaultErrorNotification(error))
            .then(() => {
                getEvents(filterText).then(r => setEvents(r.data.map(mapEvent))).finally(() => setShowLoader(false));
            }).finally(
                setShowConfirmModal(false)
            );
    }

    const onCancelEvent = (event) => {
        setCancelContext({...event});
        setShowCancelModal(true);
    }

    const onCancelCancel = () => {
        setCancelContext({});
        setShowCancelModal(false);
    }
    
    const onConfirmCancel = () => {
        setShowLoader(true);
        setShowCancelModal(false);
        getEvents(filterText).then(r => setEvents(r.data.map(mapEvent))).finally(() => setShowLoader(false));
    }

    const filterEvents = event => {
        if (!filterText && !!ownerFilter.selected?.id) {
            return event.ownerId === ownerFilter.selected.id;
        }

        return (!!ownerFilter.selected?.id ? event.ownerId === ownerFilter.selected.id : true)
        && (event.name?.toLowerCase()?.includes(filterText.toLowerCase())
        || event.rfpCode?.toLowerCase()?.includes(filterText.toLowerCase())
        || event.organizationName?.toLowerCase()?.includes(filterText.toLowerCase())
        || `${event.planners[0].firstName} ${event.planners[0].lastName} ${event.planners[0].id}`.toLowerCase().includes(filterText.toLowerCase()));
    }

    return (
        <div className='planner-dashboard-container'>
            {showLoader && <Loader />}
            <ReportBoardRow>
                <ReportBoardCol style={{ padding: 0 }}>
                    <ReportBoardRow>
                        <ReportBoardCol sm={2}>
                            <ReportCard
                                title="Active RFPs"
                                height={reportCardHeights.singlePlus}
                            >
                                <DonutChart
                                    width='100%'
                                    height='100%'
                                    metricValue={formatNumber(metrics.activeRfps.length)}
                                    metricCalloutStyle='no-legend'
                                >
                                    <Pie
                                        dataKey="count"
                                        data={metrics.activeStatuses}
                                        cx='50%'
                                        cy='45%'
                                        innerRadius='65%'
                                        outerRadius='85%'
                                    />
                                </DonutChart>
                            </ReportCard>
                        </ReportBoardCol>
                        {dashboardStatuses.filter(s => s.active).map((s, i) => {
                            const count = metrics.activeStatuses.find(a => a.status === s.status)?.count ?? 0;
                            const other = metrics.activeRfps.length - count;
                            return (
                                <ReportBoardCol key={i}>
                                    <ReportCard
                                        title={EventPlanStatusMap[s.status].label}
                                        height={reportCardHeights.fit}
                                    >
                                        <Metric
                                            value={count}
                                        />
                                        <StackedBar
                                            hideLegend={true}
                                            data={[
                                                { label: EventPlanStatusMap[s.status].label, formattedValue: formatNumber(count), value: count, color: s.fill },
                                                { label: 'Other', formattedValue: formatNumber(other), value: other, color: '#c0cadd' }
                                            ]}
                                        />
                                    </ReportCard>
                                </ReportBoardCol>
                            );
                        })}
                        <ReportBoardCol>
                            <ReportCard title="Overdue RFPs"
                                height={reportCardHeights.singlePlus}
                            >
                                <div style={{ width: '40%', display: 'inline-block', height: '100%' }}>
                                    <DonutChart
                                        width='100%'
                                        height='100%'
                                        metricValue={formatNumber(metrics.overdueRfps[0]?.count ?? 0)}
                                        metricCalloutStyle='no-legend'
                                    >
                                        <Pie
                                            dataKey="count"
                                            data={metrics.overdueRfps}
                                            cx='50%'
                                            cy='45%'
                                            innerRadius='65%'
                                            outerRadius='85%'
                                        />
                                    </DonutChart>
                                </div>
                                <div style={{ width: '60%', display: 'inline-block', height: '100%', color: '#B82B45' }}>
                                    {metrics.overdueRfps[0]?.count === 1 ? 'RFP overdue for a decision' : 'RFPs overdue for decisions'}
                                </div>
                            </ReportCard>
                        </ReportBoardCol>
                    </ReportBoardRow>
                </ReportBoardCol>
                <ReportBoardCol sm={2}>
                    <ReportCard title="Awarded"
                        height={reportCardHeights.double}
                    >
                        <Link to={`/planner/reports/bookings`}>
                            <div style={{ width: '60%', display: 'inline-block', height: '50%', color: '#027587' }}>
                                <Metric
                                    size='small'
                                    value={formatCurrency(metrics.awardedRfps.reduce((a,c) => a + c.value, 0))}
                                />
                            </div>
                        </Link>
                        <div style={{ width: '40%', display: 'inline-block', height: '50%' }}>
                            <DonutChart
                                width='100%'
                                height='100%'
                                metricValue={formatNumber(metrics.awardedRfps.reduce((a,c) => a + c.count, 0))}
                                metricCalloutStyle='no-legend'
                            >
                                <Pie
                                    dataKey="count"
                                    data={metrics.awardedRfps}
                                    cx='50%'
                                    cy='55%'
                                    innerRadius='70%'
                                    outerRadius='90%'
                                />
                            </DonutChart>
                        </div>
                        <div style={{ height: '20%' }}>
                            {metrics.awardedRfps.map((s, i) => (
                                <div key={i} style={{ display: 'inline-block', marginLeft: '0.75rem', width: 'fit-content' }}>
                                    <div style={{
                                        width: '1rem',
                                        height: '1rem',
                                        backgroundColor: s.fill,
                                        display: 'inline-block',
                                        marginRight: '0.4rem'
                                    }} />
                                    <span style={{ fontSize: '0.8rem' }}>{EventPlanStatusMap[s.name].label}</span>
                                </div>
                            ))}
                        </div>
                    </ReportCard>
                </ReportBoardCol>
            </ReportBoardRow>
            <ReportBoardRow>
                <ReportBoardCol>
                    <ReportCard
                        title="My RFPs"
                        height={reportCardHeights.fit}
                        toolsMiddle={(
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="view-by">
                                    <span className="view-by-label text-muted">View by{' '}</span>
                                    <ButtonGroup>
                                        {[ viewNames.list.key, viewNames.pipeline.key ].map((view, i) => (
                                            <Button
                                                key={i}
                                                variant={`${viewName === view ? '' : 'outline-'}secondary`}
                                                onClick={() => onChangeView(view)}
                                            >
                                                {viewNames[view].label}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                            </div>
                        )}
                        tools={(
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle as={ActionToggle} id="dropdown-actions">
                                        <FontAwesomeIcon
                                            icon={faFilterList}
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        {!!ownerFilter.selected?.id ? ownerFilter.selected.name : 'RFP Owner'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {!!ownerFilter.selected?.id ? (
                                            <Dropdown.Item
                                                onClick={() => setOwnerFilter(s => {
                                                    return {
                                                        ...s,
                                                        selected: null
                                                    }
                                                })}
                                            >
                                                Clear Filter
                                            </Dropdown.Item>
                                        ) : null}
                                        {ownerFilter.filters.sort((c,n) => c.name > n.name ? 1 : -1).map((owner, i) => (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={() => setOwnerFilter(s => {
                                                    return {
                                                        ...s,
                                                        selected: owner
                                                    };
                                                })}
                                                title={owner.id}
                                            >
                                                {owner.name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                    >
                        <InputGroup className='rfp-filter-group'>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                placeholder="Search RFPs"
                                value={filterText}
                                onChange={e => {
                                    setFilterText(e.target.value);
                                    if (userProfile.role === ROLE_ADMIN) {
                                        onSearchAdmin(e.target.value);
                                    }
                                }}
                            />
                        </InputGroup>
                        {viewName === viewNames.pipeline.key ? (
                            <RfpViewPipeline
                                events={events.filter(filterEvents)}
                                onAssignOwner={onAssignOwner}
                                onShareEvent={onShareEvent}
                                onMarkContractSigned={onMarkContractSigned}
                                onCopyEvent={onCopyEvent}
                                onCancelEvent={onCancelEvent}
                                onDeleteEvent={onDeleteEvent}
                            />
                        ) : viewName === viewNames.list.key ? (
                            <RfpViewGrid
                                events={events.filter(filterEvents)}
                                onAssignOwner={onAssignOwner}
                                onShareEvent={onShareEvent}
                                onMarkContractSigned={onMarkContractSigned}
                                onCopyEvent={onCopyEvent}
                                onCancelEvent={onCancelEvent}
                                onDeleteEvent={onDeleteEvent}
                            />
                        ) : null}
                    </ReportCard>
                </ReportBoardCol>
                <ReportBoardCol sm={2}>
                    <ReportCard
                        title="Unread Proposals"
                        height={reportCardHeights.fit}
                        tools={(
                            <FontAwesomeIcon
                                className='metric-icon danger'
                                icon={faCircleBookOpen}
                            />
                        )}
                    >
                        <Metric
                            value={formatNumber(metrics.unreadProposals.length)}
                            valueHint='Proposals'
                            hintSize='large'
                        />
                        <div
                            className="abs-top-right"
                        >
                            <Form.Group
                                className="right"
                                style={{ marginTop: '1rem' }}
                            >
                                <Form.Label className="text-muted" style={{ fontSize: '0.9rem', marginBottom: 0 }}>View details</Form.Label>
                                <Form.Switch
                                    id="unread-proposals-switch"
                                    checked={detailsToggle === detailsToggleKeys.unreadProposals}
                                    onChange={e => setDetailsToggle(e.target.checked ? detailsToggleKeys.unreadProposals : null)}
                                    disabled={metrics.unreadProposals.length === 0}
                                />
                            </Form.Group>
                        </div>
                        {detailsToggle === detailsToggleKeys.unreadProposals && (
                            <ListGroup
                                style={{ marginLeft: '-0.5rem' }}
                            >
                                {metrics.unreadProposals.reduce((a,c) => {
                                    const rfp = a.find(p => p.eventPlanId === c.eventPlanId);
                                    if (!rfp) {
                                        a.push({
                                            eventPlanId: c.eventPlanId,
                                            eventPlanName: c.eventPlanName,
                                            proposals: [{
                                                venueId: c.venueId,
                                                venueName: c.venueName,
                                                submitted: c.submitted,
                                            }]
                                        });
                                    } else {
                                        rfp.proposals.push({
                                            venueId: c.venueId,
                                            venueName: c.venueName,
                                            submitted: c.submitted,
                                        });
                                    }
                                    return a;
                                }, []).sort((c,n) => c.eventPlanName > n.eventPlanName ? 1 : -1).map((e, i) => (
                                    <ListGroupItem
                                        style={{ padding: '0.5rem 1rem' }}
                                        key={i}
                                    >
                                        <Link
                                            className='text-muted overflow-ellipsis w15r'
                                            to={`/event/${e.eventPlanId}`}
                                        >
                                                {e.eventPlanName}
                                        </Link>
                                        {e.proposals.sort((c,n) => c.venueName > n.venueName ? 1 : -1).map((p, i2) => (
                                            <div style={{ marginTop: '0.35rem' }}>
                                                <Link
                                                    key={i2}
                                                    className='overflow-ellipsis w15r'
                                                    to={`/event/${p.eventPlanId}/proposal/${p.venueId}`}
                                                >
                                                    {p.venueName}
                                                    <div className='text-muted' style={{ fontSize: '0.8rem', fontStyle: 'italic' }}>
                                                        proposal received {format(parseISO(p.submitted), 'MMM d, yyyy')}
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        )}
                    </ReportCard>
                    <MessagingDashboardTile
                        viewDetails={detailsToggle === detailsToggleKeys.unreadMessages}
                        onChangeViewDetails={view => setDetailsToggle(view ? detailsToggleKeys.unreadMessages : null)}
                    />
                    <ReportCard
                        title="Responses Due This Week"
                        height={reportCardHeights.fit}
                        tools={(
                            <FontAwesomeIcon
                                className='metric-icon primary'
                                icon={faCircleCalendar}
                            />
                        )}
                    >
                        <Metric
                            value={metrics.responsesDueThisWeek.length}
                            valueHint={`RFP${metrics.responsesDueThisWeek.length === 1 ? '' : 's'}`}
                            hintSize='large'
                        />
                        <div
                            className="abs-top-right"
                        >
                            <Form.Group
                                className="right"
                                style={{ marginTop: '1rem' }}
                            >
                                <Form.Label className="text-muted" style={{ marginBottom: 0 }}>View details</Form.Label>
                                <Form.Switch
                                    id="due-rfps-switch"
                                    checked={detailsToggle === detailsToggleKeys.responsesDueThisWeek}
                                    onChange={e => setDetailsToggle(e.target.checked ? detailsToggleKeys.responsesDueThisWeek : null)}
                                    disabled={metrics.responsesDueThisWeek.length === 0}
                                />
                            </Form.Group>
                        </div>
                        {detailsToggle === detailsToggleKeys.responsesDueThisWeek && (
                            <ListGroup
                                style={{ marginLeft: '-0.5rem' }}
                            >
                                {metrics.responsesDueThisWeek.map((e, i) => (
                                    <ListGroup.Item
                                        key={i}
                                    >
                                        <Link
                                            className='overflow-ellipsis w15r'
                                            to={`/event/${e.id}/tracker`}
                                        >
                                            {e.name}
                                        </Link>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                    </ReportCard>
                    <ReportCard
                        title="Selections Due This Week"
                        height={reportCardHeights.fit}
                        tools={(
                            <FontAwesomeIcon
                                className='metric-icon primary'
                                icon={faCircleCalendar}
                            />
                        )}
                    >
                        <Metric
                            value={metrics.selectionsDueThisWeek.length}
                            valueHint={`RFP${metrics.selectionsDueThisWeek.length === 1 ? '' : 's'}`}
                            hintSize='large'
                        />
                        <div
                            className="abs-top-right"
                        >
                            <Form.Group
                                className="right"
                                style={{ marginTop: '1rem' }}
                            >
                                <Form.Label className="text-muted" style={{ marginBottom: 0 }}>View details</Form.Label>
                                <Form.Switch
                                    id="select-proposals-switch"
                                    checked={detailsToggle === detailsToggleKeys.selectionsDueThisWeek}
                                    onChange={e => setDetailsToggle(e.target.checked ? detailsToggleKeys.selectionsDueThisWeek : null)}
                                    disabled={metrics.selectionsDueThisWeek.length === 0}
                                />
                            </Form.Group>
                        </div>
                        {detailsToggle === detailsToggleKeys.selectionsDueThisWeek && (
                            <ListGroup
                                style={{ marginLeft: '-0.5rem' }}
                            >
                                {metrics.selectionsDueThisWeek.map((e, i) => (
                                    <ListGroup.Item
                                        key={i}
                                    >
                                        <Link
                                            className='overflow-ellipsis w15r'
                                            to={`/event/${e.id}/tracker`}
                                        >
                                            {e.name}
                                        </Link>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                    </ReportCard>
                </ReportBoardCol>
            </ReportBoardRow>
            <Modal show={assignOwnerModal} onHide={() => setShowAssignOwnerModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Owner of {assignOwnerContext.eventPlan?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        The new owner will be able to edit and send this RFP, and will receive all notifications.
                    </div>
                    <Form.Group>
                        <Form.Label>Assign owner:</Form.Label>
                        <Form.Control
                            as="select"
                            value={assignOwnerContext.newOwner?.id || ''}
                            onChange={e => setAssignOwnerContext(s => {
                                return {
                                    ...s,
                                    newOwner: { id: e.target.value }
                                };
                            })}
                        >
                            <option value="">Select owner</option>
                            {organizationMembers.filter(m => m.id !== assignOwnerContext.eventPlan?.ownerId).sort(sortMembers).map((m, i) => (
                                <option key={i} value={m.id}>
                                    {!!m.firstName && !!m.lastName ? `${m.firstName} ${m.lastName} (${m.id})` : m.id}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowAssignOwnerModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onConfirmAssignOwner}
                        disabled={!assignOwnerContext.newOwner?.id}
                    >
                        Assign
                    </Button>
                </Modal.Footer>
            </Modal>
            {showShareModal && (
                <Share event={shareContext} show={showShareModal} onCancel={() => setShowShareModal(false)} onSubmit={() => setShowShareModal(false)} />
            )}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete RFP</Modal.Title>
                </Modal.Header>
                {deleteContext.created && (
                    <Modal.Body>
                        Are you sure you want to delete the {deleteContext.name ? `${deleteContext.name} RFP` : `new RFP created on ${format(parseISO(deleteContext.created), 'eee M/d @ h:mmaaa')}`}?
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowConfirmModal(false)}>
                        No, don't delete
                    </Button>
                    <Button variant="success" onClick={onConfirmDelete}>
                        Yes, delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <EventCancelModal
                show={showCancelModal}
                event={cancelContext}
                onClose={onCancelCancel}
                onConfirm={onConfirmCancel}
            />
        </div>
    );
}