import React, { useContext } from 'react';
import { useHistory ,useParams } from 'react-router-dom'
import { isDesktop } from 'react-device-detect';

import { ROLE_AFFILIATE, ROLE_DESTINATIONMANAGER, ROLE_HOTELIER, ROLE_SUPPLIER, UserProfileContext } from '../../userProfileContext';

import { Chat } from '../../components/chat';

const ChatView = () => {
    const { userProfile } = useContext(UserProfileContext);
    const { eventPlanId, venueId } = useParams();
    const history = useHistory();

    if (isDesktop) {
        switch (userProfile.role) {
            case ROLE_AFFILIATE:
            case ROLE_SUPPLIER:
            case ROLE_DESTINATIONMANAGER:
                history.push(`/supplier/request/${eventPlanId}/messaging`);
                break;
        
            case ROLE_HOTELIER:
                history.push(`/proposal/${eventPlanId}/${venueId}/messaging`);
                break;
        
            default:
                history.push(`/event/${eventPlanId}/messaging${venueId ? `/${venueId}` : ''}`);
                break;
        }
        return <div>Please wait...</div>;
    }
    
    return (
        <Chat conversationUniqueName={`${eventPlanId}${venueId ? `|${venueId}` : ''}`} />
    );
}
export default ChatView;