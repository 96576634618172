import React from 'react';
import { parseISO, format } from 'date-fns';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import { formatCurrency, formatNumber } from '../../../common';
import { EventPlanStatusMap } from '../../../common/statusMaps';

import { DataGrid } from '../../data-grid';
import { RfpCode } from '../../rfp-code';
import { ActionToggle } from '../../buttons/action-toggle';
import { faFileSlash } from '@fortawesome/pro-duotone-svg-icons';

export const EventsList = ({ events, onMarkContractSigned, onShareEvent, onCopyEvent, onCancelEvent, onDeleteEvent }) => {

    const formatActions = (event) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <Dropdown>
                    <Dropdown.Toggle as={ActionToggle} id="dropdown-actions">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {event.status === 'Contracting' && (<Dropdown.Item onClick={() => onMarkContractSigned(event)}>Mark as Contract Signed</Dropdown.Item>)}
                        <Dropdown.Item onClick={() => onShareEvent(event)}>Share with planner(s)...</Dropdown.Item>
                        <Dropdown.Item onClick={() => onCopyEvent(event)}>Duplicate RFP...</Dropdown.Item>
                        {event.status === 'New' && (
                            <Dropdown.Item onClick={() => onDeleteEvent(event)}>Delete</Dropdown.Item>
                        )}
                        {['New','Cancelled'].includes(event.status) === false && (
                            <Dropdown.Item onClick={() => onCancelEvent(event)}>Cancel</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    const formatName = event => {
        return (
            <>
                <Link
                    style={{ textAlign: 'left', maxWidth: '20rem' }}
                    to={`/event/${event.id}`}
                >
                    <b>{event.name || `New RFP created on ${format(parseISO(event.created), 'eee M/d @ h:mmaaa')}`}</b>
                </Link>
                <br/>
                <RfpCode rfpCode={event.rfpCode} />
            </>
        );
    }

    const formatStatus = event => {
        return (
            <div style={{ position: 'relative', color: !!event.proposalsPaused ? '#B82B45' : '#000' }}>
                {event.statusText}
                {!!event.proposalsPaused ? (
                    <div style={{ position: 'absolute', top: '0', right: '0' }}>
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id={`ep-${event.id}-tt-p`}>
                                    {`New proposals stopped by ${event.proposalsPausedBy} on ${format(parseISO(event.proposalsPausedAt), 'M/d/yy')}`}
                                </Tooltip>
                            }
                        >
                            <Link
                                to={`/event/${event.id}/tracker`}
                            >
                                <FontAwesomeIcon
                                    style={{ color: '#B82B45' }}
                                    icon={faFileSlash}
                                />
                            </Link>
                        </OverlayTrigger>
                    </div>
                ) : null}
            </div>
        );
    }
    
    const formatRequests = event => {
        return (
            <>
                {event.meetingSpaceRequired && event.meetingSpaceRequests.length > 0 && (
                    <>
                        {event.peakMeetingRoomsRequired > 0 && (
                            <div style={{ fontSize: 'smaller' }}>{`${formatNumber(event.peakMeetingRoomsRequired)} Meeting Room${event.peakMeetingRoomsRequired === 1 ? '' : 's'} on Peak`}</div>
                        )}
                        {event.peakMeetingSpaceRequired > 0 && (
                            <div style={{ fontSize: 'smaller' }}>{`${formatNumber(event.peakMeetingSpaceRequired)} Square Feet on Peak`}</div>
                        )}
                    </>
                )}
                {event.roomBlocksRequired && (
                    <>
                        {event.totalRoomsRequested && (
                            <div style={{ fontSize: 'smaller' }}>{`${formatNumber(event.totalRoomsRequested)} Total Room Nights`}</div>
                        )}
                        {event.peakRooms && (
                            <div style={{ fontSize: 'smaller' }}>{`${formatNumber(event.peakRooms)} Rooms on Peak`}</div>
                        )}
                        {event.totalRoomsBudget > 0 && (
                            <div style={{ fontSize: 'smaller' }}>{`${formatCurrency(event.totalRoomsBudget)} Room Budget`}</div>
                        )}
                    </>
                )}
            </>
        );
    }

    const formatPlanner = event => {
        return (
            <>
                {event.planner}
                <br />
                <a href={`mailto:${event.planners[0].id}`}>{event.planners[0].id}</a>
            </>
        );
    }

    const formatDate = d => {
        return d ? format(parseISO(d), 'eee M/d/yyyy') : '';
    }

    const dataset = {
        fields: [
            { name: 'name', header: 'Name', filter: false, format: item => formatName(item), },
            { name: 'created', header: 'Created', filter: false, format: item => formatDate(item.created), },
            { name: 'submitted', header: 'RFP Sent', filter: false, format: item => formatDate(item.submitted), },
            events.every(e => e.status === 'Cancelled')
            ? { name: 'cancelled', header: 'Cancelled', filter: false, format: item => formatDate(item.cancelled), }
            : { name: 'statusText', header: 'Status', filter: false, format: formatStatus, },
            { name: 'startDate', header: 'Start Date', filter: false, format: item => formatDate(item.startDate), },
            { name: 'endDate', header: 'End Date', filter: false, format: item => formatDate(item.endDate), },
            { name: 'requests', header: 'Requests', filter: false, format: item => formatRequests(item), },
            { name: 'planner', header: 'Planner', filter: false, format: item => formatPlanner(item), },
            { name: 'actions', header: 'Actions', filter: false, format: item => formatActions(item), },
        ],
        defaultSort: { field: 'startDate', direction: 1 },
        data: [
            ...events.map(e => {
                return {
                    ...e,
                    planner: `${e.planners[0].firstName} ${e.planners[0].lastName}`,
                    statusText: EventPlanStatusMap[e.status].label,
                }
            })
        ],
    };

    return (
        <DataGrid dataSet={dataset} />
    );
}