import { callApi } from "../apiProvider";

export const getUserProfileLoginObjectId = ({ claims }) => {
    return claims.find(c => c.type === "http://schemas.microsoft.com/identity/claims/objectidentifier")?.value;
}

export const getUserProfile = id => {
    return callApi(
        `/api/userProfiles/${id}`,
        { method: 'GET' },
        true
    );
}

export const getUserProfileRfpSupportTeam = id => {
    return callApi(
        `/api/userProfiles/${id}/rfpsupportteam`,
        { method: 'GET' },
        true
    );
}

export const getUserProfiles = ({ filter, top, skip, orderBy }) => {
    return callApi(
        `/api/userprofiles?$filter=${filter}&$offset=${skip}&$limit=${top}&$orderby=${orderBy.field}+${orderBy.direction < 0 ? 'desc' : 'asc'}`,
        {
            method: 'GET',
        },
        true
    );
}

export const getUserProfileDataGridSettings = ({ userProfileId, dataGridId }) => {
    return callApi(
        `/api/userprofiles/${userProfileId}/datagridsettings/${dataGridId}`,
        {
            method: 'GET',
        },
        true
    );
}

export const upsertUserProfileDataGridSettings = ({ userProfileId, dataGridId, dataGridSettings }) => {
    return callApi(
        `/api/userprofiles/${userProfileId}/datagridsettings/${dataGridId}`,
        {
            method: 'PUT',
            data: {
                dataGridSettings,
            }
        },
        true
    );
}

export const resetUserProfileDataGridSettings = ({ userProfileId, dataGridId }) => {
    return callApi(
        `/api/userprofiles/${userProfileId}/datagridsettings/${dataGridId}`,
        {
            method: 'DELETE',
        },
        true
    );
}