import React, { Suspense } from 'react';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import Loader from './components/loader';
import Chat from './containers/Chat';
import { SidebarNavContainer } from './containers/sidebar-nav';

export const AppRouter = () => {
  
  return (
    <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
              <Route path="/chat" name="App" component={Chat} />
              <Route path="/" name="App" component={SidebarNavContainer} />
          </Switch>
        </Suspense>
    </Router>
  );
}