import React from 'react';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

const TabStatus = props => {
    const { errors } = props;

    const overlayErrors = () => {
        return (
            <Popover id="popover-basic">
                <Popover.Title as="h4">{errors.length > 0 ? 'Incomplete Information' : 'All Good!'}</Popover.Title>
                {errors.length > 0 &&
                    <Popover.Content>{errors.map((e, i) => (
                        <div key={i}>{e}</div>
                    ))}</Popover.Content>
                }
            </Popover>
        );
    }

    return (
        <OverlayTrigger
            placement="right"
            overlay={overlayErrors()}
        >
            <Badge>
                <FontAwesomeIcon style={{ color: errors.length === 0 ? '#4FC0B0' : '#F86866'}} icon={errors.length === 0 ? faCheckCircle : faExclamationCircle} />
            </Badge>
        </OverlayTrigger>
    );
}
export default TabStatus;