import React, { useEffect, useState, useContext } from 'react';
import { ChatConversation } from './chat-conversation';
import { Badge } from 'react-bootstrap';
import { UserProfileContext } from '../../userProfileContext';
import { ChatContext } from '../../chatContext';
import { ROLE_ADMIN, ROLE_PLANNER } from '../../userProfileContext';
import { getMessagingStateData } from '../../chatProvider';

export const Chat = ({ conversationUniqueName }) => {
  const { userProfile } = useContext(UserProfileContext);
  const { chatClientInitialized, chatClient, parseConversationDisplayName } = useContext(ChatContext);
  const [chatState, setChatState] = useState({ ready: false, message: 'Waiting to connect', badgeVariant: 'info' });
  const [conversationProxy, setConversationProxy] = useState(null);

  useEffect(() => {
    window.Intercom('update', { "hide_default_launcher": true });
  }, []);

  useEffect(() => {
    if (chatClientInitialized) {
      const connectionStateChanged = state => {
        setChatState(getMessagingStateData(state));
        if (state === 'connected') {
          chatClient.getConversationByUniqueName(conversationUniqueName).then(c => {
            setConversationProxy(c);
          });
        }
      };

      chatClient.on('connectionStateChanged', connectionStateChanged);

      return (() => {
        chatClient.off('connectionStateChange', connectionStateChanged);
      });
    } else {
      setChatState(getMessagingStateData('connecting'));
    }
  }, [chatClientInitialized, chatClient, conversationUniqueName]);

  const formatChatTitle = friendlyName => {
    const title = parseConversationDisplayName(friendlyName);
    return (
      <>
        <div>{title.rfpName}</div>
        <div>{title.venueName}</div>
      </>
    );
  }

  return (
    <div id="chat-container">
      <div className='chat-header'>
        {conversationProxy && (
          <div>
            {formatChatTitle(conversationProxy.friendlyName)}
          </div>
        )}
        <Badge pill variant={chatState.badgeVariant}>{chatState.message}</Badge>
      </div>
      {userProfile.id && conversationProxy && (
        <ChatConversation
          conversationProxy={conversationProxy}
          myIdentity={userProfile.id}
          canInitiate={[ROLE_ADMIN, ROLE_PLANNER].includes(userProfile.role)}
          standalone={true}
        />
      )}
    </div>
  );
}