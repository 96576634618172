import React, { useContext, useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { getAccessToken, getServiceHostAndEndpointPath } from "./apiProvider";
import { UserProfileContext } from './userProfileContext';

export const RealtimeContext = React.createContext(null);

export const HUB_USERPROFILE = 'user-profile';

export const RealtimeProvider = ({ children }) => {
    const { setUserProfile } = useContext(UserProfileContext);
    const [hubConnection, setHubConnection] = useState({});
    
    const startConnection = async connection => {
        try {
            await connection.start();
            console.log("signalr connected");
        } catch (err) {
            console.log(err);
            setTimeout(() => startConnection(connection), 5000);
        }
    }

    const initializeHub = (hubName, userProfileId) => {
        
        const hubUrl = getServiceHostAndEndpointPath(`/rtw/api/${hubName}/${userProfileId}`);
        const connection = new HubConnectionBuilder()
            .withUrl(`${hubUrl.host}${hubUrl.endpointPath}`,
                {
                    accessTokenFactory: () => {
                        return getAccessToken();
                    }
                }
            )
            .configureLogging(LogLevel.Information)
            .build();

        connection.on('update', message => {
            const {
                associatedVenues,
                isPaidSubscriber,
                licenseInfo,
                organizationId,
                organizationName,
                role,
                disabled,
                isDisabled,
                disabledReason,
                accountType,
                venueMatchCriteria,
                permissions,
                organizationRoles,
                previewExperiences,
            } = message.userProfile;

            setUserProfile(s => {
                return {
                    ...s,
                    associatedVenues,
                    isPaidSubscriber,
                    licenseInfo,
                    licenseTier: licenseInfo?.tier,
                    organizationId,
                    organizationName,
                    role,
                    disabled,
                    isDisabled,
                    disabledReason,
                    accountType,
                    venueMatchCriteria,
                    permissions,
                    organizationRoles,
                    previewExperiences,
                }
            });
        });

        connection.onclose(async () => {
            console.log('disconnected');
            await startConnection(connection);
        });

        console.log('connecting...');
        startConnection(connection);
        setHubConnection(connection);
    }

    useEffect(() => {
        console.log(`hubConnection.connectionState = ${hubConnection.connectionState}`);
    }, [hubConnection.connectionState]);

    return (
        <RealtimeContext.Provider value={{
            initializeHub
        }}>
            {children}
        </RealtimeContext.Provider>
    );
}