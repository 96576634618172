import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { PieChart, ResponsiveContainer } from 'recharts';
import './donut-chart.scss';

export const DonutChart = props => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.setTimeout(() => {
            setLoading(false);
        }, 1500)
    }, [props.width, props.height]);

    return (
        <div className="donut-chart-container">
            {props.metricValue && (
                <div className={`metric-callout${props.metricCalloutStyle ? ` ${props.metricCalloutStyle}` : ''}`}>
                    <div className='metric large'>
                        {props.metricValue}
                    </div>
                    {props.metricLabel && (
                        <div className='metric label'>
                            {props.metricLabel}
                        </div>
                    )}
                </div>
            )}
            {(loading && (
                <Spinner />
            )) || (
                <ResponsiveContainer width={props.width} height={props.height}>
                    <PieChart>
                        {props.children}
                    </PieChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}