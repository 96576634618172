import React, { useEffect, useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';

export const RfpCode = ({ rfpCode, fontSize }) => {
    const [copied, setCopied] = useState(false);
    const buttonRef = useRef();

    useEffect(() => {
        setTimeout(() => setCopied(false), 2000);
    }, [copied]);

    const onClickCopy = () => {
        navigator.clipboard.writeText(rfpCode);
        setCopied(true);
    }

    return (
        <span style={{ color: '#898989', fontSize: fontSize ?? '1rem' }}>
            RFP CODE: {rfpCode}
            <FontAwesomeIcon
                icon={faCopy}
                ref={buttonRef}
                style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                title='Copy to clipboard'
                onClick={onClickCopy}
            />
            <Overlay target={buttonRef.current} show={copied} onHide={() => {}} rootClose>
                {(props) => (
                    <Tooltip id="copy-text-tooltip" {...props}>
                        Copied!
                    </Tooltip>
                )}
            </Overlay>
        </span>
    );
}