import React from 'react';
import { Row, Col } from 'react-bootstrap';

export const ReportBoardRow = props => {
    return (
        <Row style={{ margin: 0 }}>
            {props.children}
        </Row>
    );
}

export const ReportBoardCol = props => {
    return (
        <Col {...props} style={{ padding: 0 }}>
            {props.children}
        </Col>
    );
}