import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle, faQuestionCircle, faInfoCircle as faInfoCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

export const WhatsThisStyles = {
    success: { badgeVariant: 'success', icon: faCheckCircle, color: '#4FC0B0' },
    info: { badgeVariant: 'info', icon: faInfoCircle, color: '#073d57' },
    warning: { badgeVariant: 'danger', icon: faExclamationCircle, color: '#F86866' },
    help: { badgeVariant: 'info', icon: faQuestionCircle, color: '#073d57' },
    infoSolid: { badgeVariant: 'info', icon: faInfoCircleSolid, color: '#fff' },
};

const WhatsThis = ({ id, style, title, content, verticalAlign, containerStyle }) => {

    const popover = () => {
        return (
            <Popover id={`whats-this-${id}`}>
                {title && (<Popover.Title as="h4">{title}</Popover.Title>)}
                <Popover.Content>{content}</Popover.Content>
            </Popover>
        );
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={popover()}
        >
            <div style={{
                marginLeft: '.5em',
                verticalAlign: verticalAlign || 'super',
                display: 'inline-block',
                ...containerStyle
            }}>
                <FontAwesomeIcon icon={(style && style.icon) || faInfoCircle } color={(style && style.color) || '#073d57'} />
            </div>
        </OverlayTrigger>
    );
}
export default WhatsThis;