import { useEffect, useState } from "react";
import { callApi } from "../apiProvider";

export const getOrganization = (organizationId) => {
    return callApi(
        `/api/organizations/${organizationId}`,
        {
            method: 'GET'
        },
        true
    );
}

export const getMembers = (organizationId) => {
    return callApi(
        `/api/organizations/${organizationId}/members`,
        {
            method: 'GET'
        },
        true
    );
}

export const sortMembers = (c, n) => {
    const currentFirstName = c.firstName ?? '';
    const currentLastName = c.lastName ?? '';
    const nextFirstName = n.firstName ?? '';
    const nextLastName = n.lastName ?? '';
    const currentId = c.id ?? '';
    const nextId = n.id ?? '';
    if (currentFirstName === nextFirstName) {
        if (currentLastName === nextLastName) {
            return currentId > nextId ? 1 : -1;
        }
        return currentLastName > nextLastName ? 1 : -1;
    }
    return currentFirstName > nextFirstName ? 1 : -1;
}

export const getSourcingProfiles = (organizationId) => {
    return callApi(
        `/api/organizations/${organizationId}/sourcingProfiles`,
        {
            method: 'GET'
        },
        true
    );
}

export const upsertSourcingProfile = (organizationId, sourcingProfileId, sourcingProfile) => {
    if (!!sourcingProfileId) {
        return updateSourcingProfile(organizationId, sourcingProfileId, sourcingProfile);
    } else {
        return addSourcingProfile(organizationId, sourcingProfile);
    }
}

export const addSourcingProfile = (organizationId, sourcingProfile) => {
    return callApi(
        `/api/organizations/${organizationId}/sourcingProfiles/`,
        {
            method: 'POST',
            data: { ...sourcingProfile }
        },
        true
    );
}

export const updateSourcingProfile = (organizationId, sourcingProfileId, sourcingProfile) => {
    return callApi(
        `/api/organizations/${organizationId}/sourcingProfiles/${sourcingProfileId}`,
        {
            method: 'PUT',
            data: { ...sourcingProfile }
        },
        true
    );
}

export const deleteSourcingProfile = (organizationId, sourcingProfileId) => {
    return callApi(
        `/api/organizations/${organizationId}/sourcingProfiles/${sourcingProfileId}`,
        { method: 'DELETE' },
        true
    );
}

export const mapSourcingProfileToAssociatedObject = (sourcingProfile) => {
    return {
        ...sourcingProfile,
        name: sourcingProfile.code,
        partitionKey: sourcingProfile.organizationId,
    };
}

export const mapAssociatedObjectToSourcingProfile = (associatedObject) => {
    return {
        ...associatedObject,
        code: associatedObject.name,
        organizationId: associatedObject.partitionKey,
    };
}

export const useSourcingProfiles = (organizationId) => {
    const [sourcingProfiles, setSourcingProfiles] = useState([]);

    useEffect(() => {
        getSourcingProfiles(organizationId).then((response) =>
            setSourcingProfiles(response.data)
        );
    }, [ organizationId ]);

    return [
        sourcingProfiles,
        setSourcingProfiles
    ];
}

export const getVenueLists = (organizationId) => {
    return callApi(
        `/sourcing/api/organizations/${organizationId}/venueLists`,
        {
            method: 'GET'
        },
        true
    );
}

export const upsertVenueList = (organizationId, venueListId, venueList) => {
    if (!!venueListId) {
        return updateVenueList(organizationId, venueListId, venueList);
    } else {
        return addVenueList(organizationId, venueList);
    }
}

export const addVenueList = (organizationId, venueList) => {
    return callApi(
        `/sourcing/api/organizations/${organizationId}/venueLists`,
        {
            method: 'POST',
            data: { ...venueList }
        },
        true
    );
}

export const updateVenueList = (organizationId, venueListId, venueList) => {
    return callApi(
        `/sourcing/api/organizations/${organizationId}/venueLists/${venueListId}`,
        {
            method: 'PUT',
            data: { ...venueList }
        },
        true
    );
}

export const deleteVenueList = (organizationId, venueListId) => {
    return callApi(
        `/sourcing/api/organizations/${organizationId}/venueLists/${venueListId}`,
        { method: 'DELETE' },
        true
    );
}

export const mapVenueToAssociatedObject = (venue, properties) => {
    return {
        id: venue.id,
        partitionKey: venue.id,
        name: venue.name,
        itemType: venue.itemType || 'hotel.property',
        properties: {
            address: venue.address,
            city: venue.city,
            state: venue.state,
            zip: venue.zip,
            country: venue.country,
            chainId: venue.chainId,
            brandId: venue.brandId,
            geolocation: venue.geolocation,
            guestRoomQuantity: venue.guestRoomQuantity,
            meetingRoomQuantity: venue.meetingRoomQuantity,
            meetingSpaceSquareFeet: venue.meetingSpaceSquareFeet,
            largestMeetingSpaceSquareFeet: venue.largestMeetingSpaceSquareFeet,
            ...properties
        }
    };
}

export const getUniqueVenues = (venueLists) => {
    return venueLists.reduce((acc, list) => {
        list.venues.forEach((venue) => {
            if (!acc.some((v) => v.id === venue.id)) {
                acc.push({
                    ...venue,
                    lists: [list],
                });
            } else {
                acc.find((v) => v.id === venue.id).lists.push(list);
            }
        });
        return acc;
    }, []);
}

export const useVenueLists = (organizationId) => {
    const [venueLists, setVenueLists] = useState([]);
    const [uniqueVenues, setUniqueVenues] = useState([]);

    useEffect(() => {
        if (!!organizationId) {
            getVenueLists(organizationId).then((response) => {
                setVenueLists(response.data);
                setUniqueVenues(getUniqueVenues(response.data));
            });
        }
    }, [ organizationId ]);

    useEffect(() => {
        if (typeof(venueLists) == 'object' && Array.isArray(venueLists)) {
            setUniqueVenues(getUniqueVenues(venueLists));
        }
    }, [ venueLists ]);

    return [
        venueLists,
        setVenueLists,
        uniqueVenues,
    ];
}

export const getSupplierContacts = (organizationId) => {
    return callApi(
        `/api/organizations/${organizationId}/contacts`,
        {
            method: 'GET'
        },
        true
    );
}