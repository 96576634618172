import { faExclamationCircle, faInfoCircle, faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';

export const warningStyles = {
    warning: 'warning',
    info: 'info',
    danger: 'danger',
    license: 'license',
}

export const warningSizes = {
    small: 'small'
}

const icons = {
    warning: faExclamationCircle,
    info: faInfoCircle,
    danger: faExclamationCircle,
    license: faLockAlt,
}

export const WarningBanner = ({ variant, style, size, width, heading, message, ctaPrompt, onClick }) => {
    return (
        <Alert
            id="warning-banner"
            variant={variant === warningStyles.license ? warningStyles.warning : variant || warningStyles.warning}
            style={{
                ...(style || {}),
                width: width || '100%'
            }}
        >
            <div className={`warning-banner-container ${size || 'normal'}${heading ? ' with-heading' : ''}${message ? ' with-message' : ''}${ctaPrompt ? ' with-prompt' : ''}`}>
                <FontAwesomeIcon icon={icons[variant]} />
                {heading && (
                    <Alert.Heading>
                        {heading}
                    </Alert.Heading>
                )}
                <div className='warning-banner-message'>
                    <div>
                        {message}
                    </div>
                    <div>
                        {typeof(onClick) === 'function' && ctaPrompt && (
                            <Button
                                variant='link'
                                onClick={onClick}
                            >
                                {ctaPrompt}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Alert>
    );
}