import { parseISO } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { callApi } from './apiProvider';
import { UserProfileContext } from './userProfileContext';

export const FeatureContext = React.createContext(null);

const loadApplicationFeatures = () => {
    return callApi(
        `/api/applicationfeatures`,
        { method: 'GET' },
        true
    );
}

export const FeatureProvider = ({ children }) => {
    const { userProfile } = useContext(UserProfileContext);
    const [applicationFeatures, setApplicationFeatures] = useState([]);
    const [licensedFeatures, setLicensedFeatures] = useState([]);
    const [showUpgradePrompt, setShowUpgradePrompt] = useState({ show: false, feature: null });
    const [isTrial, setIsTrial] = useState(false);
    const [canUpgrade, setCanUpgrade] = useState(false);

    useEffect(() => {
        if (userProfile.id) {
            // results are filtered by role (admin == all)
            loadApplicationFeatures().then(r => {
                const tierRank = userProfile.isPaidSubscriber
                    ? r.data.find(f => f.tierKey === userProfile.licenseTier)?.tierRank || 0
                    : 0;
                const features = r.data.map(d => {
                    return {
                        ...d,
                        isLicensed: userProfile.isAdmin ? true : d.tierRank <= tierRank
                    };
                });
                setApplicationFeatures(features);
                setLicensedFeatures(
                    r.data
                        .filter(f => f.key && (userProfile.isAdmin || f.tierRank <= tierRank))
                        .sort((c,n) => n.tierRank - c.tierRank)
                        .reduce((a,c) => {
                            // do not include lower ranked features with the same key
                            if (a.find(f => f.role === c.role && f.key === c.key)) {
                                return a;
                            }
                            return [
                                ...a,
                                {
                                    ...c,
                                    isLicensed: userProfile.isAdmin ? true : c.tierRank <= tierRank
                                }
                            ];
                        }, []
                        )
                );
                const isTrial = userProfile.licenseType?.toLowerCase().includes('trial');
                setIsTrial(isTrial);
                setCanUpgrade(
                    userProfile.isAdmin
                    ||
                    isTrial
                    ||
                    features.some(f => f.isLicensed === false)
                );
            });
        }
    }, [ userProfile.id, userProfile.role, userProfile.isPaidSubscriber, userProfile.isAdmin, userProfile.licenseTier, userProfile.licenseType ]);

    // pass the currentSubscriptionInfo container object for org-level licensing (DMOs, hotels)
    const getFeatureByKey = (key, org) => {
        if (org?.currentSubscriptionInfo) {
            if (org.currentSubscriptionInfo?.startDate) {
                const now = new Date();
                if (parseISO(org.currentSubscriptionInfo.startDate) <= now) {
                    if (!org.currentSubscriptionInfo.expiryDate || parseISO(org.currentSubscriptionInfo.expiryDate) >= now) {
                        const subscriptionTierRank = applicationFeatures.find(f => f.tierKey === org.currentSubscriptionInfo.tier)?.tierRank;
                        return applicationFeatures.sort((c,n) => n.tierRank - c.tierRank).find(f => f.key === key && f.tierRank <= subscriptionTierRank);
                    }
                }
            } else {
                return applicationFeatures.find(f => f.key === key && f.tierRank === 0);
            }
        }
        return licensedFeatures.find(f => f.key === key);
    }

    const getFeatureByKeyAndTier = (key, tier) => {
        return applicationFeatures.find(f => f.key === key && (!tier || f.tierKey === tier));
    }

    const getFeaturesByKeys = (keys) => {
        return licensedFeatures.filter(f => keys.includes(f.key));
    }

    const getNextTier = (role, tier) => {
        const thisTierRank = applicationFeatures.find(f => f.tierKey === tier)?.tierRank;
        return applicationFeatures
            .filter(f => f.role === role && f.tierRank > thisTierRank)
            .sort((c,n) => c.tierRank - n.tierRank)
            .find(f => f.role === role);
    }

    const promptUserToUpgrade = (show, featureKey, tier) => {
        setShowUpgradePrompt({
            show,
            feature: getFeatureByKeyAndTier(featureKey, tier) || null
        });
    }

    return (
        <FeatureContext.Provider value={{ applicationFeatures, licensedFeatures, getFeatureByKey, getFeatureByKeyAndTier, getFeaturesByKeys, getNextTier, canUpgrade, isTrial, promptUserToUpgrade, showUpgradePrompt }}>
            {children}
        </FeatureContext.Provider>
    );
}