import axios from "axios";
import { protectedResources } from './authConfig';
import { msalInstance } from './index';
import { NotificationManager } from 'react-notifications';

export const defaultErrorNotification = error => {
    const title = 'Application error';
    const message = `${error.response.status}: ${error.response.statusText}`;
    NotificationManager.error(message, title);
}

// const ignoreErrors = {
//     get: [
//         '/api/userProfiles/'
//     ],
//     post: [
//         '/selling/api/suppliers/suggestionrequests',
//         '/selling/api/hsadmin/venues/capacities',
//         '/plat/api/venueSourcing/runIndexers',
//         '/sourcing/api/events/plans/',
//         '/rtw/api/eventplanhub/'
//     ],
//     put: [
//         '/selling/api/destinations/',
//         '/selling/api/hub/proposalRequests/',
//         '/sourcing/api/events/plans/'
//     ],
//     delete: [
//         '/sourcing/api/events/plans/'
//     ]
// }

// axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     //special cases
//     const ignorePaths = ignoreErrors[error.config?.method];
//     if (ignorePaths && ignorePaths.length > 0) {
//         if (ignorePaths.some(p => error.config?.url?.indexOf(p) > 0)) {
//             return Promise.reject(error);
//         }
//     }
//     defaultErrorNotification(error);
//     return Promise.reject(error);
//   });

export const callApi = async (path, requestConfig, isSecure, apiKey) => {
    let headers = {};
    if (isSecure) {
        if (!!apiKey) {
            headers['x-api-key'] = apiKey;
        } else {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    headers.Authorization = `Bearer ${accessToken}`;
                }
            };
        }
    }
    const { host, endpointPath } = getServiceHostAndEndpointPath(path);
    return axios({
        url: `${host}${endpointPath}`,
        crossDomain: typeof(host) === 'string',
        headers: { ...headers },
        ...requestConfig
    });
};

export const getServiceHostAndEndpointPath = (path) => {
    var host = window.hopSkipConfig.api.host;
    if (path.startsWith('/api/') === false && window.hopSkipConfig.api.proxies !== undefined) {
        const matches = path.match(/^\/(\w+)\//gi);
        if (matches) {
            const service = matches[0].split('/')[1];
            const proxy = window.hopSkipConfig.api.proxies[service];
            if (proxy) {
                host = proxy.host
                    ? proxy.host
                    : proxy.local
                        ? `http://localhost:${proxy.localPort}`
                        : `https://${service}${window.hopSkipConfig.api.hostnamesuffix}.myhopskip.com`
            }
            return {
                host,
                endpointPath: proxy
                    ? `${proxy.includePath ? path : path.replace(matches[0], '/')}${proxy && proxy.query ? proxy.query : ''}`
                    : path
            };
        }
    } else {
        return { host, endpointPath: path };
    }
}

export const getAccessToken = async () => {
    try {
        const authenticationResult = await msalInstance.acquireTokenSilent({
            scopes: protectedResources.apiHopSkip.scopes,
            account: msalInstance.getAllAccounts()[0],
            redirectUri: `${window.hopSkipConfig.clientRoot || window.hopSkipConfig.api.host}/auth.html`
        });
        if (authenticationResult) {
            return authenticationResult.accessToken || authenticationResult.idToken;
        }
    }
    catch (e) {
        if (e.errorCode === 'interaction_required') {
            msalInstance.logoutRedirect();
        } else {
            console.error(`apiProvider.getAccessToken: ${JSON.stringify(e)}`);
            msalInstance.loginRedirect();
        }
    }
    return null;
}