import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { ChatContext } from '../../../chatContext';
import { Link } from 'react-router-dom';
import { ROLE_AFFILIATE, ROLE_DESTINATIONMANAGER, ROLE_HOTELIER, ROLE_SUPPLIER, UserProfileContext } from '../../../userProfileContext';
import './nav-item.scss'

export const NotificationsNavItem = () => {
    const { userProfile } = useContext(UserProfileContext);
    const { unreadConversations, parseConversationDisplayName, parseConversationUniqueName } = useContext(ChatContext);
    const [conversations, setConversations] = useState([]);
    const [unreadConversationCount, setUnreadConversationCount] = useState(0);

    useEffect(() => {
        setConversations(unreadConversations.filter(uc => uc.unreadMessagesCount > 0));
        setUnreadConversationCount(unreadConversations.filter(uc => uc.unreadMessagesCount > 0).length);
    }, [unreadConversations]);

    const unreadConversationLink = (unreadConversation, index) => {
        const { rfpId, venueId } = parseConversationUniqueName(unreadConversation.uniqueName);
        const { rfpName, venueName } = parseConversationDisplayName(unreadConversation.friendlyName);
        
        let to = `/event/${rfpId}/messaging/${venueId}`;
        let text = `${venueName} (${rfpName})`;

        switch (userProfile.role) {
            case ROLE_HOTELIER:
                to = `/proposal/${rfpId}/${venueId}/messaging`;
                text = rfpName;
                break;
            case ROLE_AFFILIATE:
            case ROLE_SUPPLIER:
            case ROLE_DESTINATIONMANAGER:
                to = `/supplier/request/${rfpId}/messaging`;
                text = rfpName;
                break;
            default:
                break;
        }

        return (
            <div key={index} style={{ marginBottom: '.5rem' }}>
                <Link to={to}>{text}</Link>
            </div>
        );
    }

    return (
        <div className="nav-item notifications">
            <OverlayTrigger
                placement="right"
                trigger={"click"}
                rootClose={true}
                rootCloseEvent="click"
                overlay={(
                    <Popover id='notifications-nav-item-popover' className="nav-item-popover">
                        <Popover.Title>
                            {`${unreadConversationCount} unread message${unreadConversationCount === 1 ? '' : 's'}`}
                        </Popover.Title>
                        <Popover.Content>
                            <div>
                                {conversations.map(unreadConversationLink)}
                            </div>
                        </Popover.Content>
                    </Popover>
                )}
            >
                <div>
                    <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={faBell}
                    />
                    {unreadConversationCount > 0 && (
                        <div className="unread-indicator">
                            {unreadConversationCount}
                        </div>
                    )}
                </div>
            </OverlayTrigger>
        </div>
    );
}