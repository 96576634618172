import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FeatureContext } from '../../featureProvider';

export const UpgradePromptModal = () => {
    const history = useHistory();
    const { promptUserToUpgrade, showUpgradePrompt } = useContext(FeatureContext);

    const onClose = () => {
        promptUserToUpgrade(false, null);
    }

    const onClickUpgrade = () => {
        promptUserToUpgrade(false, null);
        history.push('/payment/checkout');
    }

    return (
        <Modal show={showUpgradePrompt.show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faLockAlt} />
                    {' '}Upgrade{showUpgradePrompt.feature ? ` to ${showUpgradePrompt.feature.tierName}` : ''}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showUpgradePrompt.feature && (
                    <div>{`${showUpgradePrompt.feature.gerund} is ${['A','E','I','O','U'].includes(showUpgradePrompt.feature.tierName.substr(0,1)) ? 'an' : 'a'} ${showUpgradePrompt.feature.tierName} feature.`}</div>
                )}
                Click Upgrade to see pricing and plan options.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Not now
                </Button>
                <Button variant="success" onClick={onClickUpgrade}>
                    Upgrade
                </Button>
            </Modal.Footer>
        </Modal>
    );
}