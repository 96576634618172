import { faBars, faSearch, faTh } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { EventsList } from './events-list';
import { EventsPipeline } from './events-pipeline';
import './events-container.scss';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { EventCancelModal } from '../cancel-modal';
import { callApi, defaultErrorNotification } from '../../../apiProvider';
import Loader from '../../loader';
import { Share } from '../planner/share';
import { format, parseISO } from 'date-fns';
import { ROLE_ADMIN, UserProfileContext } from '../../../userProfileContext';
import { useDebouncedCallback } from 'use-debounce';

const viewNames = {
    pipeline: { key: 'pipeline', label: 'Pipeline' },
    list: { key: 'list', label: 'List' },
    cancelled: { key: 'cancelled', label: 'Cancelled' },
};

const getEvents = filterText => {
    return callApi(
        `/sourcing/api/events/plans${!!filterText ? `?$filter=${filterText}` : ''}`,
        {
            method: 'GET'
        },
        true
    );
}

export const EventsContainer = ({ onCopyEvent }) => {
    const { userProfile } = useContext(UserProfileContext);
    const history = useHistory();
    const [events, setEvents] = useState([]);
    const [viewName, setViewName] = useState(viewNames.pipeline.key);
    const [filterText, setFilterText] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [shareContext, setShareContext] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [deleteContext, setDeleteContext] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelContext, setCancelContext] = useState({});

    useEffect(() => {
        if (userProfile.role !== ROLE_ADMIN) {
            setShowLoader(true);
            getEvents().then(r => setEvents(r.data)).finally(() => setShowLoader(false));
        }
    }, [userProfile.role]);

    const reloadEvents = () => {
        setShowLoader(true);
        getEvents(filterText).then(r => setEvents(r.data)).finally(() => setShowLoader(false));
    };

    const onShareEvent = (event) => {
        setShareContext(event);
        setShowShareModal(true);
    }

    const onSearchAdmin = useDebouncedCallback((value) => {
        setShowLoader(true);
        getEvents(filterText).then(r => setEvents(r.data)).finally(() => setShowLoader(false));
    }, 1000);

    const filterEvents = event => {
        if (userProfile.role === ROLE_ADMIN) {
            return true;
        }
        if (viewName === viewNames.cancelled.key && event.status !== 'Cancelled') {
            return false;
        }
        
        if (!filterText) {
            return true;
        }

        return event.name?.toLowerCase()?.includes(filterText.toLowerCase())
        || event.rfpCode?.toLowerCase()?.includes(filterText.toLowerCase())
        || event.organizationName?.toLowerCase()?.includes(filterText.toLowerCase())
        || `${event.planners[0].firstName} ${event.planners[0].lastName} ${event.planners[0].id}`.toLowerCase().includes(filterText.toLowerCase());
    }

    const onChangeView = viewName => {
        setViewName(viewName);
        window.analytics.track(`Planner changed My RFPs view to ${viewName} view`);
    }

    const onDeleteEvent = (event) => {
        setDeleteContext(event);
        setShowConfirmModal(true);
    }

    const onConfirmDelete = () => {
        setShowLoader(true);
        callApi(`/sourcing/api/events/plans/${deleteContext.id}`, { method: 'DELETE' }, true)
            .catch(error => defaultErrorNotification(error))
            .then(() => {
                reloadEvents();
            }).finally(
                setShowConfirmModal(false)
            );
    }

    const onCancelEvent = (event) => {
        setCancelContext({...event});
        setShowCancelModal(true);
    }

    const onCancelCancel = () => {
        setCancelContext({});
        setShowCancelModal(false);
    }
    
    const onConfirmCancel = () => {
        setShowLoader(true);
        setShowCancelModal(false);
        reloadEvents();
    }

    const onMarkContractSigned = (event) => {
        history.push(`/event/${event.id}/agreement?marksigned=true`);
    }

    return (
        <div id="events-container">
            {showLoader && <Loader />}
            {showShareModal && (
                <Share
                    event={shareContext}
                    show={showShareModal}
                    onCancel={() => setShowShareModal(false)}
                    onSubmit={(planners) => {
                        setEvents(s => {
                            return s.map(e => {
                                if (e.id === shareContext.id) {
                                    e.planners = planners;
                                }
                                return e;
                            });
                        });
                        setShowShareModal(false);
                    }}
                />
            )}
            <Row>
                <Col sm={4}>
                    <div className="title">
                        <FontAwesomeIcon icon={faFile} />
                        RFPs
                    </div>
                </Col>
                <Col sm={4} style={{ textAlign: 'center' }}>
                    <div>
                        <span className="view-by-label">View by{' '}</span>
                        <Button
                            style={{ marginRight: '.75rem', marginLeft: '1rem' }}
                            variant={viewName === 'list' ? 'secondary' : 'primary'}
                            onClick={() => onChangeView(viewNames.pipeline.key)}
                            title="Click to See Pipeline View"
                        >
                            <FontAwesomeIcon icon={faTh} />
                        </Button>
                        <Button
                            variant={viewName === 'pipeline' ? 'secondary' : 'primary'}
                            onClick={() => onChangeView(viewNames.list.key)}
                            title="Click to See List View"
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </Button>
                    </div>
                </Col>
                <Col sm={4} style={{ textAlign: 'end' }}>
                    <Button
                        variant="outline-primary"
                        onClick={() => onChangeView(viewNames.cancelled.key)}
                    >
                        Cancelled RFPs
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginTop: '.5rem' }}>
                <Col>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            placeholder="Search RFPs"
                            value={filterText}
                            onChange={e => {
                                setFilterText(e.target.value);
                                if (userProfile.role === ROLE_ADMIN) {
                                    onSearchAdmin(e.target.value);
                                }
                            }}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col style={{ paddingLeft: '0' }}>
                    {(viewName === 'pipeline' && (
                        <EventsPipeline
                            events={events.filter(filterEvents)}
                            onShareEvent={onShareEvent}
                            onMarkContractSigned={onMarkContractSigned}
                            onCopyEvent={onCopyEvent}
                            onCancelEvent={onCancelEvent}
                            onDeleteEvent={onDeleteEvent}
                        />
                    )) || (
                        <EventsList
                            events={events.filter(filterEvents)}
                            onShareEvent={onShareEvent}
                            onMarkContractSigned={onMarkContractSigned}
                            onCopyEvent={onCopyEvent}
                            onCancelEvent={onCancelEvent}
                            onDeleteEvent={onDeleteEvent}
                        />
                    )}
                </Col>
            </Row>
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete RFP</Modal.Title>
                </Modal.Header>
                {deleteContext.created && (
                    <Modal.Body>
                        Are you sure you want to delete the {deleteContext.name ? `${deleteContext.name} RFP` : `new RFP created on ${format(parseISO(deleteContext.created), 'eee M/d @ h:mmaaa')}`}?
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowConfirmModal(false)}>
                        No, don't delete
                    </Button>
                    <Button variant="success" onClick={onConfirmDelete}>
                        Yes, delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <EventCancelModal
                show={showCancelModal}
                event={cancelContext}
                onClose={onCancelCancel}
                onConfirm={onConfirmCancel}
            />
        </div>
    );
}