import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import ChatView from '../../views/chat';

const Chat = () => {
	useEffect(() => {
		document.title = 'HopSkip - Chat';
	}, []);
	return (
		<div className="app">
			<Route path="/chat/:eventPlanId/:venueId?" component={ChatView} />
		</div>
	);
}

export default Chat;
