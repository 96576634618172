import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, ListGroup } from 'react-bootstrap';
import { faCircleEnvelope } from '@fortawesome/pro-light-svg-icons';
import { ChatContext } from '../../chatContext';
import { Link } from 'react-router-dom';
import { ROLE_AFFILIATE, ROLE_DESTINATIONMANAGER, ROLE_HOTELIER, ROLE_SUPPLIER, UserProfileContext } from '../../userProfileContext';
import { ReportCard, reportCardHeights } from '../reporting/report-card';
import { Metric } from '../reporting/metric';

export const MessagingDashboardTile = ({ viewDetails, onChangeViewDetails }) => {
    const { userProfile } = useContext(UserProfileContext);
    const { unreadConversations, parseConversationDisplayName, parseConversationUniqueName } = useContext(ChatContext);
    const [conversations, setConversations] = useState([]);
    const [unreadConversationCount, setUnreadConversationCount] = useState(0);

    useEffect(() => {
        setConversations(unreadConversations.filter(uc => uc.unreadMessagesCount > 0));
        setUnreadConversationCount(unreadConversations.filter(uc => uc.unreadMessagesCount > 0).length);
    }, [unreadConversations]);

    const unreadConversationLink = (unreadConversation, index) => {
        const { rfpId, venueId } = parseConversationUniqueName(unreadConversation.uniqueName);
        const { rfpName, venueName } = parseConversationDisplayName(unreadConversation.friendlyName);
        
        let to = `/event/${rfpId}/messaging/${venueId}`;
        let text = `${venueName} (${rfpName})`;

        switch (userProfile.role) {
            case ROLE_HOTELIER:
                to = `/proposal/${rfpId}/${venueId}/messaging`;
                text = rfpName;
                break;
            case ROLE_AFFILIATE:
            case ROLE_SUPPLIER:
            case ROLE_DESTINATIONMANAGER:
                to = `/supplier/request/${rfpId}/messaging`;
                text = rfpName;
                break;
            default:
                break;
        }

        return (
            <ListGroup.Item key={index} style={{ padding: '0.5rem 1rem' }}>
                <Link
                    className="overflow-ellipsis w15r"
                    to={to}
                >
                    {text}
                </Link>
            </ListGroup.Item>
        );
    }

    return (
        <ReportCard
            title="Unread Messages"
            height={reportCardHeights.fit}
            tools={(
                <FontAwesomeIcon
                    className='metric-icon warning'
                    icon={faCircleEnvelope}
                />
            )}
        >
            <Metric
                value={unreadConversationCount}
                valueHint='Messages'
                hintSize='large'
            />
            <div
                className="abs-top-right"
            >
                <Form.Group
                    className="right"
                    style={{ marginTop: '1rem' }}
                >
                    <Form.Label className="text-muted" style={{ marginBottom: 0 }}>View details</Form.Label>
                    <Form.Switch
                        id="unread-messages-switch"
                        checked={viewDetails}
                        onChange={e => onChangeViewDetails(e.target.checked)}
                        disabled={unreadConversationCount === 0}
                    />
                </Form.Group>
            </div>
            {viewDetails && (
                <ListGroup
                    style={{ marginLeft: '-0.5rem' }}
                >
                    {conversations.map(unreadConversationLink)}
                </ListGroup>
            )}
        </ReportCard>
    );
}