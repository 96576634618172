export const ProposalRequestStatusMap = {
    Pending: { key: 'Pending', label: 'Request Not Sent', hotelLabel: 'Not Sent', supplierLabel: 'RFP has not been sent', sort: 20, color: '#638FA9', processSort: 0, },
    New: { key: 'New', label: 'Awaiting Reply', hotelLabel: 'New', hotelTimelineLabel: 'Received', supplierLabel: 'Hotel has not opened RFP', sort: 40, color: '#FAC536', processSort: 10, },
    Received: { key: 'Received', label: 'In Progress', hotelLabel: 'Reviewing', supplierLabel: 'Hotel is reviewing RFP', sort: 35, color: '#DCF2EF', processSort: 20, },
    Active: { key: 'Active', label: 'Proposal Received', hotelLabel: 'Proposal Submitted', supplierLabel: 'Hotel Submitted Proposal', sort: 30, color: '#95D9D0', processSort: 40, },
    Reviewed: { key: 'Reviewed', label: 'Proposal Reviewed', hotelLabel: 'Planner Reviewed', supplierLabel: 'Planner Reviewed Proposal', sort: 32, color: '#95D9D0', processSort: 50, },
    Declined: { key: 'Declined', label: 'Hotel Declined', hotelLabel: 'Turned Down', supplierLabel: 'Hotel Turned Down', sort: 50, color: '#F86866', processSort: 30, },
    ClosedLost: { key: 'ClosedLost', label: 'Proposal Declined', hotelLabel: 'Planner Declined', supplierLabel: 'Planner Declined', sort: 60, color: '#B82B45', processSort: 70, },
    ClosedWon: { key: 'ClosedWon', label: 'Contracting in Progress', hotelLabel: 'Property Selected', supplierLabel: 'Planner Selected Hotel', sort: 10, color: '#027587', processSort: 60, },
    ContractSigned: { key: 'ContractSigned', label: 'Contract Signed', hotelLabel: 'Contract Signed', sort: 8, color: '#4FC0B0', processSort: 80, },
    Cancelled : { key: 'Cancelled', label: 'Cancelled', hotelLabel: 'Planner Cancelled', sort: 80, color: '#F86866', processSort: 90, },
    Removed : { key: 'Removed', label: 'Removed', hotelLabel: 'Removed From RFP', supplierLabel: 'Planner Removed', sort: 90, color: '#F86866', processSort: 100, },
};

export const EventPlanStatusMap = {
    New: { key: 'New', label: 'Creating New', tracker: true, pipeline: true, adminStatusChange: false, processSort: 0, },
    Submitted: { key: 'Submitted', label: 'Awaiting Reply', tracker: true, pipeline: true, adminStatusChange: false, processSort: 10, },
    Bidding: { key: 'Bidding', label: 'Receiving Proposals', tracker: true, pipeline: true, adminStatusChange: true, processSort: 20, },
    Contracting: { key: 'Contracting', label: 'Contracting', tracker: true, pipeline: true, adminStatusChange: false, processSort: 30, },
    Contracted: { key: 'Contracted', label: 'Contract Signed', tracker: true, pipeline: true, adminStatusChange: true, processSort: 40, },
    CheckedOut: { key: 'CheckedOut', label: 'Group Checked Out', tracker: false, adminStatusChange: true, processSort: 50, },
    CommissionPaid: { key: 'CommissionPaid', label: 'Commission Paid', tracker: false, adminStatusChange: true, processSort: 60, },
    Cancelled: { key: 'Cancelled', label: 'Cancelled', tracker: false, adminStatusChange: true, processSort: 80, },
    Abandoned: { key: 'Abandoned', label: 'On Hold', tracker: false, adminStatusChange: true, processSort: 70, },
};

export const ProposalProposedStatuses = [ 'Active', 'Reviewed', 'ClosedLost', 'ClosedWon', 'ContractSigned' ];
export const ProposalWonStatuses = [ 'ClosedWon', 'ContractSigned' ];
export const EventPlanContractedStatuses = [ 'Contracted', 'CheckedOut', 'CommissionPaid', 'Cancelled' ];