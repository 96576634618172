import React from 'react';

export const ROLE_ADMIN = 'Admin';
export const ROLE_ADMIN_HOTEL = 'AdminHotel';
export const ROLE_AFFILIATE = 'Affiliate';
export const ROLE_BOOKINGBUDDY = 'BookingBuddy';
export const ROLE_DESTINATIONMANAGER = 'DestinationManager';
export const ROLE_HOTELIER = 'Hotelier';
export const ROLE_PLANNER = 'Planner';
export const ROLE_SALESOPS = 'SalesOps';
export const ROLE_SUPPLIER = 'Supplier';

export const isUserProfileInitialized = userProfile => {
    if (!userProfile.role) {
        return false;
    }

    if (userProfile.role !== ROLE_PLANNER) {
        return true;
    }

    return userProfile.accountType;
}

export const UserProfileContext = React.createContext(null);