import React, { useContext } from 'react';
import { parseISO, format } from 'date-fns';
import { EventPlanStatusMap } from '../../../common/statusMaps';
import { Row, Col, Card, ListGroup, Dropdown, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt, faComment } from '@fortawesome/pro-light-svg-icons';
import { ChatContext } from '../../../chatContext';
import './events-pipeline.scss';
import { formatNumber } from '../../../common';
import { Link } from 'react-router-dom';
import { RfpCode } from '../../rfp-code';
import { ActionToggle } from '../../buttons/action-toggle';
import { faFileSlash } from '@fortawesome/pro-duotone-svg-icons';
import { WarningBanner } from '../../warning-banner';
import { UserProfileContext } from '../../../userProfileContext';

export const EventsPipeline = ({ events, onMarkContractSigned, onShareEvent, onCopyEvent, onCancelEvent, onDeleteEvent }) => {
    const { userProfile } = useContext(UserProfileContext);
    const { unreadConversations } = useContext(ChatContext);

    const formatActions = (event) => {
        return (
            <Dropdown>
                <Dropdown.Toggle as={ActionToggle} id="dropdown-actions">
                    <FontAwesomeIcon icon={faEllipsisH} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {event.status === 'Contracting' && (<Dropdown.Item onClick={() => onMarkContractSigned(event)}>Mark as Contract Signed</Dropdown.Item>)}
                    <Dropdown.Item onClick={() => onShareEvent(event)}>Share with planner(s)...</Dropdown.Item>
                    <Dropdown.Item onClick={() => onCopyEvent(event)}>Duplicate RFP...</Dropdown.Item>
                    {event.status === 'New' && (
                        <Dropdown.Item onClick={() => onDeleteEvent(event)}>Delete</Dropdown.Item>
                    )}
                    {['New','Cancelled'].includes(event.status) === false && (
                        <Dropdown.Item onClick={() => onCancelEvent(event)}>Cancel</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <Row id="pipeline-container">
            {userProfile.isAdmin && events.length === 0 && (
                <div className='noresults'>
                    <WarningBanner
                        variant={'danger'}
                        width='30rem'
                        heading='No RFPs found'
                        message={'Admins must use the Search RFPs box to find RFPs.'}
                        ctaPrompt={userProfile.isAdmin ? null : 'Create RFP'}
                    />
                </div>
            )}
            {Object.keys(EventPlanStatusMap).filter(k => EventPlanStatusMap[k].pipeline).map((statusKey, statusIndex) => (
                <Col key={`ev-ep-sk-${statusIndex}`}>
                    <Card>
                        <Card.Header
                            data-touranchorelement={statusKey === 'Bidding' ? 'rfpPipeline' : null}
                            data-touranchorposition="center"
                        >
                            <Card.Title>
                                {EventPlanStatusMap[statusKey].label}
                                <br/>
                                ({events.filter(e => e.status === statusKey).length})
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {events.filter(e => e.status === statusKey).sort((c, n) =>
                                    c.startDate ? (c.startDate > n.startDate ? 1 : -1) : (c.created > n.created ? 1 : -1)
                                ).map((event, eventIndex) => {
                                    const unreadConversationCount = unreadConversations.filter(uc => uc.uniqueName.startsWith(`${event.id}|`) && uc.unreadMessagesCount > 0).reduce((a,c) => {
                                        return a + 1;
                                    }, 0);
                                    return (
                                        <ListGroup.Item
                                            className="pipeline-card"
                                            key={`ev-ep-sk-${statusIndex}-ev-${eventIndex}`}
                                        >
                                            <div className="button-section">
                                                {formatActions(event)}
                                            </div>
                                            <div className="title-section">
                                                <Link
                                                    to={`/event/${event.id}`}
                                                >
                                                    <b className={event.name && event.name.length > 50 ? 'small' : null}>{event.name || `New RFP created on ${format(parseISO(event.created), 'eee M/d @ h:mmaaa')}`}</b>
                                                </Link>
                                            </div>
                                            <div className='code-section'>
                                                <RfpCode rfpCode={event.rfpCode} />
                                            </div>
                                            <div className="details-section">
                                                {event.roomBlocksRequired && (
                                                    <div>
                                                        Room Block{event.roomBlockRequests.length === 1 ? '' : 's'}:
                                                        <span>{` ${formatNumber(event.peakRooms)} rooms on peak`}</span>
                                                    </div>
                                                )}
                                                {event.meetingSpaceRequired && (
                                                    <div>
                                                        Event Space:
                                                        <span>{` ${formatNumber(event.peakMeetingRoomsRequired)} room${event.peakMeetingRoomsRequired === 1 ? '' : 's'} on peak`}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="indicator-section">
                                                {!!event.proposalsPaused ? (
                                                    <div className="indicator negative">
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`ep-${event.id}-tt-p`}>
                                                                    {`New proposals stopped by ${event.proposalsPausedBy} on ${format(parseISO(event.proposalsPausedAt), 'M/d/yy')}`}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Link
                                                                to={`/event/${event.id}/tracker`}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faFileSlash}
                                                                />
                                                            </Link>
                                                        </OverlayTrigger>
                                                    </div>
                                                ) : null}
                                                <div className="indicator">
                                                    <OverlayTrigger
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id={`ep-${event.id}-tt-d`}>
                                                                {(event.startDate && event.endDate && (
                                                                    <>
                                                                        <div>Dates Requested:</div>
                                                                        <div>{`${format(parseISO(event.startDate), 'M/d/yy')} - ${format(parseISO(event.endDate), 'M/d/yy')}`}</div>
                                                                        {event.alternateDates.filter(ad => ad.startDate).map((ad, i) => (
                                                                            <div key={i}>{`${format(parseISO(ad.startDate), 'M/d/yy')} - ${format(parseISO(ad.endDate), 'M/d/yy')}`}</div>
                                                                        ))}
                                                                    </>
                                                                )) || (
                                                                    <div>No Dates Specified</div> 
                                                                )}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Link
                                                            to={`/event/${event.id}/general`}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faCalendarAlt}
                                                            />
                                                        </Link>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="indicator">
                                                    <OverlayTrigger
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id={`ep-${event.id}-tt-m`}>
                                                                {unreadConversationCount === 0
                                                                    ? `No new messages`
                                                                    : `New messages from ${unreadConversationCount} hotel${unreadConversationCount === 1 ? '' : 's'}`
                                                                }
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Link
                                                            to={`/event/${event.id}/messaging`}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faComment}
                                                            />
                                                        </Link>
                                                    </OverlayTrigger>
                                                    {unreadConversationCount > 0 && (
                                                        <Badge variant="danger" pill className="indicator-badge">
                                                            {unreadConversationCount}
                                                        </Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}