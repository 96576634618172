export const supplierContactRoles = {
    hot: { key: 'hot', label: 'Hotelier (On-Property)', portfolioType: 'property' },
    nso: { key: 'nso', label: 'National Sales (Chain)', portfolioType: 'chain' },
    gso: { key: 'gso', label: 'Global Sales (Chain)', portfolioType: 'chain' },
    soa: { key: 'soa', label: 'Sales Office (Affiliate)', portfolioType: 'portfolio' },
    dmo: { key: 'dmo', label: 'DMO/CVB', portfolioType: 'market' },
    dmc: { key: 'dmc', label: 'DMC', portfolioType: 'market' },
};

export const supplierContactSources = {
    search: { key: 'search', label: 'added from search', title: 'Planner Search' },
    comparable: { key: 'comparable', label: 'added from comparable', title: 'HopSkip Comparable' },
    directory: { key: 'directory', label: 'added from directory', title: 'Cc by Planner' },
    rfp: { key: 'rfp', label: 'added new in rfp', title: 'Cc by Planner' },
    autocopy: { key: 'autocopy', label: 'autocopy', title: 'Paid Autocopy' },
    addpaid: { key: 'addpaid', label: 'addpaid', title: 'Added by Planner', plannerDescription: 'added by opting in during Send RFP' },
}

export const matchCriteria = {
    chainId: { key: 'chainId', label: 'Chain' },
    propertySellerId: { key: 'propertySellerId', label: 'Sales Office' },
};

export const geolocationExcludeVenueIds = 'geolocationExcludeVenueIds';
export const geolocationIncludeVenueIds = 'geolocationIncludeVenueIds';

export const invitationMessages = [
    {
        requestSuggestions: false,
        role: supplierContactRoles.hot.key,
        message: "Hi {{supplierContact.firstName}},\n\nI'm sending you this RFP using the HopSkip platform. Can you please respond to this request with a proposal using the HopSkip platform?\n\nThanks!",
    },
    {
        requestSuggestions: false,
        role: '*',
        message: "Hi {{supplierContact.firstName}},\n\nI'm sending you this RFP using the HopSkip platform. Can you please make sure that the hotels I've selected will receive my RFP and submit proposals using the HopSkip platform?\n\nThanks!",
    },
    {
        requestSuggestions: true,
        role: '*',
        message: "Hi {{supplierContact.firstName}},\n\nI'm sending you this RFP using the HopSkip platform. Can you please review my RFP, suggest hotels from your {{supplierContact.portfolioType}} you think would be best, and send them my request using the HopSkip platform?\n\nThanks!",
    },
];

export const formatInvitationMessage = (context) => {
    // context = { supplierContact, currentUser };
    const message = invitationMessages.find(im => (im.requestSuggestions === (context.supplierContact.requestSuggestions || false)) && (context.supplierContact.role === im.role || '*' === im.role));
    if (message && message.message) {
        const regex = /{{(\w+).(\w+)}}/g
        return message.message.replace(regex, (m, g1, g2) => {
            return context[g1][g2];
        });
    }
    return '';
}

export const doesVenueMatchCriteriaMatch = (venueMatchCriteria, matchObject) => {
    const keys = Object.keys(venueMatchCriteria);
    return keys.some(k => {
        switch (k) {
            case 'propertySeller':
                return venueMatchCriteria.propertySeller.some(c => matchObject.propertySellers.some(ps => ps.id === c));
            default:
                return venueMatchCriteria[k].includes(matchObject[k]);
        }
    });
}

export const formatVenueMatchCriteria = (venueMatchCriteria, chains, propertySellers) => {
    const matchKeys = Object.keys(venueMatchCriteria).filter(k => [geolocationExcludeVenueIds, geolocationIncludeVenueIds].includes(k) === false);
    return matchKeys.length === 0 || matchKeys.every(k => venueMatchCriteria[k].length === 0)
        ? 'None'
        : matchKeys.reduce((a,k) => {
            venueMatchCriteria[k].forEach(c => {
                a.push(
                    k === 'chainId'
                        ? chains.find(chain => chain.id === c)?.name
                        : k === 'propertySeller'
                            ? propertySellers.find(ps => ps.id === c)?.name
                            : 'Geography'
                );
            });
            return a;
        }, []).join(', ');
}

export const findSupplierContactOrTeammate = (supplierContacts, createdBy, createdByOrganizationId) => {
    if (!createdBy) return null;
    if (createdBy.endsWith('@myhopskip.com')) return { id: createdBy, firstName: 'HopSkip', lastName: '', isHopSkip: true };
    const supplierContact = supplierContacts.find(sc => sc.id === createdBy);
    if (supplierContact) {
        return supplierContact;
    }
    const teammate = supplierContacts.find(sc => sc.organizationId === createdByOrganizationId);
    if (teammate) {
        return teammate;
    }
    return null;
}

export const getSupplierLeadSource = (supplierContact) => {
    const supplierContactSource = supplierContactSources[supplierContact?.source ?? ''];
    if (supplierContactSource) {
        return {
            isAutoCopy: supplierContact?.source === supplierContactSources.autocopy.key,
            isAddPaid: supplierContact?.source === supplierContactSources.addpaid.key,
            ...supplierContactSource,
        };
    }
    return {};
}