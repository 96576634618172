import React, { useContext, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { callApi } from './apiProvider';
import { isUserProfileInitialized, ROLE_ADMIN, ROLE_BOOKINGBUDDY, UserProfileContext } from './userProfileContext';
import { UserProfileStatus } from './userProfileProvider';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from './TelemetryService';
import { ChatProvider } from './chatProvider';
import { getUnixTime, parseISO } from 'date-fns';
import { FeatureProvider } from './featureProvider';
import { NavProvider } from './navProvider';
import { AppRouter } from './AppRouter';
import { HUB_USERPROFILE, RealtimeContext } from './realtimeProvider';
import { CurrencyProvider } from './currencyProvider';
import { SelectRoleView } from './views/landing/select-role';
import { Spinner } from 'react-bootstrap';
import { ProfileDisabled } from './components/my-profile/disabled';

const App = () => {
  const { userProfile, setUserProfile, setUserProfileStatus } = useContext(UserProfileContext);
  const { initializeHub } = useContext(RealtimeContext);
  const { accounts } = useMsal();

  useEffect(() => {
    console.log(`App accounts ${accounts.length}`);
    if (accounts.length > 0) {
      setUserProfileStatus(UserProfileStatus.loading);
      callApi('/api/signupsignin', { method: 'POST' }, true).then((response) => {
          const { status } = response;
          if (status === 201) {
              console.log('created');
              window.location.reload();
          } else {
              console.log('posted token');
              callApi(
                `/api/userprofiles/me`,
                { method: 'GET' },
                true
              ).then(r => {
                const isAdmin = [ROLE_ADMIN, ROLE_BOOKINGBUDDY].includes(r.data.role);
                setUserProfile({
                  ...r.data,
                  homeAccountId: accounts[0].homeAccountId,
                  isAdmin,
                  licenseTier: r.data.licenseInfo?.tier,
                  licenseType: r.data.licenseInfo?.type,
                  commissionVisible:
                    r.data.commissionWaived
                      ? false
                      : isAdmin || (r.data.isPaidSubscriber && (r.data.commissionEligible || r.data.rebateEligible)),
                });
                initializeHub(HUB_USERPROFILE, r.data.id);
                setUserProfileStatus(UserProfileStatus.loaded);
                appInsights.context.user.id = r.data.id;
                //posthog
                window.posthog.identify(r.data.id, {
                  name: `${r.data.firstName} ${r.data.lastName}`,
                  email: r.data.email,
                  role: r.data.role,
                  organizationId: r.data.organizationId,
                  organizationName: r.data.organizationName,
                  user_license: r.data.licenseInfo?.tier,
                  user_license_type: r.data.licenseInfo?.type,
                  user_license_expiration_at: r.data.licenseInfo?.expiryDate ? getUnixTime(parseISO(r.data.licenseInfo.expiryDate)) : null,
                });
                //segment
                window.analytics.identify(r.data.id, {
                  name: `${r.data.firstName} ${r.data.lastName}`,
                  email: r.data.email,
                  role: r.data.role
                });
                //begin intercom
                window.Intercom("boot", {
                  app_id: "tbzm7jeb",
                  name: `${r.data.firstName} ${r.data.lastName}`,
                  email: r.data.email,
                  created_at: getUnixTime(parseISO(r.data.firstLogin)),
                  user_hash: r.data.user_hash,
                  company: r.data.organizationId
                    ? {
                      id: r.data.organizationId,
                      name: r.data.organizationName
                    }
                    : {},
                  user_role: r.data.role,
                  user_license: r.data.licenseInfo?.tier,
                  user_license_type: r.data.licenseInfo?.type,
                  user_license_expiration_at: r.data.licenseInfo?.expiryDate ? getUnixTime(parseISO(r.data.licenseInfo.expiryDate)) : null,
                  associated_hotels: r.data.associatedVenues.length > 0
                    ? r.data.associatedVenues.map(v => v.name).join(',')
                    : null,
                  default_property_id: r.data.associatedVenues.length > 0 ? r.data.associatedVenues[0].id : null,
                  custom_launcher_selector: '#custom-messenger-launcher'
                });
                // end intercom
                // begin birdie
                window.birdieSettings = {
                    contact_name: `${r.data.firstName} ${r.data.lastName}`,
                    contact_email: r.data.email,
                    contact_id: r.data.email,
                };
                // end birdie
              });    
          }
        });
    } else {
      setUserProfileStatus(UserProfileStatus.anonymous);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ accounts.length ]);

  if (userProfile.isDisabled) {
    return (
      <ProfileDisabled />
    );
  }

  if (isUserProfileInitialized(userProfile)) {
    return (
      <FeatureProvider>
        <CurrencyProvider>
          <ChatProvider userProfileId={userProfile.id}>
            <NavProvider>
              <AppRouter />
            </NavProvider>
          </ChatProvider>
        </CurrencyProvider>
      </FeatureProvider>
    );
  }

  if (userProfile.id) {
    return (
      <SelectRoleView />
    );
  }

  return (
    <Spinner
      style={{
        marginTop: '20rem',
        marginLeft: 'calc(50% - 4rem)',
        width: '4rem',
        height: '4rem',
        fontSize: '1rem',
      }}
      variant='success'
      animation='border'
    />
  );
}

export default withAITracking(reactPlugin, App);