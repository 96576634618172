import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, Row, Col, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { DatepickerHtml5 } from '../datepicker/html5';
import { callApi } from '../../apiProvider';
import { useHistory } from 'react-router-dom';
import { parse, format, addDays, parseISO, formatISO, isValid } from 'date-fns';
import { UserProfileContext } from '../../userProfileContext';
import './event-plan-duplicate-modal.scss';
import { NotificationManager } from 'react-notifications';
import { TrialLimitModalContent, useTrialLimitStatus } from '../event/trial-limit-status';

const loadTemplates = () => {
    return callApi(
        `/sourcing/api/events/templates/me`,
        { method: 'GET' },
        true
    );
}

const getItemCounts = id => {
    return callApi(
        `/sourcing/api/events/plans/${id}/itemCounts`,
        { method: 'GET' },
        true
    );
}

const templateOptionsMap = {
    programInfo: { label: 'Program Info' },
    meetingSpaceRequests: { label: 'Event Space' },
    foodAndBeverageRequests: { label: 'Food and Beverage' },
    roomBlockRequests: { label: 'Room Blocks' },
    attachments: { label: 'Attachments' },
    proposalRequests: { label: 'Hotels' },
    contacts: { label: 'Industry Contacts', fromProfile: true },
    questions: { label: 'Questions', fromProfile: true },
    concessionRequests: { label: 'Concession Requests', fromProfile: true },
    contractClauses: { label: 'Contract Clauses', fromProfile: true }
}

const programInfoCounts = (template) => {
    return {
        complete: ['groupProfile','description','successCriteria','dealBreakers'].reduce((a,c) => a + (template[c] ? 1 : 0), 0) + (template.eventHistories.length > 0 ? 1 : 0),
        total: 5
    };
}

export const duplicateActions = {
    createRfpFromTemplate: 'createRfpFromTemplate',
    duplicateRfp: 'duplicateRfp'
}

export const copyOperations = {
    adminCopy: 'adminCopy',
    defaultCopy: 'defaultCopy'
}

export const copyItemTypes = {
    eventPlan: 'eventPlan',
    eventPlanTemplate: 'eventPlanTemplate'
}

const viewModes = {
    selectOperation: 'selectOperation',
    selectOptions: 'selectOptions'
}

export const EventPlanDuplicateModal = ({ show, duplicateAction, createItemType, template, onClose }) => {
    const history = useHistory();
    const { userProfile } = useContext(UserProfileContext);
    const { trialLimitStatus } = useTrialLimitStatus();
    const [viewMode, setViewMode] = useState(viewModes.selectOperation);
    const [copyContext, setCopyContext] = useState({ operation: '', from: template?.itemType, to: createItemType });
    const [templates, setTemplates] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [templateOptions, setTemplateOptions] = useState({
        programInfo: { copy: true, count: 0, disabled: false },
        meetingSpaceRequests: { copy: true, count: 0, disabled: false },
        foodAndBeverageRequests: { copy: true, count: 0, disabled: false },
        roomBlockRequests: { copy: true, count: 0, disabled: false },
        attachments: { copy: false, count: 0, disabled: false },
        contacts: { copy: false, count: 0, disabled: false },
        questions: { copy: false, count: 0, disabled: false },
        concessionRequests: { copy: false, count: 0, disabled: false },
        contractClauses: { copy: false, count: 0, disabled: false },
        proposalRequests: { copy: false, count: 0, disabled: false },
    });

    useEffect(() => {
        setCopyContext({
            operation: copyOperations.defaultCopy,
            from: template?.itemType ?? copyItemTypes.eventPlanTemplate,
            to: createItemType ?? ''
        });
        setViewMode(
            duplicateAction === duplicateActions.createRfpFromTemplate
                ? viewModes.selectOptions
                : viewModes.selectOperation
        );
    }, [duplicateAction, template?.itemType, createItemType]);

    useEffect(() => {
        if (template === null)
        {
            loadTemplates().then(r => setTemplates(r.data));
            setSelectedTemplate({});
        } else {
            setSelectedTemplate(s => {
                return { ...template };
            });
            getItemCounts(template.id).then(r => {
                const programInfo = programInfoCounts(template);
                const supplierContactsCount = template.supplierContacts.filter(sc => !sc.isDelegate).length;
                setTemplateOptions({
                    programInfo: { copy: programInfo.complete > 0, count: programInfo.complete, disabled: programInfo.total === 0 },
                    meetingSpaceRequests: { copy: template.meetingSpaceRequired, count: template.meetingSpaceRequests.length, disabled: !template.meetingSpaceRequired },
                    foodAndBeverageRequests: { copy: (template.foodAndBeverageRequests.length > 0), count: template.foodAndBeverageRequests.length, disabled: (template.foodAndBeverageRequests.length === 0) },
                    roomBlockRequests: { copy: template.roomBlocksRequired, count: template.roomBlockRequests.length, disabled: !template.roomBlocksRequired },
                    attachments: { copy: false, count: r.data['attachment'] || 0, disabled: (r.data['attachment'] || 0) === 0 },
                    contacts: { copy: false, count: supplierContactsCount, disabled: supplierContactsCount === 0 },
                    questions: { copy: false, count: r.data['question'] || 0, disabled: (r.data['question'] || 0) === 0 },
                    concessionRequests: { copy: false, count: r.data['concessionRequest'] || 0, disabled: (r.data['concessionRequest'] || 0) === 0 },
                    contractClauses: { copy: false, count: r.data['contractClause'] || 0, disabled: (r.data['contractClause'] || 0) === 0 },
                    proposalRequests: { copy: false, count: r.data['proposalRequest'] || 0, disabled: (r.data['proposalRequest'] || 0) === 0 },
                });
            });
        }
    }, [template, createItemType, show]);

    const selectTemplate = (id) => {
        if (id) {
            const template = templates.find(t => t.id === id);
            if (template !== null) {
                setSelectedTemplate(s => {
                    return { ...template };
                });
                getItemCounts(id).then(r => {
                    const programInfo = programInfoCounts(template);
                    const supplierContactsCount = template.supplierContacts.filter(sc => !sc.isDelegate).length;
                    setTemplateOptions({
                        programInfo: { copy: programInfo.complete > 0, count: programInfo.complete, disabled: programInfo.total === 0 },
                        meetingSpaceRequests: { copy: template.meetingSpaceRequired, count: template.meetingSpaceRequests.length, disabled: !template.meetingSpaceRequired },
                        foodAndBeverageRequests: { copy: (template.foodAndBeverageRequests.length > 0), count: template.foodAndBeverageRequests.length, disabled: (template.foodAndBeverageRequests.length === 0) },
                        roomBlockRequests: { copy: template.roomBlocksRequired, count: template.roomBlockRequests.length, disabled: !template.roomBlocksRequired },
                        attachments: { copy: false, count: r.data['attachment'] || 0, disabled: (r.data['attachment'] || 0) === 0 },
                        contacts: { copy: false, count: supplierContactsCount, disabled: supplierContactsCount === 0 },
                        questions: { copy: false, count: r.data['question'] || 0, disabled: (r.data['question'] || 0) === 0 },
                        concessionRequests: { copy: false, count: r.data['concessionRequest'] || 0, disabled: (r.data['concessionRequest'] || 0) === 0 },
                        contractClauses: { copy: false, count: r.data['contractClause'] || 0, disabled: (r.data['contractClause'] || 0) === 0 },
                        proposalRequests: { copy: false, count: r.data['proposalRequest'] || 0, disabled: (r.data['proposalRequest'] || 0) === 0 },
                    });
                });
            }
        }
    }

    const onChangeStartDate = (e) => {
        let v = parse(e.target.value, 'yyyy-MM-dd', new Date());
        if (isValid(v) === false) {
            setStartDate(null);
            setEndDate(null);
        } else {
            setStartDate(formatISO(v, { representation: 'date' }));
            setEndDate(formatISO(addDays(v, (selectedTemplate.totalDays - 1)|| 1), { representation: 'date' }));
        }
    }

    const onClickAdminCopy = () => {
        CreateCopy({
            operation: copyOperations.adminCopy,
            from: copyItemTypes.eventPlan,
            to: copyItemTypes.eventPlan
        });
    }

    const onClickCreateRfp = () => {
        setCopyContext(s => {
            return {
                ...s,
                operation: copyOperations.defaultCopy,
                to: copyItemTypes.eventPlan
            };
        });
        setViewMode(viewModes.selectOptions);
    }

    const onClickCreateTemplate = () => {
        setCopyContext(s => {
            return {
                ...s,
                operation: copyOperations.defaultCopy,
                from: copyItemTypes.eventPlan,
                to: copyItemTypes.eventPlanTemplate
            };
        });
        setViewMode(viewModes.selectOptions);
    }

    const onClickConfirm = () => {
        CreateCopy({
            operation: copyContext.operation || copyOperations.defaultCopy,
            from: copyContext.from || (createItemType === copyItemTypes.eventPlan ? copyItemTypes.eventPlanTemplate : copyItemTypes.eventPlan),
            to: copyContext.to || createItemType
        });
    }

    const CreateCopy = ({ operation, from, to }) => {
        const url = `/sourcing/api/events/plans/${selectedTemplate.id}/${operation}/${from}/${to}`;

        callApi(
            url,
            {
                method: 'POST',
                data: Object.keys(templateOptions).reduce((a, k) => {
                    a[k] = templateOptions[k].copy ? true : false;
                    return a;
                }, { startDate, endDate })
            },
            true
        ).then(r => {
            setTemplateOptions({
                programInfo: { copy: true, count: 0, disabled: false },
                meetingSpaceRequests: { copy: true, count: 0, disabled: false },
                foodAndBeverageRequests: { copy: true, count: 0, disabled: false },
                roomBlockRequests: { copy: true, count: 0, disabled: false },
                attachments: { copy: false, count: 0, disabled: false },
                contacts: { copy: false, count: 0, disabled: false },
                questions: { copy: false, count: 0, disabled: false },
                concessionRequests: { copy: false, count: 0, disabled: false },
                contractClauses: { copy: false, count: 0, disabled: false },
                proposalRequests: { copy: false, count: 0, disabled: false },
            });
            setStartDate(null);
            setEndDate(null);
            setSelectedTemplate({});
            setCopyContext({ operation: '', from: '', to: '' });
            onClose();
            history.push(`/event/${r.data.id}/general`);
        }).catch(err => {
            let message = "Error";
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 409:
                        message = err.response.data;
                        break;
                    case 401:
                    default:
                        message = "Authorization error.";
                        break;
                }
            }
            NotificationManager.error(message);
        });
    }

    const onCancel = () => {
        setTemplateOptions({
            meetingSpaceRequests: { copy: true, count: 0, disabled: false },
            foodAndBeverageRequests: { copy: true, count: 0, disabled: false },
            roomBlockRequests: { copy: true, count: 0, disabled: false },
            attachments: { copy: false, count: 0, disabled: false },
            contacts: { copy: false, count: 0, disabled: false },
            questions: { copy: false, count: 0, disabled: false },
            concessionRequests: { copy: false, count: 0, disabled: false },
            contractClauses: { copy: false, count: 0, disabled: false },
            proposalRequests: { copy: false, count: 0, disabled: false },
        });
        setStartDate(null);
        setEndDate(null);
        setSelectedTemplate({});
        setCopyContext({ operation: '', from: '', to: '' });
        onClose();
    }

    return (
        <Modal
            id='event-plan-duplicate-modal'
            show={show}
            onHide={onCancel}
        >
            <Modal.Header closeButton>
                {viewMode === viewModes.selectOperation && (
                    <Modal.Title>
                        Duplicate RFP
                        {template?.name ? (
                            <div style={{ fontSize: '1rem' }}>{template.name}</div>
                        ) : null}
                    </Modal.Title>
                )}
                {(viewMode === viewModes.selectOptions && duplicateAction === duplicateActions.createRfpFromTemplate && (
                    <Modal.Title>
                        Create an RFP
                        {template?.name ? (
                            <div style={{ fontSize: '1rem' }}>{template.name}</div>
                        ) : null}
                    </Modal.Title>
                )) || (viewMode === viewModes.selectOptions && duplicateAction === duplicateActions.duplicateRfp && (
                    <Modal.Title>
                        {`Copy RFP ${copyContext.to === copyItemTypes.eventPlanTemplate ? ' to Template' : ''}`}
                        {template?.name ? (
                            <div style={{ fontSize: '1rem' }}>{template.name}</div>
                        ) : null}
                    </Modal.Title>
                ))}
            </Modal.Header>
            {viewMode === viewModes.selectOperation && (
                <Modal.Body>
                    <ListGroup>
                        <ListGroupItem
                            onClick={onClickCreateRfp}
                        >
                            <b>Create a new RFP now based on this {copyContext.from === copyItemTypes.eventPlan ? "RFP" : "Template"}</b>
                            <div>You can make any necessary changes and your new RFP will be ready to send.</div>
                        </ListGroupItem>
                        {copyContext.from === copyItemTypes.eventPlan && (
                            <ListGroupItem
                                onClick={onClickCreateTemplate}
                            >
                                <b>Create a Template for creating similar RFPs later</b>
                                <div>Your new template will be stored in your Planner Toolbox and can be used to create new RFPs in the future.</div>
                            </ListGroupItem>
                        )}
                        {window.hopSkipConfig.api.environment !== 'Prod' && userProfile.isAdmin && (
                            <ListGroupItem
                                onClick={onClickAdminCopy}
                            >
                                <b>Create an exact copy including current status (Admin only)</b>
                            </ListGroupItem>
                        )}
                    </ListGroup>
                </Modal.Body>
            )}
            {viewMode === viewModes.selectOptions && (
                <>
                    {trialLimitStatus.loading ? (
                        <Modal.Body>
                            <Spinner animation="border" variant='primary' />
                        </Modal.Body>
                    ) : trialLimitStatus.isTrialLimitReached ? (
                        <TrialLimitModalContent trialLimitStatus={trialLimitStatus} />
                    ) : (
                        <Modal.Body>
                            {template === null && (
                                <Form.Group>
                                    <Form.Control
                                        as="select"
                                        value={selectedTemplate.id}
                                        onChange={e => selectTemplate(e.target.value)}
                                    >
                                        <option value={null}>Select a Template...</option>
                                        {templates.map((t, i) => (
                                            <option key={`ept-t-${i}`} value={t.id}>{t.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            )}
                            <Form.Text style={{ fontSize: 'large', marginBottom: '1rem' }}>Select items to include:</Form.Text>
                            {selectedTemplate.id && (
                                <Form>
                                    {createItemType === 'eventPlan' && (
                                        <Form.Group as={Row}>
                                            <Form.Label column>Event Start</Form.Label>
                                            <Col>
                                                <DatepickerHtml5
                                                    name="startDate"
                                                    type="date"
                                                    value={startDate ? format(parseISO(startDate), 'yyyy-MM-dd') : ''}
                                                    onChange={onChangeStartDate}
                                                    min={format(addDays(new Date(), 1), 'yyyy-MM-dd')}
                                                />
                                            </Col>
                                        </Form.Group>
                                    )}
                                    {Object.keys(templateOptions).filter(k => !templateOptionsMap[k].fromProfile).map((k, i) => (
                                        <Form.Group key={`ept-o-${i}`}>
                                            <label className="checkbox-container" style={{ fontSize: '1em' }}>
                                                {templateOptionsMap[k].label}{` (${templateOptions[k].count || 'None' })`}
                                                <input
                                                    type="checkbox"
                                                    checked={templateOptions[k].copy}
                                                    onChange={e => setTemplateOptions({ ...templateOptions, [k]: { ...templateOptions[k], copy: e.target.checked } })}
                                                    disabled={templateOptions[k].disabled}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Form.Group>
                                    ))}
                                    <hr />
                                    <div style={{ marginBottom: '0.5rem' }}>Leave the items below unchecked to use your Planner Toolbox settings:</div>
                                    {Object.keys(templateOptions).filter(k => templateOptionsMap[k].fromProfile).map((k, i) => (
                                        <Form.Group key={`ept-o-${i}`}>
                                            <label className="checkbox-container" style={{ fontSize: '1em' }}>
                                                {templateOptionsMap[k].label}{` (${templateOptions[k].count || 'None' })`}
                                                <input
                                                    type="checkbox"
                                                    checked={templateOptions[k].copy}
                                                    onChange={e => setTemplateOptions({ ...templateOptions, [k]: { ...templateOptions[k], copy: e.target.checked } })}
                                                    disabled={templateOptions[k].disabled}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Form.Group>    
                                    ))}
                                </Form>
                            )}
                        </Modal.Body>
                    )}
                </>
            )}
            {!trialLimitStatus.loading && !trialLimitStatus.isTrialLimitReached && (
                <Modal.Footer>
                    <Button variant="danger" onClick={onCancel}>
                        Cancel
                    </Button>
                    {viewMode === viewModes.selectOptions && (
                        <Button
                            variant="success"
                            onClick={onClickConfirm}
                            disabled={selectedTemplate.id === undefined || (createItemType === 'eventPlan' && !startDate)}
                        >
                            Create
                        </Button>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    );
}