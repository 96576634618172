import { ROLE_ADMIN, ROLE_ADMIN_HOTEL, ROLE_AFFILIATE, ROLE_DESTINATIONMANAGER, ROLE_HOTELIER, ROLE_PLANNER, ROLE_SALESOPS, ROLE_SUPPLIER } from "../userProfileContext";

export const previewExperiences = {
    DASHBOARDPREVIEW: {
        key: 'DASHBOARDPREVIEW',
        label: 'Dashboard Preview Option',
        roles: [ROLE_ADMIN,ROLE_ADMIN_HOTEL,ROLE_AFFILIATE,ROLE_DESTINATIONMANAGER,ROLE_HOTELIER,ROLE_PLANNER,ROLE_SALESOPS,ROLE_SUPPLIER],
    },
    DASHBOARD: {
        key: 'DASHBOARD',
        label: 'New Dashboard (circa 2023)',
        alert: {
            enabled: {
                intro: 'Welcome to the new dashboard preview.'
            },
            disabled: {
                intro: 'A new dashboard experience is available to preview.'
            }
        },
        roles: [ROLE_ADMIN,ROLE_ADMIN_HOTEL,ROLE_AFFILIATE,ROLE_DESTINATIONMANAGER,ROLE_HOTELIER,ROLE_PLANNER,ROLE_SALESOPS,ROLE_SUPPLIER],
    },
    CREATEHOTELFROMPLACEID: {
        key: 'CREATEHOTELFROMPLACEID',
        label: 'Create Hotel from Google Places ID in Search',
        roles: [ROLE_PLANNER,ROLE_ADMIN],
    },
    AIPROPOSALASSISTANT: {
        key: 'AIPROPOSALASSISTANT',
        label: 'AI Assistant - Proposal Details',
        roles: [ROLE_PLANNER,ROLE_ADMIN],
    },
    AIIMPORTTEMPLATESPDF: {
        key: 'AIIMPORTTEMPLATESPDF',
        label: 'Import RFP Templates from PDF',
        roles: [ROLE_PLANNER,ROLE_ADMIN],
    },
    AIIMPORTTEMPLATESEXCEL: {
        key: 'AIIMPORTTEMPLATESEXCEL',
        label: 'Import RFP Templates from Excel',
        roles: [ROLE_PLANNER,ROLE_ADMIN],
    },
    SOURCINGPROFILES: {
        key: 'SOURCINGPROFILES',
        label: 'Sourcing Profiles',
        roles: [ROLE_PLANNER,ROLE_ADMIN],
    },
    PRESENTATIONBUILDER: {
        key: 'PRESENTATIONBUILDER',
        label: 'Presentation Builder',
        roles: [ROLE_PLANNER,ROLE_ADMIN],
    },
    SEARCHFILTERHOTELTYPE: {
        key: 'SEARCHFILTERHOTELTYPE',
        label: 'Search Filter - Hotel Type',
        roles: [ROLE_PLANNER,ROLE_ADMIN],
    },
}