import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { msalConfig, loginRequest } from './authConfig';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { UserProfileProvider } from './userProfileProvider';
import { reactPlugin } from './TelemetryService';
import { RealtimeProvider } from './realtimeProvider';
import { isIE, isFirefox } from 'react-device-detect';
import './scss/index.scss';
import 'filepond/dist/filepond.min.css';

if (window.self !== window.top) {
    console.log('In iframe');
    window.top.location.replace(window.self.location.href);
} else {
    console.log('Not in iframe');
}

export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

msalInstance.handleRedirectPromise()
    .then((tokenResponse) => {
        console.log('Handle redirect response');
        console.log(tokenResponse);
    })
    .catch((error) => {
        console.error('Handle redirect error');
        console.error(error);
    });

if (window.location.search.indexOf('hsCtaTracking') > -1) {
    window.location = `${window.location.origin}${window.location.pathname}${window.location.hash}`;
} else if (window.location.hash.indexOf('/id_token=') > -1) {
    window.location = `${window.location.origin}${window.location.pathname}`;
} else if (isIE || isFirefox) {
    window.location = `${window.location.origin}${window.location.pathname}/browser-support.html`;
} else {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
        <AppInsightsErrorBoundary
            onError={() => (
                <div>
                    <h5>Something went wrong.</h5>
                    <a href={`${window.location.origin}`}>Go back and try again</a>
                </div>
            )}
            appInsights={reactPlugin}
        >
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                >
                    <UserProfileProvider>
                        <RealtimeProvider>
                            <App />
                        </RealtimeProvider>
                    </UserProfileProvider>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </AppInsightsErrorBoundary>
    );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
