import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { callApi } from '../../../apiProvider';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { UserProfileContext } from '../../../userProfileContext';
import { NotificationManager } from 'react-notifications';
import { getMembers } from '../../../services/organizations';
import { Checkbox } from '../../checkbox';

export const Share = ({ event, show, onCancel, onSubmit }) => {
    const { userProfile } = useContext(UserProfileContext);
    const [eventPlanOwner, setEventPlanOwner] = useState({});
    const [orgPlanners, setOrgPlanners] = useState([]);
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        getMembers(event.organizationId).then(r => {
            setOrgPlanners(
                r.data.filter(d => d.id !== userProfile.id).map(d => ({
                    ...d,
                    share: event.planners.some(p => p.id === d.id)
                }))
            );
        });
        setEventPlanOwner(event.planners.find(p => p.owner) || {});
    }, [userProfile.id, event.organizationId, event.planners]);

    const onClickSubmit = e => {
        e.stopPropagation();
        callApi(`/sourcing/api/events/plans/${event.id}/share`,
            {
                method: 'POST',
                data: {
                    emails: [
                        ...emails.map(e => { return { email: e }; }),
                        ...orgPlanners.filter(p => p.share === true && emails.includes(p.id) === false).map(p => { return { email: p.id }; })
                    ]
                },
            },
            true
        ).then(r => onSubmit(r.data))
        .catch(e => {if (e.response && e.response.data && e.response.data.Message)
            {
                NotificationManager.error(e.response.data.Message, 'Sharing error');
            }
        });
    }

    const getEmailLabel = (email, index, removeEmail) => {
        return (
            <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                </span>
            </div>
        );
    }

    return (
        <Modal show={show} onHide={onCancel} dialogClassName='modal-50w'>
            <Modal.Header>
                <Modal.Title>Share this {event?.itemType === 'eventPlan' ? 'RFP' : 'Template'} with one or more planners:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {orgPlanners.length > 0 && (
                    <div style={{ marginBottom: '1rem' }}>
                        <Form.Label>Select one or more team members to share with:</Form.Label>
                        <Row>
                            {orgPlanners.map((planner, pi) => (
                                <Col
                                    key={pi}
                                    sm={6}
                                >
                                    <Checkbox
                                        label={planner.firstName && planner.lastName ? `${planner.firstName} ${planner.lastName} (${planner.id})` : planner.id}
                                        checked={planner.share}
                                        onChange={e => setOrgPlanners(s => {
                                            return s.map(p => {
                                                if (p.id === planner.id) {
                                                    p.share = e.target.checked;
                                                }
                                                return p;
                                            });
                                        })}
                                        disabled={eventPlanOwner.id === planner.id}
                                        whatsThisContent={eventPlanOwner.id === planner.id ? "The owner cannot be removed" : null}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}
                <Form.Label>Or, enter email addresses to share with other planners:</Form.Label>
                <ReactMultiEmail
                    placeholder="enter emails here"
                    emails={emails}
                    onChange={_emails => setEmails(_emails)}
                    validateEmail={email => {
                        return isEmail(email); // return boolean
                    }}
                    getLabel={getEmailLabel}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-danger"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="outline-primary"
                    onClick={onClickSubmit}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}