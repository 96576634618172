import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './nav-item.scss'

export const NavItem = ({ icon, iconStyle, text, target, id, onClick, disabled }) => {
    return (
        <div className="nav-item">
            <OverlayTrigger
                placement="right"
                trigger={['hover','focus']}
                overlay={(
                    <Popover id={text.replace(' ', '-')} className="nav-item-popover">
                        <Popover.Content><span className="content">{text}</span></Popover.Content>
                    </Popover>
                )}
            >
                {(typeof(onClick) === 'function' && (
                    <Button
                        variant="link"
                        onClick={onClick}
                        disabled={disabled}
                    >
                        <FontAwesomeIcon id={id} icon={icon} style={iconStyle} />
                        <span>{text}</span>
                    </Button>
                )) || (target && target.startsWith('http') && (
                    <a href={target} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={icon} style={iconStyle} />
                        <span>{text}</span>
                    </a>
                )) || (
                    <Link to={target || '/'}>
                        <FontAwesomeIcon icon={icon} style={iconStyle} />
                        <span>{text}</span>
                    </Link>
                )}
            </OverlayTrigger>
        </div>
    );
}