import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Image, ListGroup, Modal, Row, Spinner } from 'react-bootstrap';

import { callApi } from '../../apiProvider';
import { accountTypeOptions } from '../../common/accountTypes';
import { Checkbox } from '../../components/checkbox';
import { LocationSelector } from '../../components/location-selector';
import { isUserProfileInitialized, ROLE_AFFILIATE, ROLE_DESTINATIONMANAGER, ROLE_HOTELIER, ROLE_PLANNER, ROLE_SUPPLIER, UserProfileContext } from '../../userProfileContext';

import './select-role.scss';

const viewModes = {
    role: 'role',
    accountType: 'accountType',
    address: 'address',
    jobsToBeDone: 'jobsToBeDone',
    wait: 'wait'
}

const jobsToBeDoneOptions = {
    [ROLE_PLANNER]: [
        'Save time during the RFP process',
        'Receive higher response rates when requesting proposals from hotels',
        'Increase the number of meetings/events I can source',
        'Compare proposals, download reports, and communicate cost savings',
        'Have all of my meeting sourcing history organized in one place',
        'Stay in control of the process (versus relying on third parties)',
    ]
}

export const SelectRoleView = () => {
    const { userProfile } = useContext(UserProfileContext);
    const [accountState, setAccountState] = useState({
        viewMode: viewModes.role,
        role: '',
        location: {},
        address: { address: '', city: '', state: '', zip: '', country: '' },
        accountType: '',
        jobsToBeDone: [],
        organizationId: '',
    });
    const [location, setLocation] = useState({});
    const [isAddressInvalid, setIsAddressInvalid] = useState(true);

    useEffect(() => {
        setLocation({
            name: accountState.address.address
        });
        setIsAddressInvalid(
            Object.keys(accountState.address).some(k => !accountState.address[k])
        );
    }, [accountState.address]);

    const selectRole = role => {
        setAccountState(s => {
            return {
                ...s,
                viewMode: viewModes.wait
            };
        });

        callApi(
            `/api/userprofiles/${userProfile.id}/selectrole/${role}`,
            { method: 'POST' },
            true
        ).then(r => {
            if (role !== ROLE_PLANNER) {
                window.setTimeout(() => {
                    if (!userProfile.accountType) {
                        window.location.reload();
                    }
                }, 3000);
            } else {
                setAccountState(s => {
                    return {
                        ...s,
                        role: role,
                        organizationId: r.data.organizationId,
                        viewMode: viewModes.accountType
                    };
                });
            }
        }).catch(e => {
            // `invalid user profile id '${userProfile.id}'`
            // 'user role cannot be changed'
            // 'role is required'
            // `invalid role value '${role}'`
        });
    }

    const selectAccountType = accountType => {
        setAccountState(s => {
            return {
                ...s,
                accountType: accountType,
                viewMode: viewModes.address
            };
        });
    }

    const onChangeLocation = location => {
        const { streetNumber, route, city, state, zip, country } = location.addressComponents;
        let v = {};
        if (route) {
            v.address = `${streetNumber ? `${streetNumber} `: ''}${route}`;
        }
        if (city) {
            v.city = city;
        }
        if (state) {
            v.state = state;
        }
        if (zip) {
            v.zip = zip;
        }
        if (country) {
            v.country = country;
        }
        setAccountState(s => {
            return {
                ...s,
                address: {...v}
            };
        });
    }

    const onChangeAddress = (name, value) => {
        setAccountState(s => {
            return {
                ...s,
                address: {
                    ...s.address,
                    [name]: value
                }
            };
        });
    }

    const onSaveAddress = () => {
        callApi(
            `/api/organizations/${accountState.organizationId}/intent`,
            {
                method: 'put',
                data: {
                    source: 'https://hopskip.com/web-client/1.0',
                    action: { type: 'mergeProperties'},
                    value: Object.keys(accountState.address).map(k => { return { name: k, value: accountState.address[k] }; }),
                }
            },
            true
        ).then(() => {
            setAccountState(s => {
                return {
                    ...s,
                    viewMode: viewModes.jobsToBeDone
                };
            });
        });
    }

    const toggleJobToBeDone = (jobToBeDone, checked) => {
        setAccountState(s => {
            return {
                ...s,
                jobsToBeDone: checked
                    ? [...s.jobsToBeDone, jobToBeDone]
                    : s.jobsToBeDone.filter(j => j !== jobToBeDone)
            };
        });
    }

    const onSaveJtbd = () => {
        callApi(
            `/api/userprofiles/${userProfile.id}/selectaccounttype/${accountState.accountType}`,
            {
                method: 'POST',
                data: {
                    ...accountState,
                    jobsToBeDone: accountState.jobsToBeDone.sort((c,n) => c > n ? 1 : -1),
                }
            },
            true
        ).then(() => {
            window.setTimeout(() => {
                if (!userProfile.accountType) {
                    window.location.reload();
                }
            }, 3000);
        });
    }

    return (
        <div id='select-role-container'>
            <Row>
                <Col className='left' lg={6} sm={12}>
                    <div className='inside-container'>
                        <div className='container-title'>
                            Welcome to HopSkip {userProfile.firstName}!
                        </div>
                        <div className='container-copy'>
                            To make sure we get your account set up correctly we just need to know how you'll use HopSkip and you'll be on your way!
                        </div>
                    </div>
                </Col>
                <Col className='right' lg={6} sm={12}>
                    <div className='inside-container'>
                        <div className='container-logo'>
                            <Image src='/images/logo-h-text-primary.png' alt='logo' />
                        </div>
                        {(accountState.viewMode === viewModes.role && (
                            <>
                                <div className='container-prompt'>What is your role?</div>
                                <div className='container-hint'>Select one option</div>
                                <div className='container-input'>
                                    <ListGroup>
                                        <ListGroup.Item onClick={() => selectRole(ROLE_PLANNER)}>
                                            <div className='item-title'>Planner</div>
                                            <div className='item-description'>I create & send RFPs and book meetings & events <b>for my organization or on behalf of my clients</b></div>
                                        </ListGroup.Item>
                                        <ListGroup.Item onClick={() => selectRole(ROLE_HOTELIER)}>
                                            <div className='item-title'>On-Property Hotel Sales/Lead Catcher</div>
                                            <div className='item-description'>I assign and/or submit proposals as part of a hotel sales team</div>
                                        </ListGroup.Item>
                                        <ListGroup.Item onClick={() => selectRole(ROLE_DESTINATIONMANAGER)}>
                                            <div className='item-title'>Destination Management Organization or CVB</div>
                                            <div className='item-description'>I suggest hotels and facilitate groups and meetings bookings for my city or destination</div>
                                        </ListGroup.Item>
                                        <ListGroup.Item onClick={() => selectRole(ROLE_AFFILIATE)}>
                                            <div className='item-title'>Sales Affiliate</div>
                                            <div className='item-description'>I suggest hotels and facilitate groups and meetings bookings for member hotels in my portfolio</div>
                                        </ListGroup.Item>
                                        <ListGroup.Item onClick={() => selectRole(ROLE_SUPPLIER)}>
                                            <div className='item-title'>Hotel Chain National Sales, Global Sales</div>
                                            <div className='item-description'>I suggest hotels and facilitate groups and meetings bookings for hotels in my chain</div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </>
                        )) || (accountState.viewMode === viewModes.jobsToBeDone && (
                            <>
                                <div className='container-prompt'>How would you like HopSkip to help you in your job?</div>
                                <div className='container-hint'>Select all that apply</div>
                                <div className='container-input'>
                                    <ListGroup>
                                        {jobsToBeDoneOptions[accountState.role].map((jobToBeDone, index) => (
                                            <ListGroup.Item key={index} style={{ cursor: 'default' }}>
                                                <Checkbox
                                                    label={jobToBeDone}
                                                    checked={accountState.jobsToBeDone.includes(jobToBeDone)}
                                                    onChange={e => toggleJobToBeDone(jobToBeDone, e.target.checked)}
                                                />
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>
                                <div className='container-cta'>
                                    <Button
                                        onClick={onSaveJtbd}
                                        disabled={accountState.jobsToBeDone.length === 0}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </>
                        )) || (accountState.viewMode === viewModes.accountType && (
                            <>
                                <div className='container-prompt'>What type of organization are you?</div>
                                <div className='container-hint'>Select one option</div>
                                <div className='container-input'>
                                    <ListGroup>
                                        {accountTypeOptions.filter(o => o.description).map((accountType, index) => (
                                            <ListGroup.Item key={index} onClick={() => selectAccountType(accountType.value)}>
                                                <div className='item-description'>{accountType.description}</div>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>
                            </>
                        )) || (accountState.viewMode === viewModes.address && (
                            <>
                                <div className='container-prompt'>Please enter your business address</div>
                                <div className='container-input'>
                                    <Form.Group>
                                        <Form.Label>Address</Form.Label>
                                        <LocationSelector
                                            customPlaceholder={'Lookup an address...'}
                                            value={location}
                                            onChange={onChangeLocation}
                                            isInvalid={!accountState.address.address}
                                        />
                                    </Form.Group>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={5}>
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                name='city'
                                                value={accountState.address.city}
                                                onChange={e => onChangeAddress(e.target.name, e.target.value)}
                                                isInvalid={!accountState.address.city}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                name='state'
                                                value={accountState.address.state}
                                                onChange={e => onChangeAddress(e.target.name, e.target.value)}
                                                isInvalid={!accountState.address.state}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} sm={3}>
                                            <Form.Label>Postal Code</Form.Label>
                                            <Form.Control
                                                name='zip'
                                                value={accountState.address.zip}
                                                onChange={e => onChangeAddress(e.target.name, e.target.value)}
                                                isInvalid={!accountState.address.zip}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                name='country'
                                                value={accountState.address.country}
                                                onChange={e => onChangeAddress(e.target.name, e.target.value)}
                                                isInvalid={!accountState.address.country}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                                <div className='container-cta'>
                                    <Button
                                        onClick={onSaveAddress}
                                        disabled={isAddressInvalid}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </>
                        ))}
                    </div>
                </Col>
            </Row>
            <Modal show={accountState.viewMode === viewModes.wait}>
                <Modal.Header closeButton={false}>
                    <Modal.Title>
                        Getting you setup...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(!isUserProfileInitialized(userProfile) && (
                        <div>
                            <Spinner animation='border' style={{ marginRight: '.5rem' }} />
                            Please wait...
                        </div>
                    )) || (ROLE_HOTELIER === accountState.role && (
                        <div>You can reach out using the chat tool in the lower left corner if you need to be associated with any properties.</div>
                    )) || (
                        <div>Taking you to your dashboard...</div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}