import React, { useContext, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { callApi } from '../../apiProvider';
import { UserProfileContext } from '../../userProfileContext';

export const EventCancelModal = ({ show, event, onClose, onConfirm }) => {
    const { userProfile } = useContext(UserProfileContext);
    const [changeComment, setChangeComment] = useState(null);
    const [hotelMessage, setHotelMessage] = useState(null);

    const onCancelCancel = () => {
        setHotelMessage(null);
        setChangeComment(null);
        onClose();
    }
    
    const onConfirmCancel = (suppressNotification) => {
        callApi(
            `/sourcing/api/events/plans/${event.id}/cancel`,
            {
                method: 'PUT',
                data: {
                    changeComment,
                    hotelMessage,
                    suppressNotification
                }
            },
            true
        )
        .finally(() => {
            setHotelMessage(null);
            setChangeComment(null);
            onConfirm();
        });
    }

    return (
        <Modal show={show} onHide={onCancelCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Cancel RFP and notify hotels</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ marginBottom: '0.75rem' }}>
                    Are you sure you want to cancel the {event.name} RFP? Your hotels will be notified via email.
                </div>
                <Form.Group>
                    <Form.Label>Please provide the reason for cancellation to your hotel partners:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={10}
                        value={hotelMessage || ''}
                        onChange={e => setHotelMessage(e.target.value)}
                        isInvalid={hotelMessage === null || hotelMessage.length <= 3}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Add an internal comment:</Form.Label>
                    <Form.Control
                        as="textarea"
                        value={changeComment || ''}
                        onChange={e => setChangeComment(e.target.value)}
                    />
                </Form.Group>
                You will be able to reactivate this RFP by opening the Cancelled RFP list from the RFP Board.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onCancelCancel}>
                    No, don't cancel
                </Button>
                <Button variant="success" onClick={() => onConfirmCancel(false)} disabled={!hotelMessage}>
                    Yes, cancel
                </Button>
                {userProfile.isAdmin && (
                    <Button variant="success" onClick={() => onConfirmCancel(true)}>
                        Yes, but do not notify hotels
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};  