import React from 'react';
import WhatsThis from '../whats-this';

export const Checkbox = ({ label, name, whatsThisContent, disabled, checked, onChange, tourAttributes, className, size, style, checkmarkStyle, isInvalid, invalidFeedback }) => {
    return (
        <>
            <label
                className={`checkbox-container${className ? ` ${className}` : ''}${isInvalid ? ' invalid' : ''}`}
                style={style}
            >
                {label}
                {whatsThisContent && (<WhatsThis title={label} content={whatsThisContent} />)}
                <input
                    name={name}
                    type="checkbox"
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange}
                />
                <span
                    {...tourAttributes}
                    className={`checkmark${size ? ` ${size}` : ''}`}
                    style={checkmarkStyle}
                ></span>
            </label>
            {isInvalid && invalidFeedback && (
                <div className="invalid-feedback">{invalidFeedback}</div>
            )}
        </>
    );
}