import { useContext, useEffect, useState } from "react";
import { callApi } from "../../apiProvider";
import { UserProfileContext } from "../../userProfileContext";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const isTrialLimitReached = (trialLimitStatus) => {
    if (trialLimitStatus.rfpLimit === null) {
        return false;
    }
    if (trialLimitStatus.rfpsSent === null || trialLimitStatus.rfpsSent < trialLimitStatus.rfpLimit) {
        return false;
    }
    return true;
};

export const useTrialLimitStatus = () => {
    const { userProfile } = useContext(UserProfileContext);
    const [trialLimitStatus, setTrialLimitStatus] = useState({ loading: true, rfpsSent: null, rfpLimit: null, isTrialLimitReached: true });

    useEffect(() => {
        setTrialLimitStatus(s => { return { ...s, loading: true }; });
        callApi(
            `/sourcing/api/events/getlimitstatus`,
            {
                method: "GET",
            },
            true
        ).then(r => setTrialLimitStatus({ ...r.data, loading: false, isTrialLimitReached: isTrialLimitReached(r.data) }));
    }, [userProfile.id]);

    return { trialLimitStatus };
};

export const TrialLimitModalContent = ({ trialLimitStatus }) => {
    const history = useHistory();

    return (
        <>
            <Modal.Body>
                <h4>Ahh! Looks Like You've Reached Your RFP Limit.</h4>
                <div>
                    <p>
                        It looks like you've already sent {trialLimitStatus.rfpLimit} RFP{trialLimitStatus.rfpLimit === 1 ? '' : 's'} during your free trial of HopSkip. That's the limit for our trial users, but we have great news!
                    </p>
                    <p>
                        You can easily upgrade to a paid subscription to send unlimited RFPs and enjoy other premium features.  Or, contact us to learn more about how HopSkip can make your sourcing process smoother than ever!
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={() => history.push('/payment/checkout')}
                >
                    View Upgrade Options
                </Button>
                <Button
                    className='cta-ic-planner-upgrade'
                    variant="secondary"
                    onClick={() => window.open('https://meetings.hubspot.com/swhalin/sw-planners-post-trial-contact-us-book-a-mtg-link', '_blank')}
                >
                    Contact Us
                </Button>
            </Modal.Footer>
        </>
    );
};