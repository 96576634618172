import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import { addHotelier, getHotel } from '../../services/hotels';
import { UserProfileContext } from '../../userProfileContext';
import { HotelLookup } from './hotel-lookup';
import './not-associated.scss';

export const HotelierNotAssociated = () => {
    const { userProfile } = useContext(UserProfileContext);
    const [venue, setVenue] = useState({});
    const [assigning, setAssigning] = useState(false);

	// check for hotelier routes
	const propertyProfileRouteMatch = useRouteMatch('/hotelprofile/:venueId?/:view?/:viewId?');
	const proposalRouteMatch = useRouteMatch('/proposal/:eventId/:venueId/:view?');

    useEffect(() => {
        const venueId = propertyProfileRouteMatch?.params?.venueId ?? proposalRouteMatch?.params?.venueId;
        if (venueId && venueId !== venue.id) {
            getHotel(venueId).then(r => setVenue(r.data));
        }
    }, [venue.id, propertyProfileRouteMatch, proposalRouteMatch]);

    const onSelectHotel = hotel => {
        setVenue(hotel);
        setAssigning(true);
        addHotelier(hotel.id, { id: userProfile.id });
    }

    if (userProfile.associatedVenues.find(v => v.id === venue.id)) {
        return null;
    }

    if (!venue.id && userProfile.associatedVenues.length > 0) {
        return null;
    }

    return (
        <div className='cold-welcome'>
            {(venue.id && venue.name && (
                <div className='cold-welcome-fill'>
                    <div className='cold-open-title smaller'>It looks like you are not yet associated with {venue.name.toLowerCase().startsWith('the') ? '' : 'the '}{venue.name}.</div>
                    <div className='cold-open-sub'>
                        <p>
                            If you represent more than one hotel, please <span className='cold-open-cta cta-rep-multiple-hotels'>click here</span> or reach out to us using the chat tool in the lower right corner.
                        </p>
                    </div>
                    <div className='cold-copy'>
                        {(assigning && (
                            <div>
                                <Spinner size='sm' animation='border' />
                                We're associating you with {venue.name.toLowerCase().startsWith('the') ? '' : 'the '}{venue.name}...
                            </div>
                        )) || (
                            <Button
                                onClick={() => onSelectHotel(venue)}
                                disabled={!venue.id}
                            >
                                Associate my account with {venue.name.toLowerCase().startsWith('the') ? '' : 'the '}{venue.name}
                            </Button>
                        )}
                    </div>
                </div>
            )) || (
                <div className='cold-welcome-fill'>
                    <div className='cold-open-title'>It looks like you are not yet associated with a hotel.</div>
                    <div className='cold-open-sub'>
                        <p>
                            Enter the name and/or address of your hotel below, then click your hotel to select it.
                        </p>
                        <p>
                            If you can't find your hotel or if you represent more than one hotel, please <span className='cold-open-cta cta-rep-multiple-hotels'>click here</span> or reach out to us using the chat tool in the lower right corner.
                        </p>
                    </div>
                    <div className='cold-copy'>
                        {(assigning && (
                            <div>
                                <Spinner size='sm' animation='border' />
                                We're associating you with {venue.name.toLowerCase().startsWith('the') ? '' : 'the '}{venue.name}...
                            </div>
                        )) || (
                            <HotelLookup onSelectHotel={onSelectHotel} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}