import React, { useContext, useEffect } from 'react';
import { UserProfileContext } from '../userProfileContext';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import PlannerPortal from './planner-portal';

const MyProfileView =           React.lazy(() => import('./profile/my-profile'));
const ChatView =                React.lazy(() => import('./chat'));
const Hotel =                   React.lazy(() => import('./hotel/view'));
const ProposalRequests =        React.lazy(() => import('./hotel-hub/index'));
const ProposalRequestView =     React.lazy(() => import('./hotel-hub/proposal-request'));
const HotelierHistoryView =     React.lazy(() => import('./hotel-hub/history'));
const HotelierReportsView =     React.lazy(() => import('./hotel-hub/reports'));
const HotelPromotionsView =     React.lazy(() => import('./hotel-hub/promotions'));
const HotelPromotionEditorView =React.lazy(() => import('./hotel-hub/promotion-editor'));
const HotelProfileView =        React.lazy(() => import('./hotel-hub/profile'));
const AffiliateOrganizationView=React.lazy(() => import('./affiliates/organization'));
const AffiliateReportsView =    React.lazy(() => import('./affiliates/reports'));
const DestinationProfileView =  React.lazy(() => import('./destinations/profile'));
const PaymentView =             React.lazy(() => import('./payment'));
const SupplierPortalView =      React.lazy(() => import('./suppliers/index'));
const SupplierHistoryView =     React.lazy(() => import('./suppliers/history'));
const SupplierRfpView =         React.lazy(() => import('./suppliers/rfp-view'));
const PlannerHistoryView =      React.lazy(() => import('./planner-portal/history'));
const PlannerReportsView =      React.lazy(() => import('./planner-portal/reports'));
const PlannerToolboxView =      React.lazy(() => import('./planner-portal/toolbox'));
const PromotionsView =          React.lazy(() => import('./planner-portal/promotions'));
const EducationCenterView =     React.lazy(() => import('./ed-center'));
const EventPlanner =            React.lazy(() => import('./planner-portal/event-planner'));
const SiteSearchView =          React.lazy(() => import('./planner-portal/site-search'));
const OrganizationView =        React.lazy(() => import('./organization'));
const AdminView =               React.lazy(() => import('./admin'));
const CheckoutRedirect =        React.lazy(() => import('./payment/checkout-redirect'));
const EventPlanSignals =        React.lazy(() => import('../components/event/signals'));
const DestinationReportsView =  React.lazy(() => import('./destinations/reports'));

// const plannerRoles = [ROLE_BOOKINGBUDDY, ROLE_PLANNER];
// const hotelRoles = [ROLE_BOOKINGBUDDY, ROLE_HOTELIER];
// const supplierRoles = [ROLE_BOOKINGBUDDY, ROLE_DESTINATIONMANAGER, ROLE_SUPPLIER, ROLE_SALESOPS];
// const dmoRoles = [ROLE_BOOKINGBUDDY, ROLE_DESTINATIONMANAGER];

const pathTitles = [
    { path: ['/admin/:view?/:viewId?'],                                 title: 'Admin' },
    { path: ['/edcenter/:view?/:viewId?'],                              title: 'Contracting Academy' },
    { path: ['/destination/reports/:view?/:viewId?'],                   title: 'Destination Reports' },
    { path: ['/destinationprofile/:destinationId?/:view?/:viewId?'],    title: 'Destination Profile' },
    { path: ['/hotel/:propertyId/:reviewId?'],                          title: 'Hotel' },
    { path: ['/myprofile/:view?'],                                      title: 'My Profile' },
    { path: ['/affiliate/:view?/:viewId?'],                             title: 'My RFPs' },
    { path: ['/destinationmanager/:view?/:viewId?'],                    title: 'My RFPs' },
    { path: ['/hotelier'],                                              title: 'My RFPs' },
    { path: ['/supplier/:view?/:viewId?'],                              title: 'My RFPs' },
    { path: ['/salesops/:view?/:viewId?'],                              title: 'My RFPs' },
    { path: ['/planner'],                                               title: 'My RFPs' },
    { path: ['/affiliateorganization/:organizationId?/:view?/:viewId?'],title: 'Organization' },
    { path: ['/affiliate/reports/:view?/:viewId?'],                     title: 'Reports' },
    { path: ['/organization/:organizationId?/:view?/:viewId?'],         title: 'Organization' },
    { path: ['/planner/toolbox/:view?'],                                title: 'Planner Toolbox' },
    { path: ['/hotelier/promotion/:venueId/:promotionId?/:viewId?'],    title: 'Promotion' },
    { path: ['/hotelier/promotions/:venueId?/:view?/:viewId?'],         title: 'Promotions' },
    { path: ['/promotions/:hotelId?/:promotionId?'],                    title: 'Promotions' },
    { path: ['/hotelprofile/:venueId?/:view?/:viewId?'],                title: 'Property Profile' },
    { path: ['/hotelier/history/:view?'],                               title: 'Proposal History' },
    { path: ['/proposal/:eventId/:venueId/:view?'],                     title: 'Proposal Request' },
    { path: ['/hotelier/reports/:view?/:viewId?'],                      title: 'Reports' },
    { path: ['/event/:eventId?/:view?/:viewId?/:tabId?'],               title: 'Request For Proposal' },
    { path: ['/supplier/request/:eventId/:view?'],                      title: 'Request for Proposal' },
    { path: ['/salesops/request/:eventId/:view?'],                      title: 'Request for Proposal' },
    { path: ['/planner/history/:view?'],                                title: 'RFP and Proposal History' },
    { path: ['/supplier/history/:view?'],                               title: 'RFP and Proposal History' },
    { path: ['/sitesearch/:view?/:eventId?/:viewId?'],                  title: 'Search Hotels' },
];

const isPathMatch = (pathTemplate, currentPath) => {
    const pathConstant = pathTemplate.split(':')[0];
    return `${currentPath}${pathConstant.endsWith('/') && !currentPath.endsWith('/') ? '/' : ''}`.includes(pathConstant);
}

export const Routes = () => {
	const { userProfile } = useContext(UserProfileContext);
    const location = useLocation();

    useEffect(() => {
        const pathTitle = pathTitles.find(pt => {
            if (typeof(pt.path) === 'string') {
                return isPathMatch(pt.path, location.pathname);
            } else if (typeof(pt.path) === 'object') {
                return pt.path.some(p => isPathMatch(p, location.pathname));
            }
            return false;
        });
        if (pathTitle?.title) {
            document.title = `HopSkip - ${pathTitle.title}`;
        }
    }, [location]);

    return (
        <Switch>
            <Route exact={true}
                path={'/hotel/:propertyId/:reviewId?'}
                component={Hotel}
            />
            <Route exact={true}
                path={'/hotelier/history/:view?'}
                component={HotelierHistoryView}
            />
            <Route exact={true}
                path={'/hotelier/promotion/:venueId/:promotionId?/:viewId?'}
                component={HotelPromotionEditorView}
            />
            <Route exact={true}
                path={'/hotelier/promotions/:venueId?/:view?/:viewId?'}
                component={HotelPromotionsView}
            />
            <Route exact={true}
                path={'/hotelier/reports/:view?/:viewId?'}
                component={HotelierReportsView}
            />
            <Route exact={true}
                path={'/hotelier'}
                component={ProposalRequests}
            />
            <Route exact={true}
                path={'/proposal/:eventId/:venueId/:view?'}
                component={ProposalRequestView}
            />
            <Route exact={true}
                path={'/hotelprofile/:venueId?/:view?/:viewId?'}
                component={HotelProfileView}
            />
            <Route exact={true}
                path={'/destination/reports/:view?/:viewId?'}
                component={DestinationReportsView}
            />
            <Route exact={true}
                path={'/affiliate/reports/:view?/:viewId?'}
                component={AffiliateReportsView}
            />
            <Route exact={true}
                path={'/affiliateorganization/:organizationId?/:view?/:viewId?'}
                component={AffiliateOrganizationView}
            />
            <Route exact={true}
                path={'/destinationprofile/:destinationId?/:view?/:viewId?'}
                component={DestinationProfileView}
            />
            <Route exact={true}
                path={'/supplier/history/:view?'}
                component={SupplierHistoryView}
            />
            <Route exact={true}
                path={['/supplier/request/:eventId/:view?','/salesops/request/:eventId/:view?']}
                component={SupplierRfpView}
            />
            <Route exact={true}
                path={['/affiliate/:view?/:viewId?','/destinationmanager/:view?/:viewId?','/supplier/:view?/:viewId?','/salesops/:view?/:viewId?']}
                component={SupplierPortalView}
            />
            <Route exact={true}
                path={'/planner/history/:view?'}
                component={PlannerHistoryView}
            />
            <Route exact={true}
                path={'/planner/reports/:view?/:viewId?'}
                component={PlannerReportsView}
            />
            <Route exact={true}
                path={'/planner/toolbox/:view?'}
                component={PlannerToolboxView}
            />
            <Route exact={true}
                path={'/planner'}
                component={PlannerPortal}
            />
            <Route exact={true}
                path={'/promotions/:hotelId?/:promotionId?'}
                component={PromotionsView}
            />
            <Route exact={true}
                path={'/sitesearch/:view?/:eventId?/:viewId?'}
                component={SiteSearchView}
            />
            <Route exact={true}
                path={'/event/:eventId?/:view?/:viewId?/:tabId?'}
                component={EventPlanner}
            />
            <Route exact={true}
                path={'/edcenter/:view?/:viewId?'}
                component={EducationCenterView}
            />
            <Route exact={true}
                path={'/myprofile/:view?'}
                component={MyProfileView}
            />
            <Route exact={true}
                path={'/organization/:organizationId?/:view?/:viewId?'}
                component={OrganizationView}
            />
            <Route exact={true}
                path={'/admin/:view?/:viewId?'}
                component={AdminView}
            />
            <Route exact={true}
                path={'/checkout/:role/:priceId/:productId/:quantity/:amount'}
                component={CheckoutRedirect}
            />
            <Route exact={true}
                path={'/payment/:paymentStatus'}
                component={PaymentView}
            />
            <Route exact={true}
                path={'/chat/:eventPlanId/:venueId?'}
                component={ChatView}
            />
            <Route exact={true}
                path={'/debug/event/:eventId'}
                component={EventPlanSignals}
            />
            <Redirect to={`/${userProfile.role.toLowerCase()}`} />
        </Switch>
    );
}