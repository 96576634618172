import React, { useEffect, useState } from 'react';
import { callApi } from '../../../apiProvider';
import { Row, Col, Spinner, ListGroup, Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './actions-list.scss';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { format, parseISO } from 'date-fns';

const loadActions = () => {
    return callApi(
        `/api/useractions`,
        { method: 'GET' },
        true
    );
}

const ActionsList = () => {
    const [loading, setLoading] = useState(false);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        setLoading(true);
        loadActions()
        .then(r => setActions(r.data))
        .finally(setLoading(false));
    }, []);

    const onClickMarkComplete = id => {
        callApi(
            `/api/useractions/${id}`,
            { method: 'DELETE' },
            true
        ).then(r => setActions(actions.filter(a => a.id !== id)));
    }

    return (
        <div id="my-actions-container">
            {actions.length > 0 && (
                <Badge variant="danger" pill className="counter-badge">{actions.length}</Badge>
            )}
            <div
                className="title"
                data-touranchorelement='userActionsContainer'
                data-touranchorposition="right"
            >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                Actions
            </div>
            <div className="list-container">
                {loading && (
                    <div style={{ textAlign: 'center' }}>
                        <Spinner variant="success" animation="border" />
                    </div>
                )}
                <ListGroup className="actions-list">
                    <ListGroup.Item className="action-header">
                        <Row style={{ alignItems: 'center' }}>
                            <Col sm={4}>
                                Action
                            </Col>
                            <Col xl={4} md={3}>
                                RFP
                            </Col>
                            <Col md={2}>
                                Due Date
                            </Col>
                            <Col xl={2} md={3}>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {(actions.length > 0 && actions.sort((c, n) => c.created > n.created ? 1 : -1).map((a, i) => (
                        <ListGroup.Item key={`ua-${i}`} className="action-item">
                            <Row style={{ alignItems: 'center' }}>
                                <Col md={4}>
                                    <Link to={`/event/${a.eventPlanId}/${a.venueId ? `proposal/${a.venueId}` : 'overview'}`}>
                                        {a.description}
                                    </Link>
                                </Col>
                                <Col xl={4} md={3}>
                                    {a.eventPlanName}
                                </Col>
                                <Col md={2}>
                                    {a.dueDate ? format(parseISO(a.dueDate), 'M/d/yy') : 'NA'}
                                </Col>
                                <Col xl={2} md={3}>
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => onClickMarkComplete(a.id)}
                                    >
                                        Complete
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))) || (
                        <ListGroup.Item className="action-item">
                            You're done!
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </div>
        </div>
    );
}
export default ActionsList;