import React, { useEffect, useState } from 'react';
import { format, parseJSON } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import './message-bubble.scss';

export const MessageBubble = ({ direction, message, author, itemRef }) => {
  const [messageState, setMessageState] = useState({
    mediaDownloadFailed: false,
    mediaUrl: null,
    authorName: ''
  });

  useEffect(() => {
    if (message.type === 'media') {
      message.media.getContentUrl()
      .then(url => setMessageState( s => { return { ...s, mediaUrl: url, mediaDownloadFailed: false }; }))
      .catch(() => setMessageState(s => { return { ...s, mediaDownloadFailed: true }; }));
    }
  }, [message]);

  useEffect(() => {
    if (itemRef?.current) {
      itemRef.current.scrollIntoView();
    }
  }, [itemRef]);

  useEffect(() => {
    if (author && author.state?.friendlyName) {
      const nameParts = author.state.friendlyName.split(' ');
      if (nameParts.length === 1) {
        setMessageState(s => { return { ...s, authorName: nameParts[0] }; });
      } else {
        nameParts[nameParts.length - 1] = nameParts[nameParts.length - 1].substring(0, 1);
        setMessageState(s => { return { ...s, authorName: nameParts.join(' ') }; });
      }
    }
  }, [author]);

return (
  <Row className={`message-item ${direction}`}>
    <Col className="message-container">
      <div className="message-bubble">
        {direction === 'incoming' && (
          <FontAwesomeIcon
            title={`${messageState.authorName || message.author} is${author && author.state?.online ? ' ' : ' not '}online`}
            style={{ color: author && author.state?.online ? '#4FC0B0' : '#f0f2f3' }}
            icon={faUserCircle}
          />
        )}
        <div className="message" ref={itemRef}>
          {direction === 'incoming' && (
            <strong>{messageState.authorName || message.author || message.from}</strong>
          )}
          <span className="timestamp">
            {format(parseJSON(message.dateCreated || message.date_created), 'M/d/yy h:mmaaa')}
          </span>
          <br />
          {message.type === 'media' ? (
            <Media
              hasFailed={messageState.mediaDownloadFailed}
              url={messageState.mediaUrl}
            />
          ) : null}
          <div dangerouslySetInnerHTML={{ __html: message.body.replace(/\n/g, '<br/>')}} />
        </div>
      </div>
    </Col>
  </Row>
  );
}

function Media(props) {
  if (props.hasFailed) return <p>(Failed to download media!)</p>;
  else if (props.url === null) return <p>Downloading…</p>;
  // eslint-disable-next-line
  else return <img className="image" src={props.url} />;
}