import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureContext } from '../../../featureProvider';
import './nav-item.scss'

export const UpgradeNavItem = () => {
    const { canUpgrade } = useContext(FeatureContext);
    const history = useHistory();

    if (!canUpgrade) {
        return null;
    }

    return (
        <div
            className="nav-item upgrade"
            onClick={() => history.push('/payment/checkout')}
        >
            <div>Upgrade</div>
        </div>
    );
}