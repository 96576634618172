import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { DataGrid, customColumnTypes } from "../../data-grid";
import { format, parseISO } from "date-fns";
import { formatCurrency, formatNumber } from "../../../common";
import { EventPlanStatusMap } from "../../../common/statusMaps";
import { Badge, Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { RfpCode } from "../../rfp-code";
import { excelFormatStrings } from "../../../services/reports";
import { dashboardStatuses } from ".";
import { ActionToggle } from "../../buttons/action-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { StackedBar } from "../../reporting/stacked-bar";
import { useContext } from "react";
import { CurrencyContext } from "../../../currencyProvider";

export const RfpViewGrid = ({ id, title, events, currency, onMarkContractSigned, onAssignOwner, onShareEvent, onCopyEvent, onDeleteEvent, onCancelEvent, includeRoomRate, includeContractedDate, rfpValueColumnHeader, lockPaidFeatures }) => {
    const { getCurrency } = useContext(CurrencyContext);
    const noActionsAvailable = [onMarkContractSigned, onAssignOwner, onShareEvent, onCopyEvent, onDeleteEvent, onCancelEvent].every(a => typeof(a) !== 'function');
    
    const formatName = item => {
        return (
            <div style={{ position: 'relative' }}>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Popover>
                            <Popover.Content>
                                {item.name}
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <Link
                        className="overflow-ellipsis w85p"
                        to={`/event/${item.id}`}
                    >
                        {item.name}
                    </Link>
                </OverlayTrigger>
                <RfpCode
                    rfpCode={item.rfpCode}
                    fontSize='.8rem'
                />
                {item.isSelectionOverdue && (
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Popover>
                                <Popover.Content>
                                    {`Proposal Selection Date: ${format(parseISO(item.selectionDate), 'MMM d, yyyy')}`}
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <Badge
                            className="status-badge danger abs-top-right"
                        >
                            Overdue
                        </Badge>
                    </OverlayTrigger>
                )}
            </div>
        );
    }

    const formatStatus = item => {
        const dashboardStatus = dashboardStatuses.find(s => s.status === item.status);
        return (
            <Badge
                className="status-badge"
                style={{ backgroundColor: dashboardStatus?.fill, color: dashboardStatus?.color }}
            >
                {EventPlanStatusMap[item.status].label}
            </Badge>
        );
    }

    const formatDate = (date, color) => {
        if (!!date) {
            return <span style={{ color: color || '#000'}}>{format(parseISO(date), 'MM/dd/yyyy')}</span>;
        }
        return '';
    }

    const formatResponseRate = item => {
        if (item.status !== EventPlanStatusMap.New.key) {
            return (
                <div
                    className="response-rate-container"
                >
                    <div>
                        {item.status !== EventPlanStatusMap.Submitted.key && (
                            <StackedBar
                                hideLegend={true}
                                data={[
                                    { label: 'Responded', formattedValue: formatNumber(item.requestsResponded), value: item.requestsResponded, color: '#027587' },
                                    { label: 'Awaiting Reply', formattedValue: formatNumber(item.requestsAwaiting), value: item.requestsAwaiting, color: '#c0cadd' }
                                ]}
                            />
                        )}
                    </div>
                    <div>
                        {item.status === EventPlanStatusMap.Submitted.key ? (
                            <span className="text-muted smaller">
                                {`${formatNumber(item.requestsTotal)} hotels requested`}
                            </span>
                        ) : (
                            <span className="text-muted smaller">
                                {`${formatNumber(item.requestsResponded)}/${formatNumber(item.requestsTotal)} hotels (${formatNumber(item.responseRate)}%)`}
                            </span>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    }

    const formatValue = (item, value) => {
        if (!!value) {
            return formatCurrency(value, currency || getCurrency(item.currencyCode));
        }
        return '';
    };

    const formatActions = item => {
        return (
            <div style={{ textAlign: 'center' }}>
                <Dropdown>
                    <Dropdown.Toggle as={ActionToggle} id="dropdown-actions">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {item.status === EventPlanStatusMap.Contracting.key && (<Dropdown.Item onClick={() => onMarkContractSigned(item)}>Mark as Contract Signed</Dropdown.Item>)}
                        <Dropdown.Item onClick={() => onAssignOwner(item)}>Assign Owner...</Dropdown.Item>
                        <Dropdown.Item onClick={() => onShareEvent(item)}>Share with planner(s)...</Dropdown.Item>
                        <Dropdown.Item onClick={() => onCopyEvent(item)}>Duplicate RFP...</Dropdown.Item>
                        {item.status === EventPlanStatusMap.New.key && (
                            <Dropdown.Item onClick={() => onDeleteEvent(item)}>Delete</Dropdown.Item>
                        )}
                        {['New','Cancelled'].includes(item.status) === false && (
                            <Dropdown.Item onClick={() => onCancelEvent(item)}>Cancel</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    return (
        <DataGrid
            id={id || 'rfp-view-grid'}
            lockPaidFeature={lockPaidFeatures}
            dataSet={{
                title: title || 'All RFPs',
                fields: [
                    { name: 'name', header: 'RFP Name', format: formatName, customColumnType: customColumnTypes.required.key, exportFormat: item => `${window.hopSkipConfig.clientRoot || window.hopSkipConfig.api.host}/#/event/${item.id}|${item.name}`, exportFormatString: excelFormatStrings.hyperlink, },
                    { name: 'rfpCode', header: 'RFP Code', format: item => <RfpCode rfpCode={item.rfpCode} />, customColumnType: customColumnTypes.optional.key, exportFormat: item => item.rfpCode, },
                    { name: 'status', header: 'Status', format: formatStatus, exportFormat: item => EventPlanStatusMap[item.status].label, customColumnType: customColumnTypes.default.key, },
                    { name: 'ownerName', header: 'Owner', customColumnType: customColumnTypes.default.key, },
                    { name: 'submitted', header: 'Sent', format: item => formatDate(item.submitted), customColumnType: customColumnTypes.default.key, exportFormat: item => item.submitted, exportFormatString: excelFormatStrings.date, },
                    { name: 'responsesDueDate', header: 'Responses due', format: item => formatDate(item.responsesDueDate, item.overdueForResponses ? '#B82B45' : '#000'), customColumnType: customColumnTypes.default.key, exportFormat: item => item.responsesDueDate, exportFormatString: excelFormatStrings.date, },
                    { name: 'responseRate', header: 'Response rate', format: formatResponseRate, customColumnType: customColumnTypes.default.key, exportFormat: item => item.responseRate / 100, exportFormatString: excelFormatStrings.percentageNoDecimals, },
                    { name: 'selectionDate', header: 'Decision due', format: item => formatDate(item.selectionDate, item.overdueForSelection ? '#B82B45' : '#000'), customColumnType: customColumnTypes.default.key, exportFormat: item => item.selectionDate, exportFormatString: excelFormatStrings.date, },
                    { name: 'firstContractSigned', header: 'Contracted date', hideColumn: !includeContractedDate, format: item => formatDate(item.firstContractSigned), customColumnType: customColumnTypes.default.key, exportFormat: item => item.firstContractSigned, exportFormatString: excelFormatStrings.date, },
                    { name: 'averageRoomRate', header: 'Avg Room Rate', hideColumn: !includeRoomRate, format: item => formatValue(item, item.averageRoomRate), customColumnType: customColumnTypes.default.key, exportFormat: item => item.averageRoomRate, exportFormatString: excelFormatStrings.currency, },
                    { name: 'rfpValue', header: rfpValueColumnHeader || 'RFP Value', format: item => formatValue(item, item.rfpValue), customColumnType: customColumnTypes.default.key, exportFormat: item => item.rfpValue, exportFormatString: excelFormatStrings.currency, },
                    { name: 'requestsTotal', header: 'Hotels Requested', customColumnType: customColumnTypes.optional.key, exportFormat: item => item.requestsTotal, exportFormatString: excelFormatStrings.numberNoDecimals, },
                    { name: 'requestsAwaiting', header: 'Awaiting', customColumnType: customColumnTypes.optional.key, exportFormat: item => item.requestsAwaiting, exportFormatString: excelFormatStrings.numberNoDecimals, },
                    { name: 'requestsResponded', header: 'Responses', customColumnType: customColumnTypes.optional.key, exportFormat: item => item.requestsResponded, exportFormatString: excelFormatStrings.numberNoDecimals, },
                    { name: 'actions', header: 'Actions', hideColumn: noActionsAvailable, format: formatActions, customColumnTypes: customColumnTypes.required.key, exportFormat: item => `${window.hopSkipConfig.clientRoot || window.hopSkipConfig.api.host}/#/event/${item.id}`, exportFormatString: excelFormatStrings.url, },
                ],
                defaultSort: { field: 'name', direction: 1 },
                data: events,
            }}
            exportConfiguration={{
                title: title || 'RFPs',
                workbookId: 'clientexport',
                packageData: {
                    workbookName: `rfps-${format(new Date(), 'yyyyMMdd-HHmmss')}`,
                    sheets: [
                        {
                            name: title || 'RFPs',
                            title: title || 'RFPs'
                        }
                    ]
                }
            }}
        />
    );
}