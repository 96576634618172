import React, { useState, useEffect } from 'react';
import { InputGroup, FormControl, Spinner, ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import PlacesAutocomplete, { geocodeByAddress } from "../../react-places-autocomplete";

export const locationTypes = {
    ROOFTOP: 'ROOFTOP',
    RANGE_INTERPOLATED: 'RANGE_INTERPOLATED',
    GEOMETRIC_CENTER: 'GEOMETRIC_CENTER',
    APPROXIMATE: 'APPROXIMATE',
};

export const LocationSelector = ({ value, isInvalid, onChange, onChangeExt, customPlaceholder, searchOptions, clearAfterChange, clickToChange }) => {
    const [placeAddress, setPlaceAddress] = useState(value.name || '');
    const [location, setLocation] = useState({ ...value });

    useEffect(() => {
        setPlaceAddress(value.name || '');
    }, [value.name]);

    useEffect(() => {
        if (location.name !== value.name) {
            if (!clickToChange) {
                onChange(location);
                if (clearAfterChange) {
                    setPlaceAddress('');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const onChangeClick = () => {
        onChange(location);
        if (clearAfterChange) {
            setPlaceAddress('');
        }
    }

    const handlePlaceChange = address => {
        if (!address) {
            setLocation({
                name: '',
                latitude: 0,
                longitude: 0,
                addressComponents: {},
            });
        }
        setPlaceAddress(address);
    }

    const parseAddressComponents = addressComponents => {
        if (addressComponents !== null && addressComponents !== undefined && addressComponents.length > 0) {
            return addressComponents.reduce((p, c) => {
                if (c.types !== undefined && c.types.length > 0) {
                    /* US address_components = [
                        { "long_name" : "1", "short_name" : "1", "types" : [ "street_number" ] },
                        { "long_name" : "Roosevelt Drive", "short_name" : "Roosevelt Dr", "types" : [ "route" ] },
                        { "long_name" : "Mount Laurel Township", "short_name" : "Mt Laurel Township", "types" : [ "locality", "political" ] },
                        { "long_name" : "Burlington County", "short_name" : "Burlington County", "types" : [ "administrative_area_level_2", "political" ] },
                        { "long_name" : "New Jersey", "short_name" : "NJ", "types" : [ "administrative_area_level_1", "political" ] },
                        { "long_name" : "United States", "short_name" : "US", "types" : [ "country", "political" ] },
                        { "long_name" : "08054", "short_name" : "08054", "types" : [ "postal_code" ] },
                        { "long_name" : "6307", "short_name" : "6307", "types" : [ "postal_code_suffix" ] }
                    ];*/
                    /* UK address_components = [
                        { "long_name" : "48", "short_name" : "48", "types" : [ "street_number" ] },
                        { "long_name" : "Doughty Street", "short_name" : "Doughty St", "types" : [ "route" ] },
                        { "long_name" : "London", "short_name" : "London", "types" : [ "postal_town" ] },
                        { "long_name" : "Greater London", "short_name" : "Greater London", "types" : [ "administrative_area_level_2", "political" ] },
                        { "long_name" : "England", "short_name" : "England", "types" : [ "administrative_area_level_1", "political" ] },
                        { "long_name" : "United Kingdom", "short_name" : "GB", "types" : [ "country", "political" ] },
                        { "long_name" : "WC1N", "short_name" : "WC1N", "types" : [ "postal_code", "postal_code_prefix" ] }
                    ];*/
                    /* CA address_components = [
                        { "long_name" : "Koffler Student Services Centre", "short_name" : "Koffler Student Services Centre", "types" : [ "premise" ] },
                        { "long_name" : "214", "short_name" : "214", "types" : [ "street_number" ] },
                        { "long_name" : "College Street", "short_name" : "College St", "types" : [ "route" ] },
                        { "long_name" : "Old Toronto", "short_name" : "Old Toronto", "types" : [ "political", "sublocality", "sublocality_level_1" ] },
                        { "long_name" : "Toronto", "short_name" : "Toronto", "types" : [ "locality", "political" ] },
                        { "long_name" : "Toronto", "short_name" : "Toronto", "types" : [ "administrative_area_level_2", "political" ] },
                        { "long_name" : "Ontario", "short_name" : "ON", "types" : [ "administrative_area_level_1", "political" ] },
                        { "long_name" : "Canada", "short_name" : "CA", "types" : [ "country", "political" ] },
                        { "long_name" : "M5T 3A1", "short_name" : "M5T 3A1", "types" : [ "postal_code" ] }
                    ];*/
                    /* MX address_components = [
                        { "long_name" : "Avenida Yaxchilán", "short_name" : "Av Yaxchilán", "types" : [ "route" ] },
                        { "long_name" : "24", "short_name" : "24", "types" : [ "political", "sublocality", "sublocality_level_2" ] },
                        { "long_name" : "Cancún", "short_name" : "Cancún", "types" : [ "locality", "political" ] },
                        { "long_name" : "Quintana Roo", "short_name" : "Q.R.", "types" : [ "administrative_area_level_1", "political" ] },
                        { "long_name" : "Mexico", "short_name" : "MX", "types" : [ "country", "political" ] }
                    ];*/
                    switch (c.types[0]) {
                        case 'street_number':
                            p.streetNumber = c.long_name;
                            break;
                        case 'route':
                            p.route = c.long_name;
                            break;
                        case 'locality':
                        case 'postal_town':
                            p.city = c.long_name;
                            break;
                        case 'administrative_area_level_1':
                            p.state = c.short_name;
                            break;
                        case 'postal_code':
                            p.zip = c.long_name;
                            break;
                        case 'country':
                            p.country = c.short_name;
                            break;
                        default:
                            //noop
                            break;
                    }
                    if (c.types.includes('sublocality')) {
                        p.route = `${p.route} ${c.long_name}`;
                    }
                }
                return p;
            }, {});
        }
        return {};
    }

    const handlePlaceSelect = (address, placeId, suggestion) => {
        setPlaceAddress(address);
        geocodeByAddress(address)
            .then(results => {
                try {
                    const addressComponents = parseAddressComponents(results[0]['address_components']);
                    if (typeof(onChangeExt) === 'function') {
                        onChangeExt({ ...addressComponents, latitude: results[0].geometry.location.lat(), longitude: results[0].geometry.location.lng() });
                    }
                    setLocation({
                        placeName: suggestion?.formattedSuggestion?.mainText,
                        placeId: placeId,
                        name: address,
                        formattedAddress: results[0].formatted_address,
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng(),
                        locationType: results[0].geometry.location_type,
                        addressComponents: {...addressComponents},
                        isLodging: results[0].types.includes('lodging'),
                    })
                } catch (error) {
                    console.error("Error", error);
                }
            })
            .catch(error => console.error("Error", error));
    };

    return (
        <PlacesAutocomplete
            value={placeAddress || ''}
            onChange={handlePlaceChange}
            onSelect={handlePlaceSelect}
            searchOptions={searchOptions || {}}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => (
                    <div id="location-selector-inputs">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    {loading && <Spinner size="sm" variant="secondary" animation="border" />}
                                    {!loading && <FontAwesomeIcon icon={faSearch} />}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                {...getInputProps({
                                    placeholder: customPlaceholder || "Where to?"
                                })}
                                isInvalid={isInvalid}
                            />
                            {clickToChange && (
                                <InputGroup.Append>
                                    <Button
                                        variant="primary"
                                        onClick={onChangeClick}
                                        disabled={!location.name}
                                    >
                                        <FontAwesomeIcon icon={faPlusSquare} />
                                    </Button>
                                </InputGroup.Append>
                            )}
                        </InputGroup>
                        <ListGroup className="places-dropdown-container">
                            {suggestions.map((suggestion, suggestionIndex) => {
                                const className = suggestion.active
                                    ? "places-suggestion-item-active"
                                    : "places-suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                return (
                                    <ListGroup.Item
                                        key={`ls-sg-${suggestionIndex}`}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style
                                        })}
                                    >
                                        <span className="places-suggestion-text">
                                            {suggestion.description}
                                        </span>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </div>
                )}
        </PlacesAutocomplete>
    );
}