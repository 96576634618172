import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import './report-card.scss';
import WhatsThis from '../whats-this';

export const reportCardHeights = {
    single: 'single',
    singleHalf: 'singleHalf',
    singlePlus: 'singlePlus',
    double: 'double',
    triple: 'triple',
    huge: 'huge',
    fit: 'fit'
}

export const ReportCard = props => {
    return (
        <div className={`report-card-container ${props.height || reportCardHeights.single}`}>
            <div className='report-card-header'>
                <div className='report-card-title'>
                    {props.lockPaidFeature && (
                        <FontAwesomeIcon icon={faLockAlt} style={{ marginRight: '0.5rem' }} />
                    )}
                    {props.title}
                    {props.infoText ? (
                        <WhatsThis content={props.infoText} verticalAlign='middle' />
                    ) : null}
                </div>
                {props.toolsMiddle && (
                    <div className='report-card-tools-middle'>
                        {props.toolsMiddle}
                    </div>
                )}
                {props.tools && (
                    <div className='report-card-tools'>
                        {props.tools}
                    </div>
                )}
            </div>
            <div className={`report-card-content${props.lockPaidFeature ? ' blur' : ''}`}>
                {props.loading ? (
                    <Spinner animation='border' variant='primary' />
                ) : (
                    <>
                    {props.children}
                    </>
                )}

            </div>
        </div>
    );
}