import { Badge, Col, Dropdown, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { dashboardStatuses } from ".";
import { ReportBoardCol, ReportBoardRow } from "../../reporting/report-board";
import './rfp-view-pipeline.scss';
import { format, parseISO } from "date-fns";
import { EventPlanStatusMap } from "../../../common/statusMaps";
import { ActionToggle } from "../../buttons/action-toggle";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { RfpCode } from "../../rfp-code";
import { StackedBar } from "../../reporting/stacked-bar";
import { formatNumber } from "../../../common";
import { useContext } from "react";
import { UserProfileContext } from "../../../userProfileContext";

export const RfpViewPipeline = ({ events, onMarkContractSigned, onAssignOwner, onShareEvent, onCopyEvent, onDeleteEvent, onCancelEvent }) => {
    const { userProfile } = useContext(UserProfileContext);
    return (
        <ReportBoardRow>
            {dashboardStatuses.map((s, i) => (
                <ReportBoardCol
                    key={i}
                    className="kanban-column"
                >
                    <div
                        className="kanban-column-header"
                        style={{ borderBottomColor: s.fill }}
                    >
                        <span className="title">{EventPlanStatusMap[s.status].label}</span>
                        <Badge variant="light" pill className="count">{events.filter(e => e.status === s.status).length}</Badge>
                    </div>
                    {events.filter(e => e.status === s.status).map((e, i) => {
                        return (
                            <div
                                key={i}
                                className="kanban-card"
                            >
                                <Badge
                                    className="status-badge abs-top-left"
                                    style={{ backgroundColor: s.fill, color: s.color }}
                                >
                                    {EventPlanStatusMap[e.status].label}
                                </Badge>
                                <Dropdown className="abs-top-right">
                                    <Dropdown.Toggle as={ActionToggle} id="dropdown-actions">
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {e.status === EventPlanStatusMap.Contracting.key && (<Dropdown.Item onClick={() => onMarkContractSigned(e)}>Mark as Contract Signed</Dropdown.Item>)}
                                        <Dropdown.Item onClick={() => onAssignOwner(e)}>Assign Owner...</Dropdown.Item>
                                        <Dropdown.Item onClick={() => onShareEvent(e)}>Share with planner(s)...</Dropdown.Item>
                                        <Dropdown.Item onClick={() => onCopyEvent(e)}>Duplicate RFP...</Dropdown.Item>
                                        {e.status === EventPlanStatusMap.New.key && (
                                            <Dropdown.Item onClick={() => onDeleteEvent(e)}>Delete</Dropdown.Item>
                                        )}
                                        {['New','Cancelled'].includes(e.status) === false && (
                                            <Dropdown.Item onClick={() => onCancelEvent(e)}>Cancel</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    overlay={
                                        <Popover>
                                            <Popover.Content>
                                                {e.name}
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <Link
                                        className="overflow-ellipsis w20r"
                                        to={`/event/${e.id}`}
                                    >
                                        {e.name}
                                    </Link>
                                </OverlayTrigger>
                                <RfpCode
                                    rfpCode={e.rfpCode}
                                    fontSize='.8rem'
                                />
                                {s.status !== EventPlanStatusMap.New.key && (
                                    <Row
                                        className="response-rate-container"
                                    >
                                        <Col>
                                            {s.status !== EventPlanStatusMap.Submitted.key && (
                                                <StackedBar
                                                    hideLegend={true}
                                                    data={[
                                                        { label: 'Responded', formattedValue: formatNumber(e.requestsResponded), value: e.requestsResponded, color: '#027587' },
                                                        { label: 'Awaiting Reply', formattedValue: formatNumber(e.requestsAwaiting), value: e.requestsAwaiting, color: '#c0cadd' }
                                                    ]}
                                                />
                                            )}
                                        </Col>
                                        <Col sm={5}>
                                            {s.status === EventPlanStatusMap.Submitted.key ? (
                                                <span className="text-muted smaller">
                                                    {`${formatNumber(e.requestsTotal)} hotels requested`}
                                                </span>
                                            ) : (
                                                <span className="text-muted smaller">
                                                    {`${formatNumber(e.requestsResponded)}/${formatNumber(e.requestsTotal)} hotels (${formatNumber(e.responseRate)}%)`}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                {[EventPlanStatusMap.Submitted.key,EventPlanStatusMap.Bidding.key].includes(s.status) && !!e.selectionDate && parseISO(e.selectionDate) < new Date() && (
                                    <OverlayTrigger
                                        trigger={['hover', 'focus']}
                                        overlay={
                                            <Popover>
                                                <Popover.Content>
                                                    {`Proposal Selection Date: ${format(parseISO(e.selectionDate), 'MMM d, yyyy')}`}
                                                </Popover.Content>
                                            </Popover>
                                        }
                                    >
                                        <Badge
                                            className="status-badge danger"
                                        >
                                            Overdue
                                        </Badge>
                                    </OverlayTrigger>
                                )}
                                <div className="abs-bottom-right">
                                    {userProfile.isAdmin && (
                                        <Link
                                            className="text-muted smaller"
                                            to={`/organization/${e.organizationId}`}
                                        >
                                            {e.organizationName}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </ReportBoardCol>
            ))}
        </ReportBoardRow>
    );
}