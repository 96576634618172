import { comparableHotelSources } from ".";
import { findSupplierContactOrTeammate } from "./supplierContacts";

export const proposalValuesComponents = {
    roomCost: { key: 'roomCost', label: 'Room Rate', description: 'Total revenue from room rate only', sort: 100, default: true, },
    roomTax: { key: 'roomTax', label: 'Room Tax', description: 'Total tax amount on the room rate', sort: 200, default: false, },
    roomFees: { key: 'roomFees', label: 'Room Fees', description: 'Total amount of room fees. Fees are never included in the ADR calculation.', sort: 300, default: true, },
    foodAndBeverage: { key: 'foodAndBeverage', label: 'F&B Revenue', description: 'F&B Minimum or detailed estimate', sort: 400, default: true, },
    foodAndBeverageTax: { key: 'foodAndBeverageTax', label: 'F&B Tax', description: 'Total tax amount on F&B', sort: 500, default: false, },
    roomRental: { key: 'roomRental', label: 'Room Rental', description: 'Total amount of any meeting room or event space rental fee(s)', sort: 600, default: false, },
    roomRentalTax: { key: 'roomRentalTax', label: 'Room Rental Tax', description: 'Total tax amount on room rental fee(s)', sort: 700, default: false, },
    serviceCharge: { key: 'serviceCharge', label: 'Service Charge', description: 'Total amount of service charge percentage applied to the F&B revenue', sort: 800, default: false, },
    serviceChargeTax: { key: 'serviceChargeTax', label: 'Service Charge Tax', description: 'Total amount of tax on the service charge', sort: 900, default: false, },
};

export const calculateOpportunityValue = (proposalValues) => {
    return proposalValues.roomCost
        + proposalValues.roomFees
        + proposalValues.foodAndBeverage
        + proposalValues.roomRental;
}

export const calculateGrossMarketValue = (proposalValues) => {
    // include everything
    return calculateProposalValue(proposalValues, Object.keys(proposalValuesComponents));
}

export const calculateProposalValue = (proposalValues, componentKeys) => {
    const calculateComponents = componentKeys ?? Object.keys(proposalValuesComponents).filter(k => proposalValuesComponents[k].default);
    return calculateComponents.reduce((a,c) => a + (proposalValues[c] ?? 0), 0);
}

export const normalizeProposalsByRfp = proposals => proposals.reduce((a, pr) => {
    if (!a.some(e => e.eventPlanId === pr.eventPlanId)) {
        a.push({ ...pr, proposalValues: pr.proposalValuesAverage } );
    }
    return a;
}, []);

export const getProposalRequestLeadSource = ({ eventPlan, proposalRequest }) => {
    const supplierContact = findSupplierContactOrTeammate(eventPlan.supplierContacts, proposalRequest.createdBy);
    if (supplierContact?.isHopSkip) {
        if (proposalRequest.comparableHotelSource === comparableHotelSources.CompSet) {
            return { label: 'HopSkip Comparable Hotel', description: 'A hotel in your comp set received this RFP' };
        } else {
            return { label: 'HopSkip Comparable Hotel', description: 'A hotel in your market received this RFP' };
        }
    } else if (supplierContact) {
        return { label: `Suggested by ${supplierContact.companyName} via HopSkip`, description: '' };
    } else if (proposalRequest.promotionId) {
        return { label: `Hotel promotion via HopSkip`, description: 'The planner added your hotel to this RFP from a promotion' };
    } else {
        return { label: `Planner handpicked your hotel via HopSkip`, description: 'The planner added your hotel to this RFP from search results' };
    }
}

export const calculateProposalMetrics = ({ proposals, componentKeys, useAverage }) => {
    const calculateComponents = componentKeys ?? Object.keys(proposalValuesComponents).filter(k => proposalValuesComponents[k].default);
    return proposals.reduce((a,c) => {
        const values = useAverage ? 'proposalValuesAverage' : c.submitted ? 'proposalValues' : 'proposalValuesAverage';
        const proposalValue = calculateProposalValue(c[values], calculateComponents);
        const roomRevenue = (c[values].roomCost ?? 0) + (calculateComponents.includes(proposalValuesComponents.roomFees.key) ? (c[values].roomFees ?? 0) : 0);
        a.count += 1;
        a.countWithRooms += (c.roomBlocksRequired ? 1 : 0);
        a.countWithSpace += (c.meetingSpaceRequired ? 1 : 0);
        a.total += proposalValue;
        a.roomFees += c[values].roomFees;
        a.roomRevenue += roomRevenue;
        a.roomCost += c[values].roomCost ?? 0   ;
        a.foodAndBeverage += c[values].foodAndBeverage ?? 0;
        a.roomRental += c[values].roomRental;
        a.serviceCharge += c[values].serviceCharge;
        a.totalRoomsOffered += c[values].totalRoomsOffered ?? 0;
        a.peakRooms += c.eventPlanPeakRooms ?? 0;
        a.peakSpace += c.eventPlanPeakMeetingSpaceRequired ?? 0;
        a.largestSpace = Math.max(a.largestSpace ?? 0, c.eventPlanLargestMeetingSpaceRequired ?? 0);
        a.allowDateSuggestions += c.allowDateSuggestions;
        a.series.push({
            ...c,
            ...c[values],
            peakRooms: c.eventPlanPeakRooms,
            peakSpace: c.eventPlanPeakMeetingSpaceRequired,
            largestSpace: c.eventPlanLargestMeetingSpaceRequired,
            proposalValue,
            roomRevenue,
            adr: !!c[values].totalRoomsOffered ? (roomRevenue / c[values].totalRoomsOffered) : null,
            leadSource: getProposalRequestLeadSource({
                eventPlan: {
                    supplierContacts: (c.supplierContactsEventPlan?.length > 0) ? c.supplierContactsEventPlan : c.supplierContacts
                },
                proposalRequest: {
                    ...c
                }
            }), // default will only find comparable true/false
        });
        return a;
    }, {
        count: 0,
        countWithRooms: 0,
        countWithSpace: 0,
        total: 0,
        roomRevenue: 0,
        foodAndBeverage: 0,
        totalRoomsOffered: 0,
        peakRooms: 0,
        peakSpace: 0,
        largestSpace: 0,
        series: [],
        roomCost:0
    });
}