import React, { useContext, useEffect, useState } from 'react';
import {
    ROLE_ADMIN,
    ROLE_ADMIN_HOTEL,
    ROLE_AFFILIATE,
    ROLE_DESTINATIONMANAGER,
    ROLE_HOTELIER,
    ROLE_PLANNER,
    ROLE_SALESOPS,
    ROLE_SUPPLIER,
    UserProfileContext
} from './userProfileContext';

export const NavContext = React.createContext(null);

export const NAV_MODEL_STORAGE_KEY = 'hopskip.nav.model';
export const NAV_MODEL_DEFAULT = 'default';
export const NAV_MODEL_SIDEBAR = 'sidebar';

const globalNavMap = {
    [ROLE_ADMIN]: {
        title: 'Admin',
        navItems: [
            { iconKey: 'promo', path: '/hotelier/promotions', title: 'Promotions' },
            { iconKey: 'search', path: '/sitesearch', title: 'Search' },
            { iconKey: 'newrfp', path: '/event', title: 'Create an RFP' },
            { iconKey: 'board', path: '/planner', title: 'My RFPs', multiRole: true, options: [ { label: 'Planner', path: '/planner' }, { label: 'Hotelier', path: '/hotelier' }, { label: 'NSO/DMO/Affiliate', path: '/supplier' } ] },
            { iconKey: 'building', path: '/hotelprofile', title: 'Property Profile' },
            { iconKey: 'destination', path: '/destinationprofile', title: 'DMO Profile' },
            { iconKey: 'reports', path: '/planner/reports', title: 'Reports & Analytics', multiRole: true, options: [
                { label: 'Planner', path: '/planner/reports' },
                { label: 'Hotelier', path: '/hotelier/reports' },
                { label: 'DMO', path: '/destination/reports' },
                { label: 'Affiliate', path: '/affiliate/reports' }
            ] },
            { iconKey: 'toolbox', path: '/admin', title: 'Admin Tools' }
        ]
    },
    [ROLE_ADMIN_HOTEL]: {
        title: 'Hotel Admin',
        navItems: [
            { iconKey: 'search', path: '/sitesearch', title: 'Search' },
            { iconKey: 'building', path: '/hotelprofile', title: 'Property Profile' },
        ]
    },
    [ROLE_PLANNER]: {
        title: "Planner",
        navItems: [
            { iconKey: 'bell', path: null, title: 'Notifications' },
            { iconKey: 'search', path: '/sitesearch', title: 'Search', featureKey: 'SITESEARCH' },
            { iconKey: 'newrfp', path: '/event', title: 'Create an RFP' },
            { iconKey: 'board', path: '/planner', title: 'My RFPs' },
            { iconKey: 'history', path: '/planner/history', title: 'RFP and Proposal History' },
            { iconKey: 'toolbox', path: '/planner/toolbox', title: 'Toolbox' },
            { iconKey: 'reports', path: '/planner/reports', title: 'Reports & Analytics' },
        ]
    },
    [ROLE_HOTELIER]: {
        title: "Hotelier",
        navItems: [
            { iconKey: 'promo', path: '/hotelier/promotions', title: 'Promotions' },
            { iconKey: 'board', path: '/hotelier', title: 'My RFPs' },
            { iconKey: 'history', path: '/hotelier/history', title: 'Proposal History' },
            { iconKey: 'building', path: '/hotelprofile', title: 'Property Profile' },
            { iconKey: 'reports', path: '/hotelier/reports', title: 'Reports & Analytics' },
        ]
    },
    [ROLE_DESTINATIONMANAGER]: {
        title: "Destination Org",
        navItems: [
            { iconKey: 'board', path: '/supplier', title: 'My RFPs' },
            { iconKey: 'destination', path: '/destinationprofile', title: 'Organization Profile' },
            { iconKey: 'reports', path: '/destination/reports/opportunity', title: 'Reports & Analytics' },
        ]
    },
    [ROLE_AFFILIATE]: {
        title: "Affiliate",
        navItems: [
            { iconKey: 'board', path: '/supplier', title: 'My RFPs' },
            { iconKey: 'history', path: '/supplier/history', title: 'RFP and Proposal History' },
            { iconKey: 'reports', path: '/affiliate/reports', title: 'Reports & Analytics' },
        ]
    },
    [ROLE_SUPPLIER]: {
        title: "Industry Contact",
        navItems: [
            { iconKey: 'board', path: '/supplier', title: 'My RFPs' },
            { iconKey: 'history', path: '/supplier/history', title: 'RFP and Proposal History' },
        ]
    },
    [ROLE_SALESOPS]: {
        title: "Sales Support",
        navItems: [
            { iconKey: 'board', path: '/supplier', title: 'My RFPs' },
            { iconKey: 'history', path: '/supplier/history', title: 'RFP and Proposal History' },
        ]
    }
};

export const NavProvider = ({ children }) => {
    const [currentNavModel, setCurrentNavModel] = useState(NAV_MODEL_SIDEBAR);
    const { userProfile } = useContext(UserProfileContext);
    const [currentRole, setCurrentRole] = useState(null);
    const [currentGlobalNav, setCurrentGlobalNav] = useState({ title: '', navItems: [] });
    const [currentLocalNav, setCurrentLocalNav] = useState({});

    useEffect(() => {
        setCurrentGlobalNav(
            globalNavMap[currentRole || userProfile.role] || { title: '', navItems: [] }
        );
    }, [userProfile.role, currentRole]);

    return (
        <NavContext.Provider value={{
            currentNavModel, setCurrentNavModel,
            availableRoles: userProfile.isAdmin ? Object.keys(globalNavMap).map(k => { return { key: k, title: globalNavMap[k].title }; }) : [userProfile.role],
            currentRole, setCurrentRole,
            currentGlobalNav,
            currentLocalNav, setCurrentLocalNav
        }}>
            {children}
        </NavContext.Provider>
    );
}