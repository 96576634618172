import React from 'react';
import { Button } from 'react-bootstrap';

export const ActionToggle = React.forwardRef(({ children, onClick, disabled }, ref) => (
    <Button
        ref={ref}
        disabled={disabled}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
        variant="outline-primary"
        className="action-button"
    >
        {children}
    </Button>
));