import React, { useEffect, useState } from 'react';
import { callApi } from './apiProvider';

export const CurrencyContext = React.createContext(null);

export const UnitedStatesDollar = { symbol: '$', name: 'United States Dollar', code: 'USD' };
export const DefaultCurrency = UnitedStatesDollar;

const loadCurrencies = () => {
    return callApi(
        `/api/currencies`,
        { method: 'GET' },
        true
    );
}

export const getExchangeRate = (sourceCurrencyCode, targetCurrencyCode) => {
    return callApi(
        `/api/currencies/exchangeRate/${sourceCurrencyCode}/${targetCurrencyCode}`,
        { method: 'GET' },
        true
    );
}

export const CurrencyProvider = ({ children }) => {
    const [currencies, setCurrencies] = useState({
        [UnitedStatesDollar.code]: {...UnitedStatesDollar},
    });

    useEffect(() => {
        loadCurrencies().then(r => {
            setCurrencies(r.data);
        });
    }, []);

    const getCurrency = (currencyCode) => {
        // currencyItem = {
        //     symbol: '',
        //     name: '',
        //     code: '' //(same as currency param)
        // };
        return currencies[currencyCode];
    }

    return (
        <CurrencyContext.Provider value={{ currencies, getCurrency }}>
            {children}
        </CurrencyContext.Provider>
    );
}