import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { copyItemTypes, duplicateActions, EventPlanDuplicateModal } from '../../../components/event-plan-duplicate/event-plan-duplicate-modal';
import { FeatureContext } from '../../../featureProvider';
import './nav-item.scss'

export const CreateRfpNavItem = ({ icon, iconStyle, text, id, disabled }) => {
    const history = useHistory();
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const { getFeatureByKey, promptUserToUpgrade } = useContext(FeatureContext);

    return (
        <div className="nav-item">
            <OverlayTrigger
                placement="right"
                trigger="click"
                rootClose={true}
                rootCloseEvent="click"
                overlay={(
                    <Popover id={text.replace(' ', '-')}>
                        <Popover.Title>Create an RFP</Popover.Title>
                        <Popover.Content>
                            <div>
                                <Button
                                    variant="link"
                                    onClick={() => history.push(`/event`)}
                                    disabled={disabled}
                                >
                                    Create New RFP
                                </Button>
                            </div>
                            <div>
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        if (getFeatureByKey('TEMPLATES')) {
                                            setShowTemplateModal(true);
                                        } else {
                                            promptUserToUpgrade(true, 'TEMPLATES');
                                        }
                                    }}
                                    disabled={disabled}
                                >
                                    Use a Template
                                </Button>
                            </div>
                        </Popover.Content>
                    </Popover>
                )}
            >
                <Button
                    variant="link"
                    title="Create an RFP"
                >
                    <FontAwesomeIcon id={id} icon={icon} style={iconStyle} />
                    <span>{text}</span>
                </Button>
            </OverlayTrigger>
            <EventPlanDuplicateModal
                show={showTemplateModal}
                duplicateAction={duplicateActions.createRfpFromTemplate}
                createItemType={copyItemTypes.eventPlan}
                template={null}
                onClose={() => setShowTemplateModal(false)}
            />
        </div>
    );
}