import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './nav-item.scss'

export const MultiRoleNavItem = ({ icon, iconStyle, text, id, options, disabled }) => {
    return (
        <div className="nav-item">
            <OverlayTrigger
                placement="right"
                trigger="click"
                rootClose={true}
                rootCloseEvent="click"
                overlay={(
                    <Popover id={text.replace(' ', '-')}>
                        <Popover.Title>{text}</Popover.Title>
                        <Popover.Content>
                            {options.map((option, index) => (
                                <div
                                    key={index}
                                    style={{ paddingLeft: '0.5rem', marginBottom: '0.5rem', fontSize: '1rem' }}
                                >
                                    <Link
                                        to={option.path}
                                    >
                                        {option.label}
                                    </Link>
                                </div>
                            ))}
                        </Popover.Content>
                    </Popover>
                )}
            >
                <Button
                    variant="link"
                    title={text}
                >
                    <FontAwesomeIcon id={id} icon={icon} style={iconStyle} />
                    <span>{text}</span>
                </Button>
            </OverlayTrigger>
        </div>
    );
}