import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { formatNumber } from '../../common';
import { ReportBoardCol, ReportBoardRow } from './report-board';
import './stacked-bar.scss';

const defaultLegendGroupSize = 4;

export const StackedBar = props => {
    const { data, valueKey, labelKey, colorKey, formattedValueKey, hideOverlay, hideLegend, legendGroupSize, suppressZeroValues, hideValueInLegend } = props;
    const [dataSummary, setDataSummary] = useState({
        total: 0,
        leftMost: 0,
        rightMost: data.length - 1,
        barData: [],
        legendGroups: [],
    });

    useEffect(() => {
        const barData = data.filter(d => {
            if (d.hide) {
                return false;
            }
            if (suppressZeroValues && d.value === 0) {
                return false;
            }
            return true;
        }).map((d,i) => {
            return {
                ...d,
                index: i,
                value: d[valueKey || 'value'] * 1.0,
                color: d[colorKey || 'color'],
                label: d[labelKey || 'label'],
                formattedValue: d[formattedValueKey || 'formattedValue'],
            };
        });
        const legendGroups = barData.sort((c,n) => c.index - n.index).reduce((a,c,i) => {
            a.currentGroupIndex += (i % (legendGroupSize ?? defaultLegendGroupSize) === 0) ? 1 : 0;
            if (a.data.length > a.currentGroupIndex) {
                a.data[a.currentGroupIndex].data.push(c);
            } else {
                a.data.push({
                    index: a.currentGroupIndex,
                    data: [c]
                });
            }
            return a;
        }, { currentGroupIndex: -1, data: [] }).data;
        setDataSummary({
            total: barData.reduce((a, c) => a + c.value, 0),
            leftMost: (barData.find(d => d.value > 0) || { index: 0 }).index,
            rightMost: (barData.sort((c,n) => n.index - c.index).find(d => d.value) || { index: barData.filter(d => d.value).length - 1 }).index,
            barData,
            legendGroups,
        });
    }, [data, valueKey, labelKey, colorKey, formattedValueKey, legendGroupSize, suppressZeroValues]);

    return (
        <div className='stacked-bar-container' style={props.style}>
            <div className='bar-container'>
                {(dataSummary.barData.every(d => !d.value) && (
                    <div className='bar empty'>
                        No Data
                    </div>
                )) || (dataSummary.barData.filter(d => Math.floor(d.value ?? 0) > 0).sort((c,n) => c.index - n.index).map(d => {
                    const width = Math.max(0, Math.floor(dataSummary.total ? (d.value / dataSummary.total * 100) : (1.0 / dataSummary.barData.length * 100)) - 1);
                    var borderRadii = {};
                    if (d.index === dataSummary.leftMost && d.index === dataSummary.rightMost) {
                        borderRadii = { borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem', borderTopRightRadius: '.5rem', borderBottomRightRadius: '.5rem' };
                    } else if (d.index === dataSummary.leftMost) {
                        borderRadii = { borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem' };
                    } else if (d.index === dataSummary.rightMost) {
                        borderRadii = { borderTopRightRadius: '.5rem', borderBottomRightRadius: '.5rem' };
                    }
                    if (hideOverlay) {
                        return (
                            <div
                                key={d.index}
                                className='bar'
                                style={{
                                    width: `${width}%`,
                                    backgroundColor: d.color,
                                    marginBottom: '.5rem',
                                    ...borderRadii
                                }}
                            >
                                &nbsp;
                            </div>
                        );
                    }
                    return (
                        <OverlayTrigger
                            key={d.index}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Popover>
                                    <Popover.Content>
                                        {d.label}
                                        <br/>
                                        {d.formattedValue}{dataSummary.total ? ` (${formatNumber((d.value * 1.0) / dataSummary.total * 100)}%)` : ''}
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <div
                                className='bar'
                                style={{
                                    width: `${width}%`,
                                    backgroundColor: d.color,
                                    marginBottom: '.5rem',
                                    ...borderRadii
                                }}
                            >
                                &nbsp;
                            </div>
                        </OverlayTrigger>
                    );
                }))}
            </div>
            {!hideLegend && (
                <>
                    {dataSummary.legendGroups.map(g => (
                        <ReportBoardRow key={g.index}>
                            {(new Array(legendGroupSize ?? defaultLegendGroupSize)).fill(0).map((a, i) => {
                                const d = g.data[i];
                                if (!d) {
                                    return (<ReportBoardCol key={i}></ReportBoardCol>);
                                }
                                return (
                                    <ReportBoardCol key={i}>
                                        <div
                                            className='bar legend-key'
                                            style={{
                                                marginRight: '.25rem',
                                                width: '1rem',
                                                backgroundColor: d.color
                                            }}
                                        >
                                            &nbsp;
                                        </div>
                                        <span className='bar-label'>{d.label}</span>
                                        <span className='bar-percentage'>{dataSummary.total ? ` (${formatNumber((d.value * 1.0) / dataSummary.total * 100)}%)` : ''}</span>
                                        <br/>
                                        <div
                                            className='bar legend-key'
                                            style={{
                                                marginRight: '.25rem',
                                                width: '1rem'
                                            }}
                                        >
                                            &nbsp;
                                        </div>
                                        {!hideValueInLegend ? d.formattedValue : ''}
                                    </ReportBoardCol>
                                );
                            })}
                        </ReportBoardRow>
                    ))}
                </>
            )}
        </div>
    );
}