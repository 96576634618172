import React from 'react';
import WhatsThis from '../whats-this';

export const Metric = props => {
    return (
        <div className='report-card-metric-container'>
            <div className={`report-card-metric${props.variant ? ` ${props.variant}` : ''}${props.size ? ` ${props.size}` : ''}`}>
                {props.value}
                {props.valueHint && (
                    <span className={`report-card-metric-hint${props.hintSize ? ` ${props.hintSize}` : ''}`}>
                        {props.valueHint}
                    </span>
                )}
            </div>
            {props.label && (
                <div className='report-card-metric-label'>
                    {props.label}
                    {props.infoText ? (
                        <WhatsThis content={props.infoText} verticalAlign='middle' />
                    ) : null}
                </div>
            )}
            {props.subtext && (
                <div className='report-card-metric-subtext'>
                    {props.subtext}
                </div>
            )}
        </div>
    );
}