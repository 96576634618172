import React from 'react'
import { Spinner } from 'react-bootstrap';

const Loader = () => {
    return (
        <div style={{
                display: 'block',
                textAlign: 'center',
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: 9999,
                cursor: 'pointer'
            }}>
            <Spinner
                style={{
                    marginTop: '20rem',
                    width: '4rem',
                    height: '4rem',
                    fontSize: '1rem',
                }}
                variant="success"
                animation="border"
            />
        </div>
    );
}
export default Loader;