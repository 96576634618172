export const accountTypeOptions = [
    {
        value: 'meplanner',
        name: 'Meeting & Event Planning Company',
        description: 'My organization plans meetings and events for clients',
        commissionEligible: true
    },
    {
        value: 'association',
        name: 'Association / Non-Profit',
        description: 'My organization is an association or non-profit',
        commissionEligible: true
    },
    {
        value: 'assocmgmt',
        name: 'Association Management'
    },
    {
        value: 'proservices',
        name: 'Professional Services'
    },
    {
        value: 'midmarket',
        name: 'Midmarket Company'
    },
    {
        value: 'enterprise',
        description: 'My organization provides a product or service unrelated to meetings and events',
        name: 'Enterprise'
    },
    {
        value: 'education',
        name: 'Education'
    },
];