import React, { useContext } from 'react';
import { UserProfileContext } from '../../userProfileContext';
import { LogoutButton } from '../auth/logout-button';
import './disabled.scss';

export const ProfileDisabled = () => {
    const { userProfile } = useContext(UserProfileContext);

    return (
        <div className='disabled-container'>
            <div className='disabled-content'>
                <div className='title'>
                    Your account has been disabled.
                </div>
                <div className='copy'>
                    Please <a href={`mailto:info@myhopskip.com?subject=Disabled account for ${userProfile.id}`}>contact us</a> if you would like us to re-enable your account.
                </div>
                {userProfile.disabledReason && (
                    <div className='reason'>
                        {userProfile.disabledReason}
                    </div>
                )}
                <div className='cta'>
                    <LogoutButton variant='primary' />
                </div>
            </div>
        </div>
    );
}