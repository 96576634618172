import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import { FeatureContext } from '../../featureProvider';
import { NavContext, NAV_MODEL_SIDEBAR } from '../../navProvider';
import { ROLE_PLANNER, UserProfileContext } from '../../userProfileContext';

import { MyMessages } from '../../components/chat/my-messages';
import ActionsList from '../../components/event/summary/actions-list';
import { EventsContainer } from '../../components/event/summary/events-container';
import { copyItemTypes, duplicateActions, EventPlanDuplicateModal } from '../../components/event-plan-duplicate/event-plan-duplicate-modal';
import { PreviewExperienceSwitcher } from '../../components/preview-experiences/switcher';
import { previewExperiences } from '../../common/preview-experiences';
import { PlannerDashboard } from '../../components/planners/dashboard';

const PlannerPortal = () => {
    const history = useHistory();
    const { userProfile } = useContext(UserProfileContext);
    const [displayPreviewSwitcher, setDisplayPreviewSwitcher] = useState(false);
    const { currentNavModel } = useContext(NavContext);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [duplicateContext, setDuplicateContext] = useState({ eventPlan: null, duplicateAction: null, createItemType: null });
    const { getFeatureByKey, promptUserToUpgrade } = useContext(FeatureContext);

    useEffect(() => {
        setDisplayPreviewSwitcher((userProfile.isAdmin || userProfile.previewExperiences?.DASHBOARDPREVIEW));
    }, [userProfile.isAdmin, userProfile.previewExperiences]);

    const onCopyEvent = (event) => {
        setDuplicateContext({ eventPlan: event, duplicateAction: duplicateActions.duplicateRfp, createItemType: copyItemTypes.eventPlanTemplate });
        setShowDuplicateModal(true);
    }

    return (
        <Fragment>
            {displayPreviewSwitcher ? (
                <PreviewExperienceSwitcher
                    previewExperienceKey={previewExperiences.DASHBOARD.key}
                    enabled={userProfile.previewExperiences?.DASHBOARD}
                />
            ) : null}
            <Row style={{ width: 'calc(100% - 1px)' }}>
                <Col>
                    <div style={{ padding: '1rem 0 0 1rem', fontSize: 'large', fontWeight: 'bold' }}>
                        <span 
                            data-touranchorelement='dashboardTitle'
                        >
                            My RFP Dashboard
                        </span>
                    </div>
                </Col>
                {currentNavModel === NAV_MODEL_SIDEBAR && (
                    <Col style={{ paddingRight: '2rem', textAlign: 'end'}}>
                        <Dropdown
                            alignRight
                        >
                            <Dropdown.Toggle
                                variant="primary"
                                disabled={(userProfile.role === ROLE_PLANNER && !userProfile.accountType)}
                            >
                                <FontAwesomeIcon icon={faPlusCircle} />
                                {' '}Create RFP
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => history.push('/event')}>Create New</Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        if (getFeatureByKey('TEMPLATES')) {
                                            setDuplicateContext({ eventPlan: null, duplicateAction: duplicateActions.createRfpFromTemplate, createItemType: copyItemTypes.eventPlan });
                                            setShowDuplicateModal(true);
                                        } else {
                                            promptUserToUpgrade(true, 'TEMPLATES');
                                        }
                                    }}
                                >
                                    Use a Template
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                )}
            </Row>
            {userProfile.previewExperiences?.DASHBOARD ? (
                <PlannerDashboard
                    onCopyEvent={onCopyEvent}
                />
            ) : (
                <>
                    <Row style={{ width: 'calc(100% - 1px)' }}>
                        <Col style={{ padding: '1rem 2rem 1rem 2rem' }}>
                            <ActionsList />
                        </Col>
                        <Col style={{ padding: '1rem 2rem 1rem 2rem' }}>
                            <MyMessages />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '1rem', width: 'calc(100% - 25px)' }}>
                        <Col>
                            <EventsContainer
                                onCopyEvent={onCopyEvent}
                            />
                        </Col>
                    </Row>
                </>
            )}
            {setShowDuplicateModal && (
                <EventPlanDuplicateModal
                    show={showDuplicateModal}
                    duplicateAction={duplicateContext.duplicateAction}
                    createItemType={duplicateContext.createItemType}
                    template={duplicateContext.eventPlan}
                    onClose={() => { setShowDuplicateModal(false); setDuplicateContext({ eventPlan: null, duplicateAction: null, createItemType: null }); }}
                />
            )}
        </Fragment>
    );
};

export default PlannerPortal;