import React, { useEffect, useState } from 'react';
import { callApi } from './apiProvider';
import { UserProfileContext } from './userProfileContext';
import { getVenueLists, useVenueLists } from './services/organizations';

export const UserProfileStatus = {
    initial: 'initial',
    anonymous: 'anonymous',
    loading: 'loading',
    loaded: 'loaded',
};

export const UserProfileProvider = ({ children }) => {
    const [userProfileStatus, setUserProfileStatus] = useState(UserProfileStatus.initial);
    const [userProfile, setUserProfile] = useState({});
    const [venueLists, setVenueLists] = useVenueLists(userProfile.organizationId);

    useEffect(() => {
        if (!!userProfile.organizationId) {
            setVenueLists(getVenueLists(userProfile.organizationId));
        }
    }, [userProfile.organizationId, setVenueLists]);

    const updatePreviewExperience = ({ userProfileId, previewExperienceKey, enable }) => {
        callApi(
            `/api/userprofiles/${userProfileId ?? userProfile.id}/previewexperiences/${previewExperienceKey}/${enable ? 'true' : 'false'}`,
            {
                method: 'PUT'
            },
            true
        );
    }

    return (
        <UserProfileContext.Provider value={{ userProfile, setUserProfile, userProfileStatus, setUserProfileStatus, updatePreviewExperience, venueLists, setVenueLists }}>
            {children}
        </UserProfileContext.Provider>
    );
}