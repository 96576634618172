import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import TabStatus from '../../../components/tab-status';
import { NavContext } from '../../../navProvider';
import { ROLE_HOTELIER, ROLE_PLANNER, UserProfileContext } from '../../../userProfileContext';
import './local-navbar.scss';

export const LocalNavContainer = () => {
    const { userProfile } = useContext(UserProfileContext);
    const { currentLocalNav } = useContext(NavContext);
    const location = useLocation();
    const [navBarOpen, setNavBarOpen] = useState(false);
    const [groupState, setGroupState] = useState({});

    useEffect(() => {
        if (currentLocalNav.navGroups) {
            setGroupState(currentLocalNav.navGroups.reduce((a, g) => {
                a[g.key] = g.open;
                return a;
            }, {}))
        }
    }, [currentLocalNav.navGroups]);

    const toggleNavBar = () => {
        setNavBarOpen(s => !s);
    }

    const toggleGroup = (navGroup) => {
        setGroupState(s => {
            return {
                ...s,
                [navGroup.key]: !s[navGroup.key]
            };
        });
    }

    if (!currentLocalNav.show) {
        return null;
    }

    return (
        <div id='local-navbar-container' className={`${navBarOpen ? ' open' : ''}`}>
            <div className="nav-bar-welcome">
                {(currentLocalNav.title && (
                    <Link to={currentLocalNav.path}>{currentLocalNav.title}</Link>
                )) || (userProfile.role === ROLE_PLANNER && (
                    <Link to={`/organization/${userProfile.organizationId}`}>{userProfile.organizationName}</Link>
                )) || (userProfile.role === ROLE_HOTELIER && userProfile.associatedVenues.length > 0 && (
                    <Link to={`/hotelprofile/${userProfile.associatedVenues[0].id}`}>{userProfile.associatedVenues[0].name}</Link>
                )) || (
                    <div>{`Hi, ${userProfile.firstName}`}</div>
                )}
                <FontAwesomeIcon
                    icon={navBarOpen ? faChevronDown : faChevronUp}
                    onClick={toggleNavBar}
                />
            </div>
            <div className='nav-bar-groups'>
                {currentLocalNav.navGroups.filter(g => !g.hide).map((navGroup, g) => (
                    <div key={g}>
                        {(navGroup.title && (
                            <div
                                className="nav-bar-group-title"
                                onClick={() => toggleGroup(navGroup)}
                            >
                                <FontAwesomeIcon
                                    icon={groupState[navGroup.key] ? faChevronDown : faChevronUp }
                                />
                                {navGroup.title}
                            </div>
                        )) || (
                            <div className="nav-bar-group-title"></div>
                        )}
                        <div className={`nav-bar-group ${groupState[navGroup.key] ? 'open' : 'closed'}`}>
                            {navGroup.navItems.filter(i => !i.hide).map((navItem, i) => (
                                <div key={i} className={`nav-bar-item ${location.pathname === navItem.path ? 'active' : ''}`}>
                                    {navItem.badge !== undefined && (
                                        <Badge variant={navItem.badgeVariant || 'primary'} pill>{navItem.badge}</Badge>
                                    )}
                                    {navItem.errors !== undefined && (
                                        <TabStatus errors={navItem.errors} />
                                    )}
                                    <div className="link-container">
                                        <Link to={navItem.path}>{navItem.title}</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}