import { callApi } from "../apiProvider"

export const hotelDefaults = {
    platformSuggestion: {
        withinMiles: 10
    }
}

export const externalContentLinkTypes = {
    website: { key: 'website', label: 'Website', description: 'Website', },
    floorPlan: { key: 'floorPlan', label: 'Floor Plan', description: 'Floor Plan', },
    virtualTour: { key: 'virtualTour', label: 'Virtual Tour', description: 'Virtual Tour Content', },
};

export const getExternalContentLink = ({ hotel, externalContentLinkTypeKey }) => {
    return hotel.externalContentLinks?.find(l => l.type === externalContentLinkTypeKey);
};

export const getHotels = ({ filter, top, skip, orderBy }) => {
    return callApi(
        `/selling/api/venues?$filter=${filter}&$offset=${skip}&$limit=${top}&$orderby=${orderBy.field}+${orderBy.direction < 0 ? 'desc' : 'asc'}`,
        {
            method: 'GET',
        },
        true
    );
}

// find hotels who have this hotel in their comp set
// listType = 'venue' | 'eventPlan'
// listId = '{venueId}' | '{eventPlanId}'
export const getComparableHotels = ({ listType, listId }) => {
    return callApi(
        `/selling/api/comparablevenues/${listType}/${listId}`,
        {
            method: 'GET',
        },
        true
    );
}

export const getHotel = id => {
    return callApi(
        `/selling/api/venues/${id}`,
        { method: 'GET' },
        true
    );
};

export const scoreHotelProfile = id => {
    return callApi(
        `/selling/api/venues/${id}/scoreprofile`,
        { method: 'PATCH' },
        true
    );
}

export const getImages = (hotelId, { branding, profile }) => {
    const qs = `?branding=${branding ? 'true' : 'false'}&profile=${profile ? 'true' : 'false'}`;
    return callApi(
        `/selling/api/venues/images/${hotelId}${qs}`,
        { method: 'GET' }
    );
}

export const getMeetingRooms = (hotelId) => {
    return callApi(
        `/selling/api/hub/venues/${hotelId}/meetingrooms`,
        {
            method: 'GET'
        },
        true
    );
}

export const getAdditionalTaxesAndFees = (hotelId) => {
    return callApi(
        `/selling/api/venues/${hotelId}/additionalTaxesAndFees`,
        { method: 'GET' },
        true
    );
}

export const addAdditionalTaxesAndFees = (hotelId, additionalTax) => {
    return callApi(
        `/selling/api/venues/${hotelId}/additionalTaxesAndFees`,
        {
            method: 'POST',
            data: {
                additionalTax
            }
        },
        true
    );
}

export const updateAdditionalTaxesAndFees = (hotelId, additionalTaxesAndFees) => {
    return callApi(
        `/selling/api/venues/${hotelId}/additionalTaxesAndFees`,
        {
            method: 'PUT',
            data: { additionalTaxesAndFees }
        },
        true
    );
}

export const queryProposalRequests = (hotelId, filterQuery, dateFilter) => {
    const qs = filterQuery && dateFilter
        ? `?${filterQuery}&${dateFilter}`
        : filterQuery
            ? `?${filterQuery}`
            : dateFilter
                ? `?${dateFilter}`
                : '';
    return callApi(
        `/alx/api/hotels/${hotelId}/proposalRequests/query${qs}`,
        {
            method: 'GET'
        },
        true
    );
}

export const queryCompetingHotels = (hotelId, dateFilter, competingHotelIds) => {
    const queryParams = [
        dateFilter,
        competingHotelIds && competingHotelIds.length > 0 ? `cids=${competingHotelIds.join(',')}` : ''
    ];
    let qs = '';
    if (queryParams.some(s => s)) {
        qs = `?${queryParams.join('&')}`
    }
    return callApi(
        `/alx/api/hotels/${hotelId}/competingHotels/query${qs}`,
        {
            method: 'GET'
        },
        true
    );
}

export const findHotelsInSearchIndex = venueIds => {
    return callApi(
        `/search/indexes/venue-index/docs?api-version=2020-06-30&$select=id,name,address,city,state,guestRoomQuantity,meetingRoomQuantity,meetingSpaceSquareFeet,largestMeetingSpaceSquareFeet&$filter=search.in(id, '${venueIds.join(' ')}')&$top=${venueIds.length}`,
        {
            method: 'GET'
        },
        false
    );
}

export const getGooglePlaces = (hotelId) => {
    return callApi(
        `/selling/api/venues/places/${hotelId}`,
        { method: 'GET' },
        true
    );
}

export const getHoteliers = hotelId => {
    return callApi(
        `/selling/api/hub/venues/${hotelId}/hoteliers`,
        { method: 'GET' },
        true
    );
};

export const addHotelier = (hotelId, hotelier, userProfile, options) => {
    return callApi(
        `/selling/api/hub/venues/${hotelId}/hoteliers`,
        {
            method: 'POST',
            data: {
                hotelier,
                userProfile,
                options
            }
        },
        true
    );
};

export const updateHotelier = (hotelId, hotelier, userProfile) => {
    return callApi(
        `/selling/api/hub/venues/${hotelId}/hoteliers/${hotelier.id}`,
        {
            method: 'PUT',
            data: {
                hotelier,
                userProfile
            }
        },
        true
    );
};

export const deleteHotelier = (hotelId, hotelierId) => {
    return callApi(
        `/selling/api/hub/venues/${hotelId}/hoteliers/${hotelierId}`,
        { method: 'DELETE' },
        true
    );
};

export const createHotelFromGooglePlace = (placeId) => {
    return callApi(
        `/selling/api/venues/create-from-place-id`,
        {
            method: 'POST',
            data: {
                placeId: placeId,
            },
        },
        true
    );
}