import React, { useContext, useEffect, useState } from 'react';
import { ROLE_ADMIN, ROLE_ADMIN_HOTEL, ROLE_HOTELIER, ROLE_PLANNER, UserProfileContext } from '../../userProfileContext';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { callApi } from '../../apiProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faPlusCircle, faUserCircle, faUsersMedical } from '@fortawesome/pro-light-svg-icons';
import './invitation-modal.scss';
import { isEmail } from 'react-multi-email';
import { NotificationManager } from 'react-notifications';
import { faUserNinja, faUserShield } from '@fortawesome/pro-duotone-svg-icons';

const invitationOptions = {
    Admin: [
        {
            title: 'Invite another Admin user to HopSkip',
            icon: faUserNinja,
            text: 'Admin accounts have access to all features and data',
            toRole: ROLE_ADMIN,
            targetOrganization: true,
        },
        {
            title: 'Invite a Hotel Admin user to HopSkip',
            icon: faUserShield,
            text: 'Hotel Admin accounts have access to add and update all property profiles',
            toRole: ROLE_ADMIN_HOTEL,
            targetOrganization: true,
        },
    ],
    Planner: [
        {
            title: 'Invite your co-workers to join your company account',
            icon: faUsersMedical,
            text: 'Share an invite to one of your team members to create a HopSkip account and automatically join your team',
            toRole: ROLE_PLANNER,
            targetOrganization: true,
        },
        {
            title: 'Invite another planner outside of your company',
            icon: faUserCircle,
            text: 'Share an invite with one of the planners in your network',
            toRole: ROLE_PLANNER,
        },
        {
            title: 'Invite a hotelier to access their hotels RFPs on HopSkip',
            icon: faBuilding,
            text: 'Share an invite with a hotelier who reviews RFPs and submits proposals for a hotel property',
            toRole: ROLE_HOTELIER,
        },
    ],
    Hotelier: [
        {
            title: 'Invite your co-workers to join your hotel sales team on HopSkip',
            icon: faUsersMedical,
            text: 'Share an invite to one of your team members to create a HopSkip account and automatically join your sales team',
            toRole: ROLE_HOTELIER,
            targetVenue: true,
        },
        {
            title: 'Invite a planner to send you RFPs',
            icon: faUserCircle,
            text: 'Share an invite with one of the planners in your network so they can send you RFPs using HopSkip',
            toRole: 'Planner',
        },
        {
            title: 'Invite another hotel to join HopSkip and start receiving RFPs',
            icon: faBuilding,
            text: 'Share an invite with another hotel so they can join the HopSkip platform and start receiving RFPs from HopSkip planners',
            toRole: 'Hotelier',
        },
    ]
}

export const InvitationModal = ({ show, onClose }) => {
    const { userProfile } = useContext(UserProfileContext);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [invitations, setInvitations] = useState([]);
    const [invitationResults, setInvitationResults] = useState({});

    useEffect(() => {
        if (userProfile.role && Object.keys(invitationOptions).includes(userProfile.role)) {
            setOptions([
                ...invitationOptions[userProfile.role]
            ]);
        }
    }, [userProfile.role]);

    const selectOption = option => {
        setSelectedOption(option);
        setInvitations([
            { firstName: '', lastName: '', email: '' },
            { firstName: '', lastName: '', email: '' },
            { firstName: '', lastName: '', email: '' },
        ]);
        setInvitationResults({});
    }

    const onHide = () => {
        onClose();
        setSelectedOption(null);
        setInvitations([]);
        setInvitationResults({});
    }

    const updateInvitation = (index, name, value) => {
        setInvitations(s => {
            return [
                ...s.slice(0, index),
                {
                    ...s[index],
                    [name]: value
                },
                ...s.slice(index + 1)
            ];
        })
    }

    const onSendInvite = () => {
        callApi(
            `/api/userinvitations`,
            {
                method: 'POST',
                data: invitations.map((invitation) => {
                    return {
                        from: {
                            firstName: userProfile.firstName,
                            lastName: userProfile.lastName,
                            id: userProfile.id,
                            role: userProfile.role,
                            organizationId: userProfile.organizationId,
                            organizationName: userProfile.organizationName
                        },
                        to: {
                            firstName: invitation.firstName,
                            lastName: invitation.lastName,
                            id: invitation.email,
                            role: selectedOption.toRole
                        },
                        targetOrganization: selectedOption.targetOrganization
                            ? { id: userProfile.organizationId, name: userProfile.organizationName }
                            : null,
                        targetVenue: selectedOption.targetVenue
                            ? { id: userProfile.associatedVenues[0]?.id, name: userProfile.associatedVenues[0]?.name }
                            : null,
                    }
                })
            },
            true
        ).then(r => {
            if (Object.keys(r.data).length > 0) {
                setInvitationResults(r.data);
            } else {
                NotificationManager.success('Invitation(s) sent!');
                onHide();
            }
        });
    }

    return (
        <Modal show={show} onHide={onHide} dialogClassName="modal-50w" className="invitation-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {selectedOption?.title || 'Invite your contacts to experience the benefits of using HopSkip!'}
                    <Form.Text>{selectedOption?.text || 'Click an option below to share HopSkip:'}</Form.Text>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedOption ? (
                    <div className="invitations">
                        <Row>
                            <Col>
                                <Form.Label>First Name</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label>Last Name</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label>Email</Form.Label>
                            </Col>
                            <Col sm={1}>

                            </Col>
                        </Row>
                        {invitations.map((invitation, index) => (
                            <Row
                                key={index}
                            >
                                <Col>
                                    <Form.Control
                                        value={invitation.firstName || ''}
                                        onChange={e => updateInvitation(index, 'firstName', e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        value={invitation.lastName || ''}
                                        onChange={e => updateInvitation(index, 'lastName', e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="email"
                                        value={invitation.email || ''}
                                        onChange={e => updateInvitation(index, 'email', e.target.value.toLowerCase())}
                                        isInvalid={invitation.email && !isEmail(invitation.email)}
                                    />
                                    <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                                    {invitationResults[invitation.email] && (
                                        <Form.Text style={{ color: '#F86866' }}>{invitationResults[invitation.email]}</Form.Text>
                                    )}
                                </Col>
                                <Col sm={1}>
                                    {(index === invitations.length - 1) && (
                                        <FontAwesomeIcon
                                            icon={faPlusCircle}
                                            style={{ cursor: 'pointer', color: '#027587' }}
                                            onClick={() => setInvitations(s => [ ...s, { firstName: '', lastName: '', email: '' } ])}
                                        />
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>
                ) : options.map((option, index) => (
                    <div
                        key={index}
                        className="invitation-option"
                        onClick={() => selectOption(option)}
                    >
                        <Row>
                            <Col sm={1}>
                                <FontAwesomeIcon icon={option.icon} />
                            </Col>
                            <Col>
                                <div className="option-title">
                                    {option.title}
                                </div>
                                <div className="option-text">
                                    {option.text}
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))}
            </Modal.Body>
            {selectedOption && (
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={onSendInvite}
                        disabled={invitations.every(i => !i.firstName || !i.lastName || !i.email || !isEmail(i.email))}
                    >
                        Send Invitation(s)
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
}