import { faComment } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, ListGroup, Row, Col, Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { ChatContext } from '../../chatContext';
import { FeatureContext } from '../../featureProvider';
import './my-messages.scss';

const getLinkToMessage = ({ eventPlanId, venueId, location }) => {
    if (location.pathname.endsWith('hotelier')) {
        return `/proposal/${eventPlanId}/${venueId}/messaging`;
    } else if (location.pathname.includes('/supplier')) {
        return `/supplier/request/${eventPlanId}/messaging`;
    } else {
        return `/event/${eventPlanId}/messaging/${venueId}`;
    }
}

export const MyMessages = () => {
    const location = useLocation();
    const { chatClient, conversations, unreadConversations } = useContext(ChatContext);
    const [unreadConversationCount, setUnreadConversationCount] = useState(0);
    const { getFeatureByKey, promptUserToUpgrade } = useContext(FeatureContext);
    const chatFeature = getFeatureByKey('CHAT');

    useEffect(() => {
        setUnreadConversationCount(unreadConversations.filter(uc => uc.unreadMessagesCount > 0).length);
    }, [unreadConversations]);

    const onClickMarkAsRead = (conversationUniqueName) => {
        chatClient.getConversationByUniqueName(conversationUniqueName)
        .then(conversation => {
            conversation?.setAllMessagesRead();
        });
    }

    const sortConversations = (c,n) => {
        if (c.lastMessage?.dateCreated && n.lastMessage?.dateCreated) {
            if (c.lastMessage.dateCreated === n.lastMessage.dateCreated) {
                return c.uniqueName > n.uniqueName ? 1 : -1;
            }
        } else if (c.lastMessage?.dateCreated) {
            return 1;
        } else {
            return -1;
        }
        return c.lastMessage?.dateCreated > n.lastMessage?.dateCreated ? 1 : -1;
    }

    return (
        <div id="my-messages-container">
            {unreadConversationCount > 0 && (
                <Badge variant="danger" pill className="counter-badge">{unreadConversationCount}</Badge>
            )}
            <div
                className="title"
                data-touranchorelement='myMessagesContainer'
                data-touranchorposition="left"
            >
                <FontAwesomeIcon icon={faComment} />
                Messages
            </div>
            <div className="list-container">
                <ListGroup className="messages-list">
                    <ListGroup.Item className="message-header">
                        <Row style={{ alignItems: 'center' }}>
                            <Col sm={3}>
                                From
                            </Col>
                            <Col sm={3}>
                                Received
                            </Col>
                            <Col sm={4}>
                                RFP
                            </Col>
                            <Col sm={2}>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {(chatFeature === undefined && (
                        <ListGroup.Item className="message-item">
                            <Button
                                variant="outline-primary"
                                onClick={() => promptUserToUpgrade(true, 'CHAT')}
                            >
                                Upgrade to use Messaging
                            </Button>
                        </ListGroup.Item>
                    )) || (unreadConversationCount > 0 && conversations.sort(sortConversations).map((conversation, i) => {
                        const unreadConversation = unreadConversations.find(uc => uc.uniqueName === conversation.uniqueName);
                        if (!unreadConversation) {
                            return null;
                        }
                        if (!unreadConversation.unreadMessagesCount || !unreadConversation.lastMessage) {
                            return null;
                        }
                        const nameParts = conversation.friendlyName.split('|');
                        const received = unreadConversation.lastMessage.dateCreated;
                        let from = nameParts[1];
                        if (location.pathname.endsWith('hotelier') || location.pathname.includes('/supplier')) {
                            from = unreadConversation.lastMessage.author?.split('@')[0];
                        }
                        const uniqueNameParts = conversation.uniqueName.split('|');
                        const eventPlanId = uniqueNameParts[0];
                        const venueId = uniqueNameParts[1];
                        return (
                            <ListGroup.Item key={`mc-${i}`} className="message-item">
                                <Row style={{ alignItems: 'center' }}>
                                    <Col sm={3}>
                                        <Link to={getLinkToMessage({ eventPlanId, venueId, location })}>
                                            {from}
                                        </Link>
                                    </Col>
                                    <Col sm={3}>
                                        {format(received, "M/d/yy' at 'h:mma")}
                                    </Col>
                                    <Col sm={4}>
                                        {nameParts[0]}
                                    </Col>
                                    <Col sm={2}>
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => onClickMarkAsRead(conversation.uniqueName)}
                                        >
                                            Mark Read
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        );
                    })) || (
                        <ListGroup.Item className="message-item">
                            No unread messages
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </div>
        </div>
    );
}