import { callApi } from "../apiProvider"

export const excelContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const excelFormatStrings = {
    numberNoDecimals: '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)',
    currency: '$',
    date:  'mm/dd/yyyy',
    percentageNoDecimals: '0%',
    hyperlink: 'hyperlink', //url|friendly name
    url: 'url' //url only
}

export const getWorkbook = ({ workbookId, options }) => {
    return callApi(
        `/rpt/api/workbooks/${workbookId}`,
        {
            method: 'GET',
            ...options
        },
        true
    );
}

export const packageWorkbook = ({ workbookId, packageData, callback }) => {
    callApi(
        `/rpt/api/workbooks/${workbookId}/package`,
        {
            method: 'POST',
            responseType: 'blob',
            data: {...packageData}
        },
        true
    ).then(r => {
        let fileName = `${packageData.workbookName}.xlsx`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
            window.navigator.msSaveOrOpenBlob(new Blob([r.data], { type: excelContentType }), fileName);
        } else {
            const url = window.URL.createObjectURL(new Blob([r.data], { type: excelContentType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
        }
        if (typeof(callback) === 'function') {
            callback();
        }
    });
}

export const exportCollection = ({ collection, itemType, callback }) => {
    callApi(
        `/rpt/api/export/${collection}/${itemType}`,
        {
            method: 'get',
            responseType: 'blob'
        },
        true
    ).then(r => {
        let fileName = `${collection}-${itemType}.xlsx`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
            window.navigator.msSaveOrOpenBlob(new Blob([r.data], { type: excelContentType }), fileName);
        } else {
            const url = window.URL.createObjectURL(new Blob([r.data], { type: excelContentType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
        }
        if (typeof(callback) === 'function') {
            callback();
        }
    });
}