import { useContext, useEffect, useState } from "react"
import { Alert, Spinner } from "react-bootstrap";
import { previewExperiences } from "../../common/preview-experiences";
import { UserProfileContext } from "../../userProfileContext";

export const PreviewExperienceSwitcher = ({ previewExperienceKey, enabled }) => {
    const { updatePreviewExperience } = useContext(UserProfileContext);
    const [alertState, setAlertState] = useState({ intro: '', loading: false });

    useEffect(() => {
        const previewExperience = previewExperiences[previewExperienceKey];
        setAlertState({
            intro: enabled ? previewExperience.alert.enabled.intro : previewExperience.alert.disabled.intro,
            loading: false
        });
    }, [previewExperienceKey, enabled]);

    return (
        <Alert
            id={`preview-experience-switcher-${previewExperienceKey}`}
            variant='warning'
        >
            {alertState.intro}
            {' '}
            <Alert.Link
                href="#"
                onClick={() => {
                    setAlertState(s => { return { ...s, loading: true }; });
                    updatePreviewExperience({ previewExperienceKey, enable: !enabled });
                }}
                disabled={alertState.loading}
            >
                Click here {enabled ? ' to go back to the original experience.' : ' to try it out.'}
            </Alert.Link>
            {alertState.loading ? (
                <Spinner
                    style={{ marginLeft: '1rem', width: '1.4rem', height: '1.4rem' }}
                    variant="primary"
                    animation="border"
                />
            ) : null}
        </Alert>
    )
}