import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

export const DatepickerHtml5 = ({ name, value, onChange, min, max, step, className, style, inputGroupStyle, disabled, isNullable, isInvalid, invalidFeedback }) => {
    const [lastKnownGood, setLastKnownGood] = useState(value || '');
    const [internalValue, setInternalValue] = useState(value || '');
    const [internalValueAsDate, setInternalValueAsDate] = useState(null);

    useEffect(() => {
        setInternalValue(value || '');
        setLastKnownGood(value || '');
    }, [ value ])

    const onChangeInternal = e => {
        const { value, valueAsDate } = e.target;
        setInternalValue(value);
        setInternalValueAsDate(valueAsDate);
    }

    const onClearDate = () => {
        setInternalValue('');
        setInternalValueAsDate(null);
        onChange({ target: { name, value: null, type: 'date' } });
    }

    const onBlurInternal = () => {
        if (internalValueAsDate) {
            onChange({ target: { name, value: internalValue || null, type: 'date' } });
        } else {
            setInternalValue(lastKnownGood);
        }
    }

    return (
        <InputGroup style={inputGroupStyle ? {...inputGroupStyle} : null}>
            <Form.Control
                name={name}
                type="date"
                value={internalValue}
                onChange={onChangeInternal}
                onBlur={onBlurInternal}
                min={min}
                max={max}
                step={step}
                disabled={disabled}
                className={className}
                style={style}
                isInvalid={isInvalid}
            />
            {isNullable && (
                <InputGroup.Append>
                    <Button
                        variant="outline-danger"
                        onClick={onClearDate}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                </InputGroup.Append>
            )}
            {invalidFeedback && (
                <Form.Control.Feedback type="invalid">{invalidFeedback}</Form.Control.Feedback>
            )}
        </InputGroup>
    );
}